export enum ShopQuoteTypeEnum {
  shopQuote = 'ShopQuoteReady',
}

export enum ShopQuoteIconEnum {
  shopQuote = 'screwdriver-wrench',
}

export enum ShopQuoteColorEnum {
  shopQuote = 'color-shopQuoteReady',
}

export enum ShopQuoteFilterEnum {
  shopQuote = 'ShopQuoteReady',
}

interface LastDocumentTransmission {
  transmissionStatus: 'T' | 'F';
  transmissionMethod: string;
  transmissionTmstmp: string;
  creationUsername: string;
  creationUserId: string;
}

export interface ShopQuoteReady {
  emailAddress?: string;
  customerName?: string;
  shopMiLoc?: string;
  itemDescription?: string;
  workOrderCtlNo?: string;
  orderCtlNo?: string;
  shopName?: string;
  cost?: string;
  price?: string;
  shopAddr1?: string;
  shopAddr2?: string;
  shopAddr3?: string;
  shopCity?: string;
  shopState?: string;
  shopZip?: string;
  country?: string;
  shopPhone?: string;
  shopEmail?: string;
  workOrderQuoteComplete: string;
  lastDocumentTransmission: LastDocumentTransmission;
}
