import { or } from 'common/utils/logicHelpers';
import { reportsURL } from 'navigation';
import { concatRoutes } from 'utils/navigations';
import {
  costSavingsReportURL,
  endOfMonthReportsURL,
  locationPick12ReportURL,
  openQuotesReportsURL,
  pick12ReportURL,
  salesPerformanceReportURL,
  unbilledReportURL,
} from './routerConfig';

export const goToSalesPerformanceReport = ({
  baseUrl = '',
  orgType = '',
  miLoc = '',
  rowId = '',
  pgc1 = '',
}) => {
  return concatRoutes(
    or(baseUrl, reportsURL()),
    salesPerformanceReportURL({ orgType, miLoc, rowId, pgc1 })
  );
};

export const goToPick12Report = ({
  orgType = '',
  miLoc = '',
  rowId = '',
  pick12 = false,
}) => {
  return concatRoutes(
    reportsURL(),
    pick12
      ? pick12ReportURL({ orgType, miLoc, rowId })
      : locationPick12ReportURL({ orgType, miLoc, rowId })
  );
};

export const goToCostSavingsReport = ({
  orgType = '',
  miLoc = '',
  rowId = '',
  pgc1 = '',
}) => {
  return concatRoutes(
    reportsURL(),
    costSavingsReportURL({ orgType, miLoc, rowId, pgc1 })
  );
};

export const goToUnbilledReport = ({
  orgType = '',
  miLoc = '',
  rowId = '',
}) => {
  return concatRoutes(
    reportsURL(),
    unbilledReportURL({ orgType, miLoc, rowId, pgc1: '' })
  );
};

export const goToEndOfMonthReport = () =>
  concatRoutes(reportsURL(), endOfMonthReportsURL());

export const goToOpenQuotesReport = () =>
  concatRoutes(reportsURL(), openQuotesReportsURL());
