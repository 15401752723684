import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import type { SortingRule } from 'react-table';
import classnames from 'classnames';
import type { Dictionary } from 'lodash';
import {
  debounce,
  concat,
  endsWith,
  filter,
  find,
  findIndex,
  head,
  includes,
  isEmpty,
  join,
  size,
  split,
  toNumber,
  toString,
  isNil,
  map,
} from 'lodash';
import { useIonViewDidEnter } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { and, choose, ifFunction, or } from 'common/utils/logicHelpers';
import {
  endOfMonth,
  endOfYear,
  fromUnixTime,
  getUnixTime,
  getYear,
  isToday,
  startOfDay,
  subYears,
} from 'date-fns';
import { reportsDrillDownURL, reportsURL, searchURL } from 'navigation';
import useGetDigitalSalesReport from 'ReportsApp/api/useGetDigitalSalesReport';
import useGetSalesReport from 'ReportsApp/api/useGetSalesReport';
import JobWarning from 'ReportsApp/components/JobWarning/JobWarning';
import type { ReportItemType } from 'ReportsApp/models';
import {
  groupByBranch,
  groupByCustomer,
  groupByPersonalPick12,
  groupByProductGroup01,
  groupByTeamAndRep,
  groupByUnbilled,
  groupByCamExec,
  groupByCamCorp,
  groupByCam,
  groupByVirtualTeam,
  groupByRoleOptions,
} from 'ReportsApp/util/groupOptions/groupOptions';
import { useGetSelectedMiLoc } from 'api/helpers';
import useGetReportDrillDown from 'api/salesReports/useGetReportDrillDown';
import useGetReportSummary from 'api/salesReports/useGetReportSummary';
import type {
  BusDayHashMap,
  ProfitTrend,
  ProfitTrendExt,
  ReportDrillDownItem,
  ReportsContextProps,
  RoleGroupType,
  SalesTrend,
  SalesTrendExt,
  ViewAsRoleType,
} from 'models/Reports';
import type { SortFieldEnum } from 'models/Sort';
import { SortDirEnum } from 'models/Sort';
import type { RootState } from 'store/reducers';
import {
  formatDate,
  getPeriorMaxBusDay,
  parseDate,
  useGetSharedBusinessDayLabel,
} from 'utils/date';
import { getErrorMessage, getInvalidBusDay } from 'utils/helpers';
import { concatRoutes } from 'utils/navigations';
import {
  getUserRole,
  isSalesJobRunning,
  noCoprporateAccountAssigned,
} from 'utils/reports';
import { handleSearchNavigation } from 'utils/search';
import type { BarColumnData } from 'components/Charts/DailyChart/Chart';
import type { FilterOption } from 'components/Filter/Filter';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './DrillDown.module.scss';
import SalesDrillDown from './SalesDrillDown';
import getSortOptions from './sortOptions';
import WebSalesTableDrillDown from './WebSalesTableDrillDown/WebSalesTableDrillDown';

export interface GetDrillDownLinkReponse {
  title: string;
  groupByDataKey: string;
  href?: string;
  onClick: () => void;
  secondaryText?: string;
  hideArrow?: boolean;
}

interface DrillDownProps {
  reportType?: ReportItemType;
  busDayMap: BusDayHashMap;
  testid: string;
  miLoc: string;
  orgType: string;
  routeId: string;
  pgc1: string;
  role: string;
  title: string;
  includeDailyTotal?: boolean;
  contentRef?: React.MutableRefObject<HTMLIonContentElement | null>;
}

const DrillDown = ({
  className,
  title,
  reportType,
  requestType,
  busPeriod,
  sortField,
  updateSortField,
  sortDir,
  updateSortDir,
  busDayMap,
  miLoc,
  orgType,
  routeId,
  pgc1,
  role,
  testid,
  includeDailyTotal,
  contentRef,
}: DrillDownProps &
  IonicReactProps &
  Pick<
    ReportsContextProps,
    | 'requestType'
    | 'busPeriod'
    | 'sortField'
    | 'sortDir'
    | 'updateSortField'
    | 'updateSortDir'
  >): JSX.Element => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const [canChangeTab, setCanChangeTab] = useState(false);

  const { singleTeam } = useGetSelectedMiLoc(miLoc);

  const selectedDate = parseDate(busPeriod);
  let stateUserRole = or(role, 'EXEC');
  stateUserRole = getUserRole(stateUserRole);
  const userRole = or(orgType, stateUserRole) as ViewAsRoleType;
  // const userRole = stateUserRole as ViewAsRoleType;
  const pickTwelve = reportType === 'pick12';
  const locationPick12 = reportType === 'locationPick12';
  const isWebSalesReport = reportType === 'webSales';
  const isUnbilledReport = reportType === 'unbilled';
  const isPick12Report = or(pickTwelve, locationPick12);
  const isTableView = or(isPick12Report, isWebSalesReport, isUnbilledReport);

  const { showNewSalesReport, isCamUser, showDigitalSalesReport } = useSelector(
    (state: RootState) => state.user
  );

  const isNewPick12Report = isPick12Report;

  const { fromVirtualTeam } = useGetSelectedMiLoc();

  const groupByOptions: FilterOption[] = useMemo(() => {
    let groupBy;
    let groupByRoleItems;
    if (isUnbilledReport) {
      groupByRoleItems = groupByUnbilled(userRole);
    } else if (and(pickTwelve, !orgType)) {
      groupBy = groupByPersonalPick12;
    } else if (
      and(
        includes(['GRP', 'DIV', 'BRCH'], userRole),
        isCamUser,
        isWebSalesReport
      )
    ) {
      groupBy = groupByCam;
    } else if (userRole === 'BRCH') {
      groupBy = groupByBranch(isTableView);
    } else if (includes(['TEAM', 'REP'], userRole)) {
      groupBy = groupByTeamAndRep;
    } else if (userRole === 'CUST') {
      groupBy = groupByCustomer;
    } else if (userRole === 'PRD_GRP_01') {
      groupBy = groupByProductGroup01;
    } else if (and(isCamUser, miLoc === 'EXEC', isWebSalesReport)) {
      groupBy = groupByCamExec;
    } else if (and(isCamUser, miLoc !== 'EXEC', isWebSalesReport)) {
      groupBy = groupByCamCorp;
    } else {
      groupByRoleItems = groupByRoleOptions(isTableView);
    }
    if (!isNil(groupByRoleItems)) {
      const currentRoleIndex = findIndex(groupByRoleItems, { key: userRole });
      groupBy = groupByRoleItems.slice(currentRoleIndex + 1);
      if (and(isUnbilledReport, userRole === 'BRCH')) {
        groupBy = [{ key: 'BRCH', name: 'reports:branches' }];
      }
    }

    ifFunction(
      and(
        !singleTeam,
        showDigitalSalesReport,
        orgType !== 'TEAM',
        fromVirtualTeam,
        isWebSalesReport
      ),
      () => {
        groupBy = groupByVirtualTeam;
      }
    );

    return map(groupBy, (element) => {
      return {
        key: element.key,
        name: t(element.name, { suffix: element.suffix }),
      };
    });
  }, [
    isUnbilledReport,
    pickTwelve,
    orgType,
    userRole,
    isCamUser,
    isWebSalesReport,
    miLoc,
    singleTeam,
    showDigitalSalesReport,
    fromVirtualTeam,
    isTableView,
    t,
  ]);

  const [groupByData, setGroupByData] =
    useState<Dictionary<FilterOption | undefined>>();
  let selectedGroupByData = groupByData?.[userRole];

  if (and(isCamUser, isWebSalesReport)) {
    selectedGroupByData = or(
      selectedGroupByData,
      find(groupByOptions, { key: 'BRCH' })
    );
  }

  selectedGroupByData = or(selectedGroupByData, groupByOptions[0]);
  const groupByDataKey = toString(selectedGroupByData?.key);

  const sortFieldOptions = useCallback(
    (groupByKey?: string) => {
      const isDailySelected = requestType === 'DAILY';
      const isTodaySelected =
        isDailySelected && isToday(fromUnixTime(busPeriod));
      const showLocationSortOptions = includes(
        ['EXEC', 'CORP', 'GRP', 'DIV', 'BRCH'],
        groupByKey
      );

      return getSortOptions(
        isDailySelected,
        isTodaySelected,
        showLocationSortOptions,
        isTableView
      ).map((sortItem) => ({
        ...sortItem,
        name: t(sortItem.name),
      })) as FilterOption[];
    },
    [busPeriod, isTableView, requestType, t]
  );

  const sortFieldSelected = useMemo(
    () =>
      or(
        find(sortFieldOptions(groupByDataKey), { key: sortField }),
        head(sortFieldOptions(groupByDataKey))
      ),
    [sortFieldOptions, groupByDataKey, sortField]
  );

  const sortDirOptions = useCallback(
    (fieldSelected?: FilterOption) =>
      [
        {
          key: SortDirEnum.ASCENDING,
          name: t(
            fieldSelected?.type === 'string'
              ? 'sort:alphaAscending'
              : 'sort:numberDescending'
          ),
          icon: 'arrow-up',
        },
        {
          key: SortDirEnum.DESCENDING,
          name: t(
            fieldSelected?.type === 'string'
              ? 'sort:alphaDescending'
              : 'sort:numberAscending'
          ),
          icon: 'arrow-down',
        },
      ] as FilterOption[],
    [t]
  );

  const sortDirSelected = useMemo(
    () =>
      or(
        find(sortDirOptions(sortFieldSelected), { key: sortDir }),
        head(sortDirOptions(sortFieldSelected))
      ),
    [sortDirOptions, sortFieldSelected, sortDir]
  );

  // filter by customer when seeing customer and product category reports
  const customerId = includes(['CUST', 'PRD_GRP_01'], userRole) ? routeId : '';
  const territory = includes(['REP'], userRole) ? routeId : '';

  const isNewSalesReport = and(!!showNewSalesReport, !isTableView);

  const drillDownResult = useGetReportDrillDown({
    miLoc,
    id: customerId,
    territory,
    pgc1,
    busPeriod,
    requestType,
    viewAsRole: includes(['PRD_GRP_01'], userRole) ? 'CUST' : userRole,
    groupBy: groupByDataKey as RoleGroupType,
    sortField: sortFieldSelected.key as SortFieldEnum,
    sortDir: sortDirSelected.key as SortDirEnum,
    pickTwelve: !locationPick12 && pickTwelve,
    locationPick12,
    enabled: or(
      and(
        !isNewSalesReport,
        !!miLoc,
        !!userRole,
        !!groupByDataKey,
        !isUnbilledReport,
        !showDigitalSalesReport
      )
    ),
    includeWebPerfData: isWebSalesReport,
    includeDailyTotal,
    sendVirtualTeamId: true,
  });

  const newDrilldownResult = useGetSalesReport({
    miLoc,
    customerId,
    territory,
    pgc1,
    busPeriod,
    requestType,
    groupBy: groupByDataKey,
    sortField: sortFieldSelected.key,
    sortDir: sortDirSelected.key as SortDirEnum,
    isPick12Report: and(!!isNewPick12Report, !locationPick12),
    isLocationPick12Report: and(!!isNewPick12Report, locationPick12),
    enabled: or(
      and(
        !!isNewSalesReport,
        !!miLoc,
        !!userRole,
        !!groupByDataKey,
        !isUnbilledReport,
        !showDigitalSalesReport
      ),
      !!isNewPick12Report
    ),
  });

  const digitalSaleDrillDownResult = useGetDigitalSalesReport({
    miLoc,
    customerId,
    pgc1,
    busPeriod,
    requestType,
    groupBy: groupByDataKey,
    sortField: sortFieldSelected.key,
    sortDir: sortDirSelected.key as SortDirEnum,
    nationalAcctNo:
      !includes(['REP', 'CUST'], orgType) && routeId ? routeId : undefined,
    territory: includes(['REP', 'CUST'], orgType) ? routeId : undefined,
    enabled: and(
      !!miLoc,
      !!userRole,
      !!groupByDataKey,
      !!showDigitalSalesReport,
      isWebSalesReport
    ),
  });

  let reportResult = drillDownResult;
  if (!isPick12Report && showDigitalSalesReport) {
    reportResult = digitalSaleDrillDownResult;
  } else if (or(isNewSalesReport, isNewPick12Report)) {
    reportResult = newDrilldownResult;
  }

  const {
    totalsData,
    drilldownData,
    error: drilldownError,
    refetch: drilldownRefetch,
    fetchNextPage,
    hasError: hasDrilldownError,
    showLoader: drilldownIsLoading,
    isEmptyResponse,
    enableInfiniteScroll,
    // eslint-disable-next-line no-nested-ternary
  } = reportResult;

  // #region graph data
  const summaryResult = useGetReportSummary({
    miLoc,
    id: customerId,
    pgc1,
    territory,
    busPeriod,
    requestType,
    pickTwelve: and(!locationPick12, pickTwelve),
    locationPick12,
    // TODO: remove when graphs are back in pick12
    enabled: and(!isNewSalesReport, !isTableView),
    includeDailyTotal,
  });

  const {
    summaryData,
    lastUpdatedAt,
    error: summaryError,
    refetch: summaryRefetch,
  } = isNewSalesReport ? newDrilldownResult : summaryResult;
  const summaryIsLoading = isNewSalesReport
    ? newDrilldownResult.showLoader
    : summaryResult.isLoading;

  const sameBusDay = summaryData?.sameBusDay;
  const sharedBusinessDayLabel = useGetSharedBusinessDayLabel(
    busPeriod,
    sameBusDay
  );

  const today = startOfDay(new Date());
  let lastYearPeriod = subYears(parseDate(busPeriod), 1);
  const periodBusDay = busDayMap[formatDate(busPeriod, 'yyyy-MM-dd')];
  let lastYearBusDay = false;
  if (requestType === 'YTD') {
    lastYearPeriod = endOfYear(lastYearPeriod);
  } else if (requestType === 'MTD') {
    lastYearPeriod = endOfMonth(lastYearPeriod);
  } else if (requestType === 'DAILY') {
    // DOC: look for same business day for last year in calendar data
    const currentMonthBusDays = getPeriorMaxBusDay(busDayMap, busPeriod);
    const lastYearMonthBusDays = getPeriorMaxBusDay(busDayMap, lastYearPeriod);
    let diffBusDays = lastYearMonthBusDays - currentMonthBusDays;
    diffBusDays = diffBusDays > 0 ? diffBusDays : 0;

    lastYearBusDay = !!find(
      // get all days with same busDay for last year
      filter(
        busDayMap,
        (busDay, key) =>
          key.indexOf(formatDate(lastYearPeriod, 'yyyy-MM')) === 0 &&
          toNumber(busDay.busDay) ===
            toNumber(periodBusDay.busDay) + diffBusDays
      ),
      // select the primary day when there is more than one busDay
      (busDay, key, lastYearSameBusDay) => {
        if (or(size(lastYearSameBusDay) === 1, !!busDay.primary)) {
          lastYearPeriod = parseDate(busDay.gregorianDate);
          return true;
        }
        return false;
      }
    );
  }

  const displayYoYChange = !(
    requestType === 'DAILY' &&
    // DOC: don't display YoY change if there is no same busday for last year data
    or(
      !lastYearBusDay,
      // don't display if current busday is not primary for selected year data
      and(size(sameBusDay) > 1, !periodBusDay.primary)
    )
  );

  const lastYearSummaryResult = useGetReportSummary({
    miLoc,
    id: customerId,
    pgc1,
    territory,
    busPeriod: getUnixTime(lastYearPeriod),
    requestType,
    pickTwelve: !locationPick12 && pickTwelve,
    locationPick12,
    adjustForYoYBusDays: true,
    // TODO: remove when graphs are back in pick12
    enabled: !isNewSalesReport && !isTableView,
  });
  const { refetch: lastYearSummaryRefetch } = isNewSalesReport
    ? newDrilldownResult
    : lastYearSummaryResult;
  const lastYearSummaryData = isNewSalesReport
    ? newDrilldownResult.prevSummaryData
    : lastYearSummaryResult.summaryData;
  const lastYearSummaryIsLoading = isNewSalesReport
    ? newDrilldownResult.showLoader
    : lastYearSummaryResult.isLoading;

  const summaryShowLoader = or(summaryIsLoading, lastYearSummaryIsLoading);

  const headerData = concat(
    summaryData?.sales || [],
    summaryData?.profit || []
  );

  let salesData: SalesTrend[] = [];
  let lastYearSalesData: SalesTrend[] = [];
  let profitData: ProfitTrend[] = [];
  let lastYearProfitData: ProfitTrend[] = [];

  let dailySalesData: BarColumnData | undefined;
  let dailyLastYearSalesData: BarColumnData | undefined;
  let dailyProfitData: BarColumnData | undefined;
  let dailyLastYearProfitData: BarColumnData | undefined;

  if (requestType === 'DAILY') {
    const currentYear = toString(formatDate(parseDate(busPeriod)));
    dailySalesData = {
      key: currentYear,
      value: or(
        summaryData?.sales?.find((item) => item.Name === 'Sales')?.amount,
        0
      ),
    };

    dailyProfitData = {
      key: currentYear,
      value: or(
        summaryData?.profit?.find((item) => item.Name === 'GP')?.amount,
        0
      ),
    };

    const pastYear = toString(getYear(parseDate(lastYearPeriod)));

    dailyLastYearSalesData = {
      key: pastYear,
      value:
        (!summaryIsLoading &&
          displayYoYChange &&
          summaryData?.previous?.sales) ||
        0,
    };

    dailyLastYearProfitData = {
      key: pastYear,
      value:
        (!summaryIsLoading && displayYoYChange && summaryData?.previous?.gp) ||
        0,
    };
  } else {
    salesData =
      summaryData?.salesTrend?.filter((salesTrend) => {
        const trendsWithExtraFields = salesTrend as SalesTrendExt;
        return (
          trendsWithExtraFields.show === undefined || trendsWithExtraFields.show
        );
      }) || [];

    profitData =
      summaryData?.profitTrend?.filter((profitTrend) => {
        const trendsWithExtraFields = profitTrend as ProfitTrendExt;
        return (
          trendsWithExtraFields.show === undefined || trendsWithExtraFields.show
        );
      }) || [];

    lastYearSalesData =
      lastYearSummaryData?.salesTrend?.filter((salesTrend) => {
        const trendsWithExtraFields = salesTrend as SalesTrendExt;
        return (
          trendsWithExtraFields.show === undefined || trendsWithExtraFields.show
        );
      }) || [];

    lastYearProfitData =
      lastYearSummaryData?.profitTrend?.filter((profitTrend) => {
        const trendsWithExtraFields = profitTrend as ProfitTrendExt;
        return (
          trendsWithExtraFields.show === undefined || trendsWithExtraFields.show
        );
      }) || [];
  }
  // #endregion graph data

  const getDrilldownLink = ({
    Name,
    miLoc: itemMiLoc,
    id: itemId,
    secondaryText,
    disabledDrilldown,
  }: ReportDrillDownItem): GetDrillDownLinkReponse => {
    let newUrl = url;
    const splitURL = url.split('/');
    if (
      !endsWith(url, 'sales') &&
      !(
        includes(splitURL, 'sales-team') &&
        splitURL[splitURL.length - 2] === 'sales-team'
      ) &&
      !endsWith(url, 'profit') &&
      !endsWith(url, 'pick-12') &&
      !endsWith(url, 'location-pick-12') &&
      !endsWith(url, 'web-sales') &&
      !endsWith(url, 'cost-savings') &&
      !endsWith(url, 'unbilled')
    ) {
      const path = split(url, '/').slice(
        0,
        // reps and customers have 2 ids, so we need to trim 3 url params instead of 2
        includes(['REP', 'CUST'], userRole) ? -3 : -2
      );
      newUrl = join(path, '/');
    }
    let href: string | undefined = concatRoutes(
      newUrl,
      reportsDrillDownURL('', groupByDataKey, itemMiLoc, '', '')
    );
    if (includes(['CUST', 'REP'], groupByDataKey)) {
      href = concatRoutes(
        newUrl,
        reportsDrillDownURL('', groupByDataKey, itemMiLoc, itemId, '')
      );
    }
    ifFunction(includes(['PRD_GRP_01'], groupByDataKey), () => {
      href = concatRoutes(
        newUrl,
        reportsDrillDownURL(
          '',
          groupByDataKey,
          itemMiLoc,
          // customerId from URLParams
          routeId,
          itemId
        )
      );
    });

    if (includes(['PRD_GRP_02'], groupByDataKey)) {
      href = undefined;
    }

    if (isTableView && includes(['CUST'], groupByDataKey)) {
      href = concatRoutes(
        searchURL(),
        handleSearchNavigation({
          type: 'customer',
          miLoc: itemMiLoc,
          customerId: itemId,
        })
      );
    }

    let itemTitle = Name;
    if (
      and(
        !includes(['REP', 'CUST', 'PRD_GRP_01', 'PRD_GRP_02'], groupByDataKey)
      )
    ) {
      itemTitle = Name ? `${itemMiLoc}: ${Name}` : itemMiLoc;
    } else if (!isTableView && includes(['REP'], groupByDataKey)) {
      itemTitle = `${itemId}: ${Name}`;
    }

    ifFunction(and(isCamUser, isWebSalesReport), () => {
      itemTitle = Name;
    });

    ifFunction(and(fromVirtualTeam, isWebSalesReport), () => {
      itemTitle = Name;
    });

    ifFunction(and(includes(['NATLACCT'], groupByDataKey), isCamUser), () => {
      href = concatRoutes(
        newUrl,
        reportsDrillDownURL('', groupByDataKey, itemMiLoc, itemId, '')
      );
    });

    if (
      and(
        includes(['BRCH', 'DIV', 'GRP'], groupByDataKey),
        isCamUser,
        isWebSalesReport
      )
    ) {
      href = concatRoutes(
        reportsURL(),
        reportsDrillDownURL('webSales', groupByDataKey, itemMiLoc, itemId, '')
      );
    }

    ifFunction(includes(['TEAM'], groupByDataKey), () => {
      href = concatRoutes(
        newUrl,
        reportsDrillDownURL('', groupByDataKey, '', itemId, '')
      );
    });

    const hideArrow =
      (isUnbilledReport && groupByDataKey === 'BRCH' && userRole === 'BRCH') ||
      disabledDrilldown;

    return {
      title: itemTitle,
      groupByDataKey,
      secondaryText: or(secondaryText, ''),
      href: hideArrow ? undefined : href,
      hideArrow,
      onClick: () => {
        //  Changing tabs doesn't work after clicking on a customer name if canChangeTab is false
        if (isTableView && includes(['CUST'], groupByDataKey)) {
          setCanChangeTab(true);
        } else {
          // DOC: ionic fires tab change on route change, we need a flag to stop it
          setCanChangeTab(false);
        }

        setGroupByData((prev) => {
          const newGroupBy = { ...prev };
          delete newGroupBy?.[groupByDataKey];
          return newGroupBy;
        });
      },
    };
  };

  let enableBusDate = false;
  if (
    today.getFullYear() === selectedDate.getFullYear() &&
    today.getMonth() === selectedDate.getMonth() &&
    busDayMap[formatDate(today, 'yyyy-MM-dd')]
  ) {
    enableBusDate = true;
  }
  const currentBusDay = busDayMap[formatDate(today, 'yyyy-MM-dd')];

  const selectedFilters = useMemo(
    () => [selectedGroupByData, sortFieldSelected, sortDirSelected],
    [selectedGroupByData, sortFieldSelected, sortDirSelected]
  );

  const onRefresh = async () => {
    await Promise.all([
      // TODO: remove when graphs are back in pick12
      ...(isTableView ? [] : [summaryRefetch?.(), lastYearSummaryRefetch?.()]),
      drilldownRefetch?.(),
    ]);
  };

  useIonViewDidEnter(() => {
    setCanChangeTab(true);
  });

  const isJobRunning = isSalesJobRunning(drilldownError);
  const isInvalidBusDay = getInvalidBusDay(drilldownError);
  const errorBody = choose(
    noCoprporateAccountAssigned(drilldownError),
    t('reports:noAcctsAssigned'),
    getErrorMessage(drilldownError)
  );

  const reportProps = {
    sharedBusinessDayLabel: !isEmpty(sharedBusinessDayLabel) && (
      <Text
        className={classes.sharedBusinessDayLabel}
        variant="label-header"
        text={sharedBusinessDayLabel}
      />
    ),
    scrollContent: (
      <>
        {isEmptyResponse && (
          <WarningMessage
            className={classes.warningMessage}
            icon={['far', 'info-circle']}
            title={t('reports:noReports')}
          />
        )}
        {hasDrilldownError && (
          <WarningMessage
            className={classes.warningMessage}
            title={
              isInvalidBusDay
                ? t('reports:emptyReportHeader')
                : t('reports:errorReports')
            }
            body={
              isInvalidBusDay
                ? t('reports:invalidBusDayMsg', {
                    requestType:
                      requestType === 'MTD'
                        ? t('common:month')
                        : t('common:year'),
                  })
                : errorBody
            }
          />
        )}
        <Loader
          className={classes.loader}
          text="Loading results"
          isOpen={drilldownIsLoading}
        />
        <InfiniteScroll
          disabled={!enableInfiniteScroll}
          onLoadMore={fetchNextPage}
          testid="infinite-scroll"
        />
      </>
    ),
    className,
    requestType,
    summaryData,
    hasDrilldownError,
    drilldownData,
    totalsData,
    getDrilldownLink,
    testid,
    busPeriod,
  };

  const tableReportProps = {
    miLoc,
    title,
    groupByOptions,
    selectedGroupBy: selectedGroupByData,
    setGroupBy: (v: string) => {
      if (canChangeTab) {
        setGroupByData((prev) => ({
          ...prev,
          [userRole]: find(groupByOptions, (g) => g.key === v),
        }));
      }
    },
    onSortBy: debounce((sortOption: SortingRule<Dictionary<unknown>>[]) => {
      updateSortField?.(toString(head(sortOption)?.id));
      if (!isEmpty(sortOption)) {
        updateSortDir?.(
          head(sortOption)?.desc
            ? SortDirEnum.DESCENDING
            : SortDirEnum.ASCENDING
        );
      }
      void contentRef?.current?.scrollToTop();
    }, 300),
    sortField: sortFieldSelected.key,
    sortDir: sortDirSelected.key,
    ...reportProps,
  };

  return (
    <>
      <Refresher
        slot="fixed"
        className={classnames(classes.refresher, {
          [classes.pick12Refresher]: isTableView,
        })}
        lastUpdatedAt={lastUpdatedAt}
        onRefresh={onRefresh}
        hidden={requestType !== 'DAILY'}
        testid="Reports"
        // TODO: remove when graphs are back in pick12
        disabled={or(drilldownIsLoading, and(!isTableView, summaryIsLoading))}
      />
      {isWebSalesReport && (
        <WebSalesTableDrillDown
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...tableReportProps}
        />
      )}

      {!isTableView && isJobRunning && (
        <JobWarning reportName={t('salesPerformance')} />
      )}
      {!isTableView && !isJobRunning && (
        <SalesDrillDown
          summaryShowLoader={summaryShowLoader}
          salesData={salesData}
          lastYearSalesData={lastYearSalesData}
          profitData={profitData}
          lastYearProfitData={lastYearProfitData}
          headerData={headerData}
          summaryError={summaryError}
          dailySalesData={dailySalesData}
          dailyLastYearSalesData={dailyLastYearSalesData}
          dailyProfitData={dailyProfitData}
          dailyLastYearProfitData={dailyLastYearProfitData}
          displayYoYChange={displayYoYChange}
          selectedDate={selectedDate}
          enableBusDate={enableBusDate}
          currentBusDay={currentBusDay}
          selectedItems={selectedFilters}
          setFilterData={[
            (option?: FilterOption) =>
              setGroupByData((prev) => ({
                ...prev,
                [userRole]: option,
              })),
            (option) => updateSortField?.(toString(option?.key)),
            (option) => updateSortDir?.(toString(option?.key)),
          ]}
          filterOptions={[
            { title: t('common:showBy'), options: groupByOptions },
            {
              title: t('common:sortByCategory'),
              options: ([groupBySelected]) =>
                sortFieldOptions(groupBySelected?.key),
            },
            {
              title: t('common:sortBy'),
              options: ([, fieldSelected]) => sortDirOptions(fieldSelected),
            },
          ]}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...reportProps}
        />
      )}
    </>
  );
};

export default DrillDown;
