/* istanbul ignore file */

import React from 'react';
import { toString, includes, head } from 'lodash';
import { Capacitor } from '@capacitor/core';
import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as Sentry from '@sentry/capacitor';
import { init as sentryReactInit } from '@sentry/react';
import App from 'App';
import {
  defineCustomElements as jeepSqlite,
  applyPolyfills,
} from 'jeep-sqlite/loader';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from 'serviceWorker';
import './i18n/i18n';

void applyPolyfills().then(() => {
  void jeepSqlite(window);
});

// Call the element loader after the platform has been bootstrapped
void defineCustomElements(window);

const DROP_SENTRY_EVENTS = ['"DataWedge" plugin is not implemented on ios'];

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init(
    {
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration()],
      environment: process.env.REACT_APP_ENV_BUILD,
      tracesSampleRate: 1.0,
      beforeSend: (event) => {
        // Modify or drop the event here
        if (
          includes(DROP_SENTRY_EVENTS, head(event.exception?.values)?.value)
        ) {
          // Don't send the event to Sentry
          return null;
        }
        return event;
      },
    },
    sentryReactInit
  );
}

window.addEventListener('DOMContentLoaded', () => {
  const init = async () => {
    const platform = Capacitor.getPlatform();
    const sqlite: SQLiteConnection = new SQLiteConnection(CapacitorSQLite);
    try {
      if (platform === 'web') {
        const jeepEl = document.createElement('jeep-sqlite');
        document.body.appendChild(jeepEl);
        await customElements.whenDefined('jeep-sqlite');
        await sqlite.initWebStore();
      }

      const container = document.getElementById('root');
      const root = createRoot(container as Element);
      root.render(<App />);

      //  Disable long press context menu in Chrome
      window.oncontextmenu = (event) => {
        const path = event.composedPath();
        if (
          event.button !== 2 &&
          !(event.clientX === event.clientY) &&
          path.length <= 8
        ) {
          event.preventDefault();
        }
      };

      // If you want your app to work offline and load faster, you can change
      // unregister() to register() below. Note this comes with some pitfalls.
      // Learn more about service workers: https://bit.ly/CRA-PWA
      serviceWorker.unregister();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`DOMContentLoaded Error: ${toString(err)}`);
      throw new Error(`DOMContentLoaded Error: ${toString(err)}`);
    }
  };
  void init();
});
