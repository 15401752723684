import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  debounce,
  forEach,
  includes,
  isNil,
  map,
  reverse,
  toString,
} from 'lodash';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type {
  IonToastCustomEvent,
  OverlayEventDetail,
} from '@ionic/core/components';
import { IonToast } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { and } from 'common/utils/logicHelpers';
import type { ToastButtonProps } from 'models/Toast';
import { ToastType } from 'models/Toast';
import { createSVGIcon } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import classes from './Toast.module.scss';

export const renderToasts = debounce(() => {
  let offset = 0;
  const toasts = document.querySelectorAll('ion-toast');
  const tabs = document.querySelector<HTMLElement>(
    '[data-testid="main-tab-bar"] > ion-tabs > ion-tab-bar'
  );
  const footer = document.querySelector<HTMLElement>(
    'div.ion-page:not(.ion-page-hidden):not([data-testid="main-tab-bar"]) ion-footer'
  );
  if (tabs?.offsetParent) {
    offset += tabs?.getBoundingClientRect().height || 0;
  }
  if (footer?.offsetParent) {
    offset += footer?.getBoundingClientRect().height || 0;
  }
  forEach(reverse(Array.from(toasts)), (toast) => {
    const toastWrapper = toast?.shadowRoot?.querySelectorAll('div')[0];
    offset += (toastWrapper?.getBoundingClientRect().height || 0) + 8;
    toastWrapper?.setAttribute(
      'style',
      `bottom: calc(${offset}px + var(--ion-safe-area-bottom, 8px));transition: bottom 0.3s;`
    );
  });
}, 10);

interface ToastProps {
  index?: number;
  text?: string;
  icon?: IconProp | null;
  leftIcon?: IconProp;
  type?: ToastType;
  variant?: 'mipro-toast';
  button?: ToastButtonProps | null;
  onClose?: () => void;
  testid: string;
}

const Toast = ({
  className,
  index,
  type = ToastType.info,
  variant = 'mipro-toast',
  header,
  text,
  icon: propsIcon,
  position = 'bottom',
  duration = 5 * 1000,
  button = { role: 'cancel', icon: findIcon('times'), isDefault: true },
  onClose,
  testid,
  leftIcon,
}: ToastProps &
  Omit<React.ComponentProps<typeof IonToast>, 'isOpen' | 'icon'> &
  IonicReactProps): JSX.Element => {
  // DOC: ion-toast can't start open, it has to be set for it to appear
  const [isOpen, setIsOpen] = useState(false);
  let icon = propsIcon;
  if (!icon) {
    if (and(type === ToastType.info, !button?.isDefault)) {
      icon = findIcon('check');
    } else if (includes([ToastType.warn, ToastType.error], type)) {
      icon = findIcon('exclamation-triangle');
    }
  }

  useEffect(() => {
    setIsOpen(true);
    setTimeout(() => renderToasts(), 100);
  }, [index]);

  const handleToastButtons = () => {
    const toastButtons: ToastButtonProps[] = [];
    if (!isNil(icon)) {
      toastButtons.push({ side: 'start', icon, role: 'cancel' });
    }
    if (!isNil(button)) {
      toastButtons.push(button);
    }
    return map(
      toastButtons,
      ({ side = 'end', icon: actionIcon, ...props }) => ({
        ...props,
        side,
        icon: createSVGIcon(actionIcon),
      })
    );
  };

  const colorMap = {
    [ToastType.alert]: '',
    [ToastType.info]: 'info',
    [ToastType.error]: 'danger',
    [ToastType.warn]: 'warning',
    [ToastType.success]: 'success',
  };

  try {
    return (
      <IonToast
        cssClass={classNames(
          classes.toast,
          classes[toString(variant)],
          classes[type],
          classes[colorMap[type]],
          className
        )}
        isOpen={isOpen}
        onDidPresent={() => renderToasts()}
        onIonToastDidDismiss={(e: IonToastCustomEvent<OverlayEventDetail>) => {
          // valdiation for failing tests as we can't mock the close activity detail
          if (includes(['timeout', 'cancel', 'undo'], e?.detail.role)) {
            onClose?.();
          }
        }}
        color={variant ? undefined : colorMap[type]}
        icon={createSVGIcon(leftIcon)}
        header={header}
        message={text}
        position={position}
        duration={duration}
        buttons={handleToastButtons()}
        data-testid={testid}
        mode="md"
      />
    );
  } catch {
    // DOC: animations sometimes fail in ionic
    return <></>;
  }
};

export default Toast;
