import React from 'react';
import classNames from 'classnames';
import { IonItem, IonToggle } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type { FirebaseButtonClickParams } from 'utils/firebaseAnalytics';
import { logFirebaseEvent } from 'utils/firebaseAnalytics';
import classes from './Toggle.module.scss';

interface ToggleProps {
  wrapperClassName?: string;
  checked: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
  track?: boolean;
  testid: string;
}

const Toggle = ({
  className,
  wrapperClassName,
  checked,
  disabled = false,
  lines,
  onClick,
  track = true,
  testid,
  children,
  color,
  name,
}: ToggleProps &
  React.ComponentProps<typeof IonToggle> &
  Pick<React.ComponentProps<typeof IonItem>, 'lines'> &
  IonicReactProps): JSX.Element => {
  const onClickWithTracking = (
    e: React.MouseEvent<HTMLIonToggleElement, MouseEvent>
  ) => {
    if (track) {
      const params: FirebaseButtonClickParams = {
        type: 'toggle',
        testid,
      };
      logFirebaseEvent('toggle_click', params);
    }
    onClick?.(e);
  };

  return (
    <IonItem
      className={classNames(classes.ionPageItem, className)}
      lines={lines}
    >
      <div className={wrapperClassName}>
        {children}
        <IonToggle
          slot="end"
          className={classNames(classes.ionToggle, {
            [classes.disabled]: disabled,
          })}
          color={color}
          checked={checked}
          name={name}
          onClick={onClickWithTracking}
          data-testid={testid}
        />
      </div>
    </IonItem>
  );
};

export default Toggle;
