import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import {
  concat,
  filter,
  find,
  findIndex,
  forEach,
  head,
  isEmpty,
  map,
  size,
  split,
  toNumber,
  toString,
  trim,
} from 'lodash';
import { Browser } from '@capacitor/browser';
import type {
  BleCharacteristic,
  BleService,
} from '@capacitor-community/bluetooth-le';
import { BleClient } from '@capacitor-community/bluetooth-le';
import type { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonCol,
  IonLoading,
  IonFooter,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
} from '@ionic/react';
import Alert from 'common/components/Alert/Alert';
import Header from 'common/components/Header/Header';
import { and, choose, ifRender, or } from 'common/utils/logicHelpers';
import { emptyString } from 'common/utils/valueFormatter';
import useAddZeroCounts from 'InventoryApp/api/useAddZeroCounts';
import useFindCountGroupItems from 'InventoryApp/api/useFindCountGroupItems';
import useGetCountGroup from 'InventoryApp/api/useGetCountGroup';
import useRemoveCountGroup from 'InventoryApp/api/useRemoveCountGroup';
import useSubmitInventoryOrder from 'InventoryApp/api/useSubmitInventoryOrder';
import useUpdateCountGroup from 'InventoryApp/api/useUpdateCountGroup';
import useUpdateItemCount from 'InventoryApp/api/useUpdateItemCount';
import AddItemModal from 'InventoryApp/components/AddItemModal/AddItemModal';
import InventoryCustomTitle from 'InventoryApp/components/InventoryCustomTitle/InventoryCustomTitle';
import OrderModal from 'InventoryApp/components/OrderModal/OrderModal';
import Scanner from 'InventoryApp/components/Scanner/Scanner';
import useInventoryHeaderActions from 'InventoryApp/hooks/useInventoryHeaderActions';
import type {
  CountGroup,
  CountGroupItem,
  CreatePlanAndGroupsURLParams,
} from 'InventoryApp/models/InventoryPlanGroup';
import { isStartVMI, isPiBelting } from 'InventoryApp/util/inventoryUtil';
import { homeInventoryURL, inventoryURL } from 'navigation';
import { useDevice } from 'providers/DeviceProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import { useDebounce } from 'use-debounce';
import useGetCustomer from 'api/customer/useGetCustomer';
import useGoBack from 'hooks/useGoBack';
import { SortDirEnum } from 'models/Sort';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import type { MiproBleDevice } from 'store/user';
import { setUseBleScanner } from 'store/user';
import { concatRoutes } from 'utils/navigations';
import { removeLeadingZeros } from 'utils/number';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import type { FilterOption } from 'components/Filter/Filter';
import Filter from 'components/Filter/Filter';
import Loader from 'components/Loader/Loader';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Searchbar from 'components/Searchbar/Searchbar';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import Text from 'components/Text/Text';
import Toggle from 'components/Toggle/Toggle';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './CountPlanInventory.module.scss';

type MiProBleService = BleCharacteristic & { serviceId: string };

const getBatteryIcon = (value = 0) => {
  if (value < 10) {
    return 'battery-low';
  }
  if (value <= 50) {
    return 'battery-half';
  }
  if (value <= 80) {
    return 'battery-three-quarters';
  }
  return 'battery-full';
};

enum TabType {
  ALL = 'ALL',
  COUNTED = 'COUNTED',
}

enum ItemSortTypeEnum {
  'PAGE SORT' = 'PAGE SORT',
  'VENDOR' = 'VENDOR',
  'FULL MINO' = 'FULL MINO',
  'MFG ID DESCRIPTION' = 'MFG ID DESCRIPTION',
  'BIN LOCATION' = 'BIN LOCATION',
  'LOT NO' = 'LOT NO',
  'CUSTOMER STOCK NO' = 'CUSTOMER STOCK NO',
  'ITEM NO' = 'ITEM NO',
  'MINO' = 'MINO',
}

interface ListItemProps {
  item: CountGroupItem;
  selectedTab: string;
  searchQuery?: string;
  disabled?: boolean;
  triggerCloseItem?: number;
  onClear?: () => void;
  onSelect?: () => void;
  countGroup?: CountGroup;
}

const ListItem = ({
  item,
  selectedTab,
  searchQuery,
  disabled,
  triggerCloseItem,
  onClear,
  onSelect,
  countGroup,
}: ListItemProps): JSX.Element => {
  const { t } = useTranslation();
  const itemRef = useRef<HTMLIonItemSlidingElement>(null);
  const allItemsTab = selectedTab === TabType.ALL;

  useEffect(() => {
    if (toNumber(triggerCloseItem) > 0) {
      void itemRef.current?.closeOpened();
    }
  }, [triggerCloseItem]);

  return (
    <IonItemSliding
      ref={itemRef}
      disabled={disabled}
      data-testid={`item-${item.uniqueId}`}
      // TUDU: key items like this would fix item reference bugs in other places
      key={`${selectedTab}-${item.uniqueId}`}
    >
      {ifRender(
        item.hasCount,
        <IonItemOptions>
          <IonItemOption
            className={classes.options}
            onClick={onClear}
            data-testid="clear-button"
          >
            <FontAwesomeIcon icon={['far', 'eraser']} />
            <Text variant="mipro-body-copy" text={t('inventory:clear')} />
          </IonItemOption>
        </IonItemOptions>
      )}
      <ActionRow
        className={classNames(classes.item, {
          [classes.selectedItem]: and(allItemsTab, item.hasCount),
          [classes.deletedItem]: or(disabled, item.isOptimisticallyUpdating),
        })}
        withoutHrefArrow={and(
          !item?.hasCount,
          !disabled,
          !item.hasEditedMinMax
        )}
        onClick={() => {
          if (!disabled) {
            onSelect?.();
          }
        }}
        testid={`row-${item.uniqueId}`}
      >
        <IonGrid>
          <IonRow
            className={classNames(classes.itemText, classes.itemTitle, {
              [classes.firstRow]: toNumber(item.onOrderQty) === 0,
            })}
          >
            <Text
              variant="mipro-product-headline"
              className={classes.itemName}
              text={or(item.description, emptyString)}
              textQuery={searchQuery}
            />
            <div className={classes.itemIcons}>
              {ifRender(
                item.type === 'M',
                <div className={classes.itemCheckCount}>
                  <FontAwesomeIcon
                    className={classNames({
                      [classes.itemDisabledIcon]: disabled,
                      [classes.itemMemoIcon]: !disabled,
                    })}
                    icon={['fas', 'memo']}
                  />
                </div>
              )}
              {ifRender(
                item.hasEditedMinMax,
                <div className={classes.itemCheckCount}>
                  <div
                    className={classNames(classes.itemCircle, {
                      [classes.itemDisabledIcon]: disabled,
                      [classes.itemSliderIcon]: !disabled,
                    })}
                  >
                    <FontAwesomeIcon icon={['fas', 'sliders-simple']} />
                  </div>
                </div>
              )}
              {ifRender(
                item.hasCount,
                <div className={classes.itemCheckCount}>
                  <div
                    className={classNames(classes.itemCircle, {
                      [classes.itemDisabledIcon]: disabled,
                      [classes.itemCheckIcon]: !disabled,
                    })}
                  >
                    <FontAwesomeIcon icon={['fas', 'check']} />
                  </div>
                  <Text
                    variant="mipro-product-headline"
                    text={toString(item.actualCount)}
                  />
                </div>
              )}
            </div>
          </IonRow>
          {ifRender(
            toNumber(item.onOrderQty) !== 0,
            <IonRow className={classNames(classes.itemText, classes.firstRow)}>
              <Text
                className={classes.onOrderQty}
                variant="mipro-report-info"
                text={t('inventory:onOrderQty', {
                  onOrderQty: item.onOrderQty,
                })}
                textQuery={searchQuery}
              />
            </IonRow>
          )}
          <IonRow className={classes.itemText}>
            <Text
              className={classes.text}
              variant="mipro-report-info"
              text={t('inventory:bin', { bin: or(item.bin, emptyString) })}
              textQuery={searchQuery}
            />
            <Text
              className={classes.text}
              variant="mipro-report-info"
              text={t('inventory:mino', {
                mino: or(item.mino, emptyString),
              })}
              textQuery={searchQuery}
            />
          </IonRow>
          <IonRow className={classes.itemText}>
            <Text
              className={classes.text}
              variant="mipro-report-info"
              text={t('inventory:itemNo', {
                itemNo: or(removeLeadingZeros(item.itemNo), emptyString),
              })}
              textQuery={searchQuery}
            />
            <Text
              className={classes.text}
              variant="mipro-report-info"
              text={t('inventory:uom', { uom: or(item.uom, emptyString) })}
            />
          </IonRow>
          {ifRender(
            or(isStartVMI(countGroup), countGroup?.storeroomNo !== '000'),
            <IonRow className={classes.itemText}>
              <Text
                className={classes.text}
                variant="mipro-report-info"
                text={t('inventory:csn', {
                  csn: or(item.custStockNo, emptyString),
                })}
                textQuery={searchQuery}
              />
            </IonRow>
          )}
          {ifRender(
            !item.allowEdit,
            <Alert
              text={t('inventory:updateWarning')}
              className={classes.warningText}
            />
          )}
        </IonGrid>
      </ActionRow>
    </IonItemSliding>
  );
};

const CountPlanInventory = (): JSX.Element => {
  const { countPlanId, groupId, groupUniqueId } =
    useParams<CreatePlanAndGroupsURLParams>();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isOnline } = useNetworkStatus();
  const { addToast } = useToasts();
  const { goBack } = useGoBack();
  const { useBleScanner, pairedBleDevices, connectedBleDevices } = useSelector(
    (state: RootState) => state.user
  );

  const pageRef = useRef<HTMLIonContentElement>(null);

  const [selectedItem, setSelectedItem] = useState<CountGroupItem>();
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);
  const [isSubmitConfirm, setIsSubmitConfirm] = useState(false);
  const [isCompleteCountConfirm, setIsCompleteCountConfirm] = useState(false);
  const [countZeroOpen, setCountZeroOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<TabType>(TabType.ALL);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [showSortModal, setShowSortModal] = useState(false);
  const [triggerScan, setTriggerScan] = useState(0);
  const [openAddItemModal, setOpenAddItemModal] = useState(false);
  const [triggerCloseItem, setTriggerCloseItem] = useState(0);
  const [triggerNextItem, setTriggerNextItem] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [triggerSwipeLeft, setTriggerSwipeLeft] = useState(0);
  const [triggerSwipeRight, setTriggerSwipeRight] = useState(0);

  const {
    countGroup,
    isLoading: countGroupLoading,
    countPlanOptions,
  } = useGetCountGroup({ countPlanId, groupId, groupUniqueId });

  const { onUpdateCountGroup } = useUpdateCountGroup({ countPlanId, groupId });
  const { onAddZeroCounts } = useAddZeroCounts({
    groupUniqueId: toString(countGroup?.uniqueId),
  });

  const { data: customerData, isLoading: customerLoading } = useGetCustomer({
    miLoc: toString(countGroup?.miLoc),
    id: toString(countGroup?.customerNo),
    searchType: 'customer',
  });

  const isPIBelting = isPiBelting(countGroup);

  const getSortFieldData = (key = '') => {
    switch (key) {
      case ItemSortTypeEnum.VENDOR:
        return {
          key,
          name: t('inventory:sortVendor'),
          field: 'mfrName',
        };
      case ItemSortTypeEnum['MFG ID DESCRIPTION']:
        return { key, name: t('inventory:sortMfrName'), field: 'description' };
      case ItemSortTypeEnum['BIN LOCATION']:
        return { key, name: t('inventory:sortBin'), field: 'bin' };
      case ItemSortTypeEnum['LOT NO']:
        return {
          key,
          name: t(
            `inventory:${toString(choose(isPIBelting, 'sortSlab', 'sortLot'))}`
          ),
          field: 'lotNo',
        };
      case ItemSortTypeEnum['CUSTOMER STOCK NO']:
        return { key, name: t('inventory:sortCSN'), field: 'custStockNo' };
      case ItemSortTypeEnum['ITEM NO']:
        return { key, name: t('inventory:sortItemNo'), field: 'itemNo' };
      case ItemSortTypeEnum.MINO:
        return { key, name: t('inventory:sortMino'), field: 'groupSerialNo' };
      case ItemSortTypeEnum['FULL MINO']:
        return { key, name: t('inventory:sortFullMino'), field: 'mino' };
      case ItemSortTypeEnum['PAGE SORT']:
      default:
        return { key, name: t('inventory:sortPage'), field: 'pageNo, lineNo' };
    }
  };

  const getSortDirData = (key: string) => {
    switch (key) {
      case SortDirEnum.DESCENDING:
        return 'DESC';
      case SortDirEnum.ASCENDING:
      default:
        return 'ASC';
    }
  };

  const defaultSortField = or(
    countGroup?.localSortField,
    countGroup?.sortSequenceName,
    ItemSortTypeEnum['PAGE SORT']
  );
  const defaultSortDir = or(countGroup?.localSortDir, SortDirEnum.ASCENDING);
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortDir, setSortDir] = useState(defaultSortDir);
  const [updateGroupSort, setUpdateGroupSort] = useState(false);

  useEffect(() => {
    if (countGroup?.uniqueId) {
      setSortField(defaultSortField);
      setSortDir(defaultSortDir);
      setUpdateGroupSort(true);
    }
  }, [countGroup?.uniqueId, defaultSortDir, defaultSortField]);

  useEffect(() => {
    if (
      and(
        !!countGroup?.uniqueId,
        updateGroupSort,
        or(sortField !== defaultSortField, sortDir !== defaultSortDir)
      )
    ) {
      onUpdateCountGroup({
        groupUniqueId: toString(countGroup?.uniqueId),
        sortField,
        sortDir,
        miLoc: countGroup?.miLoc,
        customerNo: countGroup?.customerNo,
      });
      setUpdateGroupSort(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countGroup?.uniqueId, sortDir, sortField]);

  const sortFieldOptions: FilterOption[] = map(
    Object.keys(ItemSortTypeEnum),
    (key) => getSortFieldData(key)
  );
  const sortDirOptions: FilterOption[] = [
    {
      key: SortDirEnum.ASCENDING,
      name: t('sort:alphaAscending'),
      icon: 'arrow-up',
    },
    {
      key: SortDirEnum.DESCENDING,
      name: t('sort:alphaDescending'),
      icon: 'arrow-down',
    },
  ];

  const selectedFilters = useMemo(
    () => [
      or(find(sortFieldOptions, { key: sortField }), {
        key: ItemSortTypeEnum['PAGE SORT'],
        name: getSortFieldData().name,
      }),
      find(sortDirOptions, { key: sortDir }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortField, sortDir]
  );

  const {
    items,
    total: itemsTotal,
    showLoader: itemsLoading,
    isEmptyResponse: itemsEmptyResponse,
  } = useFindCountGroupItems({
    countPlanId,
    groupId: toString(countGroup?.uniqueId),
    query: searchQueryValue,
    sortField: getSortFieldData(sortField).field,
    sortDir: getSortDirData(sortDir),
  });

  const {
    items: countedItems,
    total: countedTotal,
    showLoader: countedItemsLoading,
    isEmptyResponse: countedEmptyResponse,
    findBarcodeItems,
  } = useFindCountGroupItems({
    countPlanId,
    groupId: toString(countGroup?.uniqueId),
    query: searchQueryValue,
    hasCount: true,
    sortField: getSortFieldData(sortField).field,
    sortDir: getSortDirData(sortDir),
  });

  const emptyResponse = or(
    and(selectedTab === TabType.ALL, itemsEmptyResponse),
    and(selectedTab === TabType.COUNTED, countedEmptyResponse)
  );

  const { status: updateStatus, onUpdateItemCount } = useUpdateItemCount();
  const { status: submitOrderStatus, onSubmitInventoryOrder } =
    useSubmitInventoryOrder();
  const { onRemoveCountGroup, status: deleteStatus } = useRemoveCountGroup();

  const isDeleting = deleteStatus === 'loading';
  const isSubmitting = submitOrderStatus === 'loading';

  const isCreateOrder = countPlanOptions?.createOrder === 'Y';

  const allItemsCounted = countedTotal === itemsTotal;

  useEffect(() => {
    if (deleteStatus === 'success') {
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  useEffect(() => {
    if (
      and(
        !!countGroup?.uniqueId,
        !countGroup?.billCustomerName,
        !!customerData?.customerNo
      )
    ) {
      onUpdateCountGroup({
        groupUniqueId: toString(countGroup?.uniqueId),
        billCustomerNo: customerData?.customerNo,
        billCustomerName: customerData?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countGroup?.billCustomerName,
    countGroup?.uniqueId,
    customerData?.customerNo,
    customerData?.name,
  ]);

  useEffect(() => {
    if (and(submitOrderStatus === 'success', !!countPlanId, !!groupId)) {
      history.replace(concatRoutes(inventoryURL(), homeInventoryURL()));
    }
  }, [countPlanId, groupId, history, submitOrderStatus]);

  const listItems = choose(selectedTab === TabType.ALL, items, countedItems);

  const goToNextItem = () => {
    if (or(currentIndex === size(listItems) - 1, currentIndex === -1)) {
      setOpenOrderModal(false);
    } else {
      setSelectedItem(listItems?.[currentIndex + 1]);
      setCurrentIndex(currentIndex + 1);
    }
  };

  const goToPrevItem = () => {
    if (or(currentIndex === 0, currentIndex === -1)) {
      setOpenOrderModal(false);
    } else {
      setSelectedItem(listItems?.[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);
    }
  };

  useEffect(() => {
    if (triggerSwipeLeft > 0) {
      setTriggerSwipeLeft(0);
      goToNextItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSwipeLeft]);

  useEffect(() => {
    if (triggerSwipeRight > 0) {
      setTriggerSwipeRight(0);
      goToPrevItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSwipeRight]);

  useEffect(() => {
    if (and(triggerNextItem > 0, !!listItems)) {
      setTimeout(() => {
        setTriggerNextItem(0);
        goToNextItem();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listItems, triggerNextItem]);

  const showLoader = or(
    countGroupLoading,
    customerLoading,
    itemsLoading,
    countedItemsLoading
  );
  const isOrderModel = or(
    countGroup?.model === 'ORDER',
    isPiBelting(countGroup)
  );

  const doSearch = (query: string) => {
    if (!isEmpty(query)) {
      return;
    }
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const onSearch = (query: string) => {
    setSearchQuery(query);
    setTimeout(() => {
      void doSearch(query);
    }, 300);
  };

  const calculateOrderQuantity = (
    quantity: number,
    minValue?: number,
    maxValue?: number
  ): number => {
    let orderQuantity = toNumber(quantity);
    if (!isOrderModel) {
      // if BOH <= min then place order to bring up to max; otherwise no order
      orderQuantity = 0;
      if (quantity <= toNumber(minValue)) {
        orderQuantity = toNumber(maxValue) - quantity;
      }
      orderQuantity = toNumber(choose(orderQuantity > 0, orderQuantity, 0));
    }
    return orderQuantity;
  };

  const handleOrderQuantity = (
    itemId: string,
    quantity: number,
    width?: number,
    length?: number,
    minValue?: number,
    maxValue?: number
  ) => {
    const orderQuantity = calculateOrderQuantity(quantity, minValue, maxValue);
    onUpdateItemCount({
      itemId,
      groupUniqueId: toString(countGroup?.uniqueId),
      actualCount: toString(or(quantity, 0)),
      orderQuantity,
      width,
      length,
    });
  };

  const disabledActions = or(
    countedTotal === 0,
    countGroup?.isDeleted,
    isSubmitting
  );
  const disableItems = or(countGroup?.isDeleted, isSubmitting);

  const tabs = [
    {
      key: TabType.ALL,
      text: t('inventory:allItems', { count: itemsTotal }),
      buttonText: t('inventory:review'),
    },
    {
      key: TabType.COUNTED,
      text: t('inventory:counted', { count: countedTotal }),
      buttonText: t(
        `inventory:${toString(
          choose(isCreateOrder, 'submitOrder', 'submitCount')
        )}`
      ),
    },
  ];

  const handleSubmitInventoryOrder = (
    keepLocal: boolean,
    completeGroup = false
  ) => {
    onSearch('');
    if (and(!!countGroup, !!countedItems)) {
      const { miLoc, storeroomNo, amtPctDiff, qtyPctDiff, countType } =
        countGroup as CountGroup;
      onSubmitInventoryOrder({
        miLoc,
        countPlanId,
        groupId: toNumber(groupId),
        groupUniqueId: toString(countGroup?.uniqueId),
        storeroomNo,
        amtPctDiff,
        qtyPctDiff,
        createOrder: isCreateOrder,
        unassignGroup: !keepLocal,
        countType,
        completeGroup,
        keepLocal,
      });
    }
  };

  const { connectMiproBleDevice, triggerDisconnectBle } = useDevice();
  const [isBleScanning, setIsBleScanning] = useState(false);
  const [isBleConnecting, setIsBleConnecting] = useState<Dictionary<boolean>>({
    all: false,
  });
  const [bleBattery, setBleBattery] = useState<Dictionary<number>>({});
  const [scannedDevices, setScannedDevices] = useState<MiproBleDevice[]>([]);
  const [triggerBleScan, setTriggerBleScan] = useState('');
  const [triggerSaveAfterScan, setTriggerSaveAfterScan] = useState('');
  const [blePairModal, setBlePairModal] = useState(false);
  const [connectionLostModal, setConnectionLostModal] = useState(false);
  const connectedMiproBleDevices = filter(
    connectedBleDevices,
    (d) => !!d.deviceId
  );
  const firstBleDevice = head(connectedMiproBleDevices);

  useEffect(() => {
    const [triggerValue, itemIndex, scanValue, validForm] = split(
      triggerSaveAfterScan,
      '#'
    );
    if (toNumber(triggerValue) > 0) {
      setTriggerSaveAfterScan('');
      setTriggerBleScan('');
      if (validForm === 'N') {
        addToast({
          variant: 'mipro-toast',
          type: ToastType.warn,
          text: t('inventory:bleScanList', {
            code: scanValue,
          }),
          testid: 'ble-dirty-form-toast',
        });
        return;
      }
      setCurrentIndex(toNumber(itemIndex));
      setSelectedItem(listItems?.[toNumber(itemIndex)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSaveAfterScan]);

  useEffect(() => {
    if (updateStatus === 'success') {
      setTriggerCloseItem(Date.now());
      if (triggerSwipeRight < 0) {
        setTriggerSwipeRight(0);
        goToPrevItem();
        return;
      }
      if (triggerSwipeLeft < 0) {
        setTriggerSwipeLeft(0);
        goToNextItem();
        return;
      }
      const [triggerValue, itemIndex] = split(triggerSaveAfterScan, '#');
      if (toNumber(triggerValue) < 0) {
        setTriggerSaveAfterScan('');
        setTriggerBleScan('');
        setCurrentIndex(toNumber(itemIndex));
        setSelectedItem(listItems?.[toNumber(itemIndex)]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  const forceCountAllItems = countPlanOptions?.forceCountAllItems === 'Y';
  const { headerActions } = useInventoryHeaderActions({
    customOptions: [
      {
        testid: 'sort-items',
        text: t('inventory:sortItems'),
        onClick: () => setShowSortModal(true),
        children: (
          <div className={classes.sortBadge}>
            {map(selectedFilters, (i) =>
              ifRender(
                !!i,
                <div key={i?.key} className={classes.sortLabel}>
                  {ifRender(
                    i?.icon,
                    <FontAwesomeIcon icon={i?.icon as IconProp} />
                  )}
                  <Text text={i?.name} />
                </div>
              )
            )}
          </div>
        ),
      },
      {
        testid: 'toggle-scan',
        text: t(
          `inventory:${toString(
            choose(useBleScanner, 'useBleScannerText', 'useCameraScannerText')
          )}`
        ),
        autoClose: false,
        onClick: () => dispatch(setUseBleScanner(!useBleScanner)),
        children: (
          <div className={classes.scanModeToggle}>
            <Toggle
              className={classNames(classes.toggle, {
                [classes.isConnected]: firstBleDevice?.deviceId,
              })}
              checked={!useBleScanner}
              lines="none"
              testid="scan-toggle"
            />
          </div>
        ),
      },
    ],
    withAddItem: and(
      isOnline,
      countPlanOptions?.hasManualSheets === 'Y',
      !countGroup?.isDeleted,
      toNumber(countGroup?.downloadedItems) > 0
    ),
    onAddItem: () => setOpenAddItemModal(true),
    withAddCountZero: forceCountAllItems,
    onAddCountZero: () => setCountZeroOpen(true),
    withDeleteCount: true,
    onDeleteGroupCount: () => {
      if (isOnline) {
        setConfirmationModal(true);
        return;
      }
      addToast({
        type: ToastType.alert,
        variant: 'mipro-toast',
        text: t('inventory:offlineDeleteMsg'),
        testid: 'count-group-delete-warn-toast',
      });
    },
  });

  const title = or(
    countGroup?.billCustomerName,
    customerData?.name,
    countGroup?.planName
  );

  const connectDevice = async (
    device: MiproBleDevice,
    disconnect?: boolean
  ) => {
    setIsBleConnecting((prev) => ({ ...prev, [device.deviceId]: true }));
    try {
      await connectMiproBleDevice?.(device, !!disconnect);
      setScannedDevices([]);
      setIsBleScanning(false);
      setBlePairModal(false);
    } catch (e) {
      addToast({
        variant: 'mipro-toast',
        type: ToastType.error,
        text: t('inventory:bleConnectError'),
        testid: 'ble-connect-error-toast',
      });
    }
    setIsBleConnecting((prev) => ({ ...prev, [device.deviceId]: false }));
  };

  const onPairDevice = async () => {
    setBlePairModal(true);
    const isBleEnabled = await BleClient.isEnabled();
    if (!isBleEnabled) {
      addToast({
        variant: 'mipro-toast',
        type: ToastType.error,
        text: t('inventory:bleScannerError'),
        button: {
          role: 'undo',
          text: t('common:settings'),
          handler: () => BleClient.openAppSettings(),
        },
        testid: 'bluetooth-error-toast',
      });
      return;
    }
    try {
      setIsBleScanning(true);
      await BleClient.requestLEScan({}, (res) => {
        if (res.uuids?.length) {
          setScannedDevices((prev) => [
            ...prev,
            {
              deviceId: res.device.deviceId,
              name: res.device.name,
            },
          ]);
        }
      });
    } catch (e) {
      // ignore error
    }
  };

  useEffect(() => {
    const onListen = () => {
      try {
        forEach(connectedMiproBleDevices, (device) => {
          forEach(device.services, (s) => {
            forEach(s.characteristics, async (c) => {
              if (c.uuid === '00002a19-0000-1000-8000-00805f9b34fb') {
                const value = await BleClient.read(
                  device.deviceId,
                  s.uuid,
                  c.uuid
                );
                setBleBattery((prev) => ({
                  ...prev,
                  [device.deviceId]: value.getUint8(0),
                }));
              }
            });
          });
        });
      } catch (e) {
        // ignore error
      }
    };
    onListen();
    const interval = setInterval(() => onListen(), 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedBleDevices]);

  const [disconnectedDeviceTrigger, disconnectedDeviceName] = split(
    triggerDisconnectBle,
    '#'
  );
  useEffect(() => {
    if (
      and(!!disconnectedDeviceName, toNumber(disconnectedDeviceTrigger) > 0)
    ) {
      setConnectionLostModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDisconnectBle]);

  const addService = (
    notify: MiProBleService[],
    c: BleCharacteristic,
    s: BleService
  ) => {
    if (c.properties.notify) {
      notify.push({ ...c, serviceId: s.uuid });
    }
  };

  const removeService = async (device: MiproBleDevice, s: MiProBleService) => {
    try {
      await BleClient.stopNotifications(
        toString(device.deviceId),
        s.serviceId,
        s.uuid
      );
    } catch (e) {
      // ignore error
    }
  };

  const addNotification = async (
    device: MiproBleDevice,
    s: MiProBleService
  ) => {
    try {
      const doFindScan = async (scanValue: string) => {
        const item = head(await findBarcodeItems?.(scanValue));
        if (item) {
          const index = findIndex(listItems, {
            uniqueId: item.uniqueId,
          });
          if (or(isSubmitting, openAddItemModal)) {
            addToast({
              variant: 'mipro-toast',
              type: ToastType.warn,
              text: t('inventory:bleScanList', {
                code: scanValue,
              }),
              testid: 'ble-dirty-form-toast',
            });
            return;
          }
          if (openOrderModal) {
            setTriggerBleScan(`${Date.now()}#${index}#${scanValue}`);
            return;
          }
          setCurrentIndex(index);
          setSelectedItem(item);
          setOpenOrderModal(true);
          return;
        }
        addToast({
          variant: 'mipro-toast',
          type: ToastType.warn,
          text: t('inventory:bleScanNotFound', {
            code: scanValue,
          }),
          testid: 'ble-connect-error-toast',
        });
      };
      const doNotification = (value: DataView) => {
        let scanValue = '';
        for (let i = 0; i < value.byteLength; i += 1) {
          scanValue += String.fromCharCode(value.getUint8(i));
        }
        scanValue = trim(scanValue);
        if (size(scanValue) < 3) {
          return;
        }
        void doFindScan(scanValue);
      };
      await BleClient.startNotifications(
        toString(device.deviceId),
        s.serviceId,
        s.uuid,
        doNotification
      );
    } catch (e) {
      // ignore error
    }
  };

  useEffect(() => {
    const notify: MiProBleService[] = [];
    const onListen = () => {
      forEach(connectedMiproBleDevices, (device) => {
        forEach(device.services, (s) => {
          forEach(s.characteristics, (c) => addService(notify, c, s));
        });
      });
      forEach(connectedMiproBleDevices, (device) =>
        forEach(notify, (s) => addNotification(device, s))
      );
    };
    onListen();

    return () => {
      forEach(connectedMiproBleDevices, (device) =>
        forEach(notify, (s) => removeService(device, s))
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    connectedBleDevices,
    listItems,
    openOrderModal,
    openAddItemModal,
    isSubmitting,
  ]);

  return (
    <IonPage test-id="count-group-items-page" ref={pageRef}>
      <Header
        testid="count-group-items"
        hideCartButton
        hideMenuButton
        backButton={{
          className: classes.backButton,
        }}
        customTitle={
          <InventoryCustomTitle title={title} subTitle={countGroup?.name} />
        }
        headerActions={{ ...headerActions, className: classes.headerAction }}
        className={classes.header}
      >
        <SegmentTabs
          className={classes.countPlanTabs}
          selectedClassName={classes.selectedTab}
          options={tabs}
          value={selectedTab}
          setValue={(v) => setSelectedTab(v as TabType)}
          testid="count-plan-tabs"
        />
        {ifRender(
          !!countGroup?.isDeleted,
          <Alert
            text={t('inventory:countGroupNotExist')}
            className={classes.isDeletedLabel}
          />
        )}
        <IonRow className={classes.searchRow}>
          <Searchbar
            disabled={isSubmitting}
            className={classes.searchBar}
            inputClassName={classes.searchInput}
            value={searchQuery}
            placeholder={t('inventory:searchPlaceholder')}
            setValue={setSearchQuery}
            onSearch={() => onSearch(searchQuery)}
            onClear={() => setSearchQuery('')}
            variant="light"
            testid="search-input"
          />
          {ifRender(
            and(!countGroup?.isDeleted, !useBleScanner),
            <Scanner
              pageRef={pageRef}
              className={classes.scanButton}
              testid="products-scanner"
              onScan={async (result) => {
                return head(await findBarcodeItems?.(result?.content))
                  ?.description;
              }}
              onDone={async (result) => {
                const item = head(await findBarcodeItems?.(result?.content));
                if (item) {
                  const index = findIndex(listItems, {
                    uniqueId: item.uniqueId,
                  });
                  setCurrentIndex(index);
                  setSelectedItem(item);
                  setOpenOrderModal(true);
                }
              }}
              triggerScan={triggerScan}
              showCameraAcessMsg
            />
          )}
          {ifRender(
            and(!countGroup?.isDeleted, useBleScanner),
            <Button
              variant="action"
              className={classNames(classes.scanBleButton, {
                [classes.isConnected]: firstBleDevice?.deviceId,
              })}
              icon={[
                'far',
                choose(
                  firstBleDevice?.deviceId,
                  getBatteryIcon(
                    bleBattery[toString(firstBleDevice?.deviceId)]
                  ),
                  'bluetooth'
                ) as IconName,
              ]}
              text={or(firstBleDevice?.name, t('inventory:pairScanner'))}
              onClick={onPairDevice}
              testid="ble-scan-button"
            />
          )}
        </IonRow>
      </Header>
      <IonContent scrollY={false}>
        <SheetModal
          title={t('inventory:pairScanner')}
          isOpen={blePairModal}
          setIsOpen={setBlePairModal}
          onClose={async () => {
            try {
              await BleClient.stopLEScan();
            } catch (e) {
              // ignore error
            }
            setIsBleConnecting({});
            setIsBleScanning(false);
            setScannedDevices([]);
          }}
          withRightCloseButton
          initialBreakpoint={0.75}
          testid="pair-scanner-modal"
        >
          <div className={classes.scanHelpModal}>
            <Text
              text={t('inventory:bleAvailableTitle')}
              variant="content-heavy"
            />
            <IonRow className={classes.scannedList}>
              {map(
                concat(
                  connectedMiproBleDevices,
                  filter(
                    pairedBleDevices,
                    (d) => !connectedBleDevices?.[d.deviceId]?.deviceId
                  ),
                  filter(
                    scannedDevices,
                    (d) =>
                      !or(
                        pairedBleDevices?.[d.deviceId]?.deviceId,
                        connectedBleDevices?.[d.deviceId]?.deviceId
                      )
                  )
                ),
                (device: MiproBleDevice) => {
                  const isConnectedDevice =
                    !!connectedBleDevices?.[device.deviceId]?.deviceId;
                  return (
                    <ActionRow
                      key={device.deviceId}
                      className={classes.scannedDevice}
                      text={or(device.name, device.deviceId)}
                      onClick={() => connectDevice(device, isConnectedDevice)}
                      leftButton={{ icon: ['far', 'bluetooth'], testid: '' }}
                      rightButton={{
                        text: t(
                          `inventory:${toString(
                            choose(
                              isConnectedDevice,
                              'disconnectScanner',
                              choose(
                                isBleConnecting[device.deviceId],
                                'connectingScanner',
                                'connectScanner'
                              )
                            )
                          )}`
                        ),
                        variant: 'link',
                        testid: '',
                      }}
                      testid={`ble-device-${device.deviceId}`}
                    >
                      {ifRender(
                        isConnectedDevice,
                        <div className={classes.scanBattery}>
                          (
                          <FontAwesomeIcon
                            icon={[
                              'far',
                              getBatteryIcon(bleBattery[device.deviceId]),
                            ]}
                          />
                          {bleBattery[device.deviceId]})
                        </div>
                      )}
                    </ActionRow>
                  );
                }
              )}
            </IonRow>
            <Loader
              isOpen={isBleScanning}
              text={t('inventory:bleScanningText')}
              className={classes.bleLoader}
            />
            <Text
              text={t('inventory:bleScannerHelpText')}
              variant="content-default"
            />
            <div className={classes.qrConfigure}>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkAQMAAABKLAcXAAAABlBMVEX///8AAABVwtN+AAAAnUlEQVQ4y53Sy4kFIBQE0cqg88+yMqhZ+JjPTkcQPBvpe2kqa1HJvQSV83hROjbMVxH9R7Z3gSq/Z7hRparfO7sTgDq+z51ytq2cD9pJqxs8KE4HmGfNlyLHpM43t5ozN80ntXVSPylzowofxD6XwYOKspX5IIAQ6dOJO/2p3oMET+7gRenaJ/WbINp8VXPongSK0c8MN6q0nZrf6wvkPf2X/TXWVgAAAABJRU5ErkJggg=="
                alt=""
              />
              <br />
              EBLE
            </div>
            <div className={classes.opticonfigure}>
              <Text
                variant="content-default"
                text={t('inventory:bleScannerHelpLinkText')}
              />
              <Button
                variant="link"
                textVariant="content-default"
                text={t('inventory:bleScannerHelpLink')}
                onClick={() => {
                  void Browser.open({
                    url: 'https://opticonfigure.opticon.com/configure?type=1D&i=0&t=39&pdf=A4&sheet=W1siRUJMRSJdXQ%3D%3D',
                  });
                }}
                testid="opticonfigure-button"
              />
            </div>
          </div>
        </SheetModal>
        <DiscardModal
          title={t('inventory:bleConnectionLostTitle')}
          discardMsg={t('inventory:bleConnectionLost', {
            name: disconnectedDeviceName,
          })}
          className={classes.discardModal}
          isOpen={connectionLostModal}
          setIsOpen={setConnectionLostModal}
          initialBreakpoint={0.4}
          withRightCloseButton
          testid="connection-lost-modal"
          goBackButtonTitle={t('inventory:bleReconnectModal')}
          discardButtonTitle={t('inventory:bleSwitchToCamera')}
          onDiscardClick={() => dispatch(setUseBleScanner(false))}
          onGoBackClick={onPairDevice}
        />
        <Filter
          className={classes.sortModal}
          variant="sort"
          initialBreakpoint={0.6}
          selectedItems={selectedFilters}
          customContent={
            <Button
              variant="link"
              className={classes.defaultSortButton}
              text={t('inventory:defaultSort', {
                field: getSortFieldData(countGroup?.sortSequenceName).name,
              })}
              onClick={() => {
                setSortField?.(
                  or(
                    countGroup?.sortSequenceName,
                    ItemSortTypeEnum['PAGE SORT']
                  )
                );
                setSortDir?.(SortDirEnum.ASCENDING);
                setShowSortModal(false);
              }}
              testid="default-sort-button"
            />
          }
          setFilterData={[
            (option) => setSortField?.(toString(option?.key)),
            (option) => setSortDir?.(toString(option?.key)),
          ]}
          filterOptions={[
            {
              title: t('common:sortBy'),
              options: sortFieldOptions,
            },
            {
              title: t('common:sortOrder'),
              options: sortDirOptions,
            },
          ]}
          isOpen={showSortModal}
          setIsOpen={setShowSortModal}
          testid="report-sorter"
        />
        <IonLoading
          isOpen={isDeleting}
          message={t('inventory:deleteCountGroupOfflineMsg')}
        />
        <IonLoading
          isOpen={isSubmitting}
          message={choose(
            isCreateOrder,
            t('inventory:submitOrderLoading'),
            t('inventory:submitCountGroupLoading')
          )}
        />
        {ifRender(
          and(
            itemsEmptyResponse,
            !showLoader,
            !searchQueryValue,
            selectedTab === TabType.ALL
          ),
          <WarningMessage
            icon={['far', 'info-circle']}
            title={t('inventory:countItemsEmptyMsg')}
            className={classes.warningMessage}
          />
        )}
        {ifRender(
          and(!!searchQueryValue, !showLoader, emptyResponse),
          <WarningMessage
            className={classes.warningMessage}
            title={t('inventory:emptySearchTitle')}
            body={t('inventory:emptySearchBody')}
          />
        )}
        {ifRender(
          and(
            !searchQueryValue,
            !showLoader,
            selectedTab === TabType.COUNTED,
            isEmpty(countedItems)
          ),
          <WarningMessage
            className={classes.warningMessage}
            title={t('inventory:emptyCountedTitle')}
            body={t('inventory:emptyCountedBody')}
          />
        )}
        <Virtuoso
          data={listItems}
          increaseViewportBy={{ top: 500, bottom: 500 }}
          components={{
            Footer: () => (
              <>
                {ifRender(
                  showLoader,
                  <Loader
                    testid="loader"
                    text={t('common:loading')}
                    className={classes.loader}
                    isOpen={showLoader}
                  />
                )}
              </>
            ),
          }}
          itemContent={(index, item) => (
            <ListItem
              key={item.uniqueId}
              countGroup={countGroup}
              item={item}
              selectedTab={selectedTab}
              searchQuery={searchQuery}
              disabled={or(disableItems, !item.allowEdit)}
              triggerCloseItem={triggerCloseItem}
              onClear={() => {
                setSelectedItem(item);
                setDiscardModalIsOpen(true);
              }}
              onSelect={() => {
                setSelectedItem(item);
                setOpenOrderModal(true);
                setCurrentIndex(index);
              }}
            />
          )}
        />
        <OrderModal
          calculateOrderQuantity={calculateOrderQuantity}
          handleOrderQuantity={handleOrderQuantity}
          setTriggerScan={setTriggerScan}
          item={selectedItem}
          setTriggerSwipeLeft={setTriggerSwipeLeft}
          setTriggerSwipeRight={setTriggerSwipeRight}
          setIsOpen={setOpenOrderModal}
          testid="order-modal"
          isOpen={openOrderModal}
          isOrderModel={isOrderModel}
          setTriggerNextItem={setTriggerNextItem}
          triggerBleScan={triggerBleScan}
          setTriggerSaveAfterScan={setTriggerSaveAfterScan}
          itemCount={or(items?.length, 0)}
          countedItemCount={or(countedItems?.length, 0)}
          isFiltered={size(searchQuery) > 0}
          hideScanNext={and(useBleScanner, !!firstBleDevice?.deviceId)}
          countGroup={countGroup}
        />
        <AddItemModal
          setIsOpen={setOpenAddItemModal}
          isOpen={openAddItemModal}
          testid="add-item"
          countGroup={countGroup}
        />
        <SheetModal
          isOpen={showConfirmationModal}
          setIsOpen={setConfirmationModal}
          testid="delete-count-group-modal"
          title={t('inventory:deleteGroupCountTitle')}
          initialBreakpoint={0.4}
          backdropDismiss={false}
          withRightCloseButton
          className={classes.confirmModal}
          contentClass={classes.modalContent}
        >
          <IonRow>
            <IonCol size="12">
              <Text
                text={t('inventory:deleteGroupCountMessage')}
                variant="mipro-body-copy"
              />
            </IonCol>
          </IonRow>
          <IonRow className={classes.btnWrapper}>
            <IonCol size="12">
              <Button
                variant="action"
                text={t('inventory:goBack')}
                testid="count-group-go-back-button"
                className={classes.fullWidth}
                onClick={() => setConfirmationModal(false)}
              />
            </IonCol>
            <IonCol size="12">
              <Button
                variant="mipro-danger-default"
                text={t('inventory:deleteGroup')}
                testid="count-group-delete-button"
                className={classes.fullWidth}
                onClick={() => {
                  onRemoveCountGroup({
                    name: toString(countGroup?.customerName),
                    miLoc: toString(countGroup?.miLoc),
                    groupUniqueId: toString(countGroup?.uniqueId),
                    countPlanId,
                    isDeleted: countGroup?.isDeleted,
                    deleteGroupOnServer: isStartVMI(countGroup),
                    groupId: toString(countGroup?.groupId),
                  });
                  setConfirmationModal(false);
                }}
              />
            </IonCol>
          </IonRow>
        </SheetModal>
        <DiscardModal
          title={t(
            `inventory:${toString(
              choose(
                forceCountAllItems,
                'zeroItemCountTitle',
                'deleteItemCountTitle'
              )
            )}`
          )}
          className={classes.discardModal}
          isOpen={discardModalIsOpen}
          setIsOpen={setDiscardModalIsOpen}
          initialBreakpoint={0.4}
          backdropDismiss={false}
          withRightCloseButton
          testid="discard-changes-modal"
          goBackButtonTitle={t('inventory:goBack')}
          discardButtonTitle={t(
            `inventory:${toString(
              choose(forceCountAllItems, 'zeroItemCount', 'deleteItemCount')
            )}`
          )}
          onDiscardClick={() => {
            onUpdateItemCount({
              itemId: toString(selectedItem?.uniqueId),
              groupUniqueId: toString(countGroup?.uniqueId),
              actualCount: toString(choose(forceCountAllItems, '0', '')),
              orderQuantity: 0,
              hasCount: forceCountAllItems,
              hasLocalCount: forceCountAllItems,
            });
          }}
          secondayButtonVariant="mipro-danger-default"
        />
        <ConfirmDialog
          isOpen={and(isCompleteCountConfirm, !isStartVMI(countGroup))}
          setIsOpen={setIsCompleteCountConfirm}
          title={t('inventory:completeCountGroup')}
          text={t('inventory:completeCountGroupText')}
          primaryText={t('common:yes')}
          secondaryText={t('common:no')}
          onPrimaryClick={() => handleSubmitInventoryOrder(false, true)}
          onSecondaryClick={() => handleSubmitInventoryOrder(false)}
          testid="submit-order-confirm-completed-modal"
        />
        <ConfirmDialog
          isOpen={isSubmitConfirm}
          setIsOpen={setIsSubmitConfirm}
          title={t('inventory:submitOrderConfirmTitle')}
          text={t('inventory:submitOrderConfirmMsg')}
          primaryText={t('common:yes')}
          secondaryText={t('common:no')}
          onPrimaryClick={() => handleSubmitInventoryOrder(true)}
          onSecondaryClick={() => handleSubmitInventoryOrder(false)}
          testid="submit-order-confirm-modal"
        />
        <ConfirmDialog
          isOpen={countZeroOpen}
          setIsOpen={setCountZeroOpen}
          title={t('inventory:addZeroCount')}
          text={t('inventory:addZeroCountMessage')}
          primaryText={t('common:yes')}
          secondaryText={t('common:no')}
          onPrimaryClick={() => onAddZeroCounts()}
          testid="add-count-zero-confirm-modal"
        />
      </IonContent>
      {ifRender(
        !countGroup?.isDeleted,
        <IonFooter className={classes.footer}>
          <div className={classes.footerButtons}>
            <Button
              className={classNames(classes.reviewButton, {
                [classes.disabledReview]: disabledActions,
              })}
              variant="action"
              onClick={() => {
                if (selectedTab === TabType.ALL) {
                  setSelectedTab(TabType.COUNTED);
                  return;
                }
                if (!isOnline) {
                  addToast({
                    type: ToastType.alert,
                    variant: 'mipro-toast',
                    text: t('inventory:offlineSubmitOrder'),
                    testid: 'submit-order-offline',
                  });
                  return;
                }
                if (!isCreateOrder) {
                  if (allItemsCounted) {
                    setIsCompleteCountConfirm(true);
                  } else {
                    setIsSubmitConfirm(true);
                  }
                } else {
                  handleSubmitInventoryOrder(false);
                }
              }}
              text={toString(
                tabs.find((tab) => tab.key === selectedTab)?.buttonText
              )}
              disabled={disabledActions}
              testid="review-button"
            />
          </div>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default CountPlanInventory;
