import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { find, includes, isEmpty } from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import Header from 'common/components/Header/Header';
import { and, ifFunction, ifRender, or } from 'common/utils/logicHelpers';
import { TabUnbilledReportKey } from 'providers/ReportsProvider';
import type { UnbilledReportRow } from 'ReportsApp/api/useGetUnbilledReport';
import useGetUnbilledReport from 'ReportsApp/api/useGetUnbilledReport';
import GroupBy from 'ReportsApp/components/GroupBy/GroupBy';
import ReportError from 'ReportsApp/components/ReportError/ReportError';
import useGetFixedHeader from 'ReportsApp/hooks/useGetFixedHeader';
import useGetGroupBy from 'ReportsApp/hooks/useGetGroupBy';
import useGetSortBy from 'ReportsApp/hooks/useGetSortBy';
import useReportHeader from 'ReportsApp/hooks/useReportHeader';
import type { BaseReportURLParams, ReportField } from 'ReportsApp/models';
import type { SortDirEnum } from 'models/Sort';
import { selectIsTeamLocation } from 'store/user/selectors';
import ChangeLocation from 'components/ChangeLocation/ChangeLocation';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import OverlayInfobar from 'components/Overlays/OverlayInfoBar';
import Refresher from 'components/Refresher/Refresher';
import Table from 'components/Table/Table';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './Unbilled.module.scss';
import { useGetUnbilledTableData } from './unbilledHelpers';

const Unbilled = (): JSX.Element => {
  const { t } = useTranslation();
  const namespace = 'ReportApp-UnbilledReport';
  const { reportHeader } = useReportHeader();
  const { miLoc = '' } = useParams<BaseReportURLParams>();
  const { sortDir, onSortBy, sortField } = useGetSortBy(TabUnbilledReportKey);
  const isTeam = useSelector(selectIsTeamLocation);

  // region group by

  const {
    miLoc: stateMiLoc,
    groupByOptions,
    selectedGroupBy,
    setGroupByData,
    userRole,
    showGroupBy,
  } = useGetGroupBy('unbilled');

  const isExecOrCorpUser = includes(['EXEC', 'CORP'], userRole);

  // end region

  // region report fields

  const unbilledReportFields: ReportField[] = useMemo(() => {
    return [
      {
        type: 'currency',
        id: 'totalPossibleAmt',
      },
      {
        type: 'text',
        id: 'unbilledDirectsCnt',
      },
      {
        type: 'text',
        id: 'unbilledDirectsLineCnt',
      },
      {
        type: 'currency',
        id: 'unbilledDirectsAmt',
      },
      {
        type: 'text',
        id: 'unreleasedSummaryBillCnt',
      },
      {
        type: 'currency',
        id: 'unreleasedSummaryBillAmt',
      },
      {
        type: 'text',
        id: 'unapprovedVendorDirectsCnt',
      },
      {
        type: 'text',
        id: 'unapprovedVendorDirectsLineCnt',
      },
      {
        type: 'currency',
        id: 'unapprovedVendorDirectsAmt',
      },
      {
        id: 'otherOrdersReadyToShipCnt',
        type: 'text',
        showEmptyString: isExecOrCorpUser,
      },
      {
        id: 'otherOrdersReadyToShipAmt',
        type: 'currency',
        showEmptyString: isExecOrCorpUser,
      },
    ];
  }, [isExecOrCorpUser]);

  // end region

  // region API call

  const {
    rows,
    summary,
    dataUpdatedAt,
    isLoading,
    error,
    noMoreData,
    refetch,
    fetchNextPage,
    isEmptyResponse,
  } = useGetUnbilledReport({
    miLoc: or(stateMiLoc, miLoc),
    groupBy: selectedGroupBy.key,
    sortField,
    sortDir: sortDir as SortDirEnum,
    enabled: !isTeam,
  });

  // end region

  // region get table data

  const { tableData, tableColumns, totalsRow } = useGetUnbilledTableData({
    drilldownData: rows,
    summaryData: summary as UnbilledReportRow,
    reportFields: unbilledReportFields,
    selectedGroupBy,
  });

  // end region

  // region error content
  let errorContent;

  ifFunction(and(isEmptyResponse, !isLoading), () => {
    errorContent = (
      <WarningMessage
        className={classes.warningMessage}
        icon={['far', 'info-circle']}
        title={t('reports:noReports')}
        testid="unbilled-empty-response"
      />
    );
  });

  ifFunction(isTeam, () => {
    errorContent = (
      <ChangeLocation
        onlineMsg={t('ReportApp-UnbilledReport:locationError')}
        testid="unbilled"
      />
    );
  });

  ifFunction(!isEmpty(error), () => {
    errorContent = (
      <ReportError
        className={classes.warningMessage}
        error={error}
        testid="unbilled-report-error"
      />
    );
  });

  // end region

  // #region fixed header
  const { headerRef, headerTop } = useGetFixedHeader({ tableData });
  // end region

  return (
    <IonPage className={classes.unbilled}>
      <Header
        testid="page-header"
        customTitle={
          <div
            data-testid="custom-title"
            className={classNames(
              classes.customHeader,
              classes.pageCustomTitle
            )}
          >
            {reportHeader}
          </div>
        }
        title={t(`${namespace}:pageTitle`)}
      >
        {ifRender(
          isExecOrCorpUser,
          <OverlayInfobar
            className={classes.overlayInfoBar}
            message={t('ReportApp-UnbilledReport:execCorpNote')}
          />
        )}
      </Header>
      <IonContent>
        <Refresher
          slot="fixed"
          lastUpdatedAt={dataUpdatedAt}
          onRefresh={refetch}
          disabled={isLoading}
          testid="unbilled"
          hidden
        />
        <Header
          pageTitle={t(`${namespace}:pageTitle`)}
          testid="page-content-header"
          collapse="condense"
          customTitle={
            <div
              className={classes.customHeader}
              data-testid="content-custom-title"
            >
              {reportHeader}
            </div>
          }
          className={classes.header}
        />
        <div ref={headerRef} className={classes.stickyHeader}>
          {ifRender(
            showGroupBy,
            <GroupBy
              groupByOptions={groupByOptions}
              selectedGroupBy={selectedGroupBy}
              onGroupBy={(value) => {
                setGroupByData((prev) => ({
                  ...prev,
                  [userRole]: find(
                    groupByOptions,
                    (option) => option.key === value
                  ),
                }));
              }}
              testid="unbilled-groupby"
            />
          )}
        </div>

        {ifRender(
          and(!error, !isTeam),
          <Table
            className={classes.drilldownTable}
            thClassName={classes.tableTH}
            tdClassName={classes.tableTD}
            theadStyle={{ top: headerTop }}
            columns={tableColumns}
            data={tableData}
            totals={totalsRow}
            onSortBy={onSortBy}
            sortField={sortField}
            sortDir={sortDir}
          />
        )}

        <Loader
          className={classes.loader}
          text={t('reports:loadingReports')}
          isOpen={isLoading}
          testid="loader"
        />
        {errorContent}
        {ifRender(
          !noMoreData,
          <InfiniteScroll onLoadMore={fetchNextPage} testid="infinite-scroll" />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Unbilled;
