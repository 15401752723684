import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { map, split, toString } from 'lodash';
import type { UnbilledReportRow } from 'ReportsApp/api/useGetUnbilledReport';
import { useGetTableColumns } from 'ReportsApp/hooks/useGetTableColumns';
import type { BaseReportURLParams, ReportField } from 'ReportsApp/models';
import { goToUnbilledReport } from 'ReportsApp/navigation/navigationHelpers';
import type { FilterOption } from 'components/Filter/Filter';

interface UseGetUnbilledTableDataProps {
  drilldownData: UnbilledReportRow[];
  summaryData: UnbilledReportRow;
  selectedGroupBy: FilterOption;
  reportFields: ReportField[];
}

// eslint-disable-next-line import/prefer-default-export
export const useGetUnbilledTableData = ({
  drilldownData,
  summaryData,
  selectedGroupBy,
  reportFields,
}: UseGetUnbilledTableDataProps) => {
  const { orgType = '', rowId: routeRowId = '' } =
    useParams<BaseReportURLParams>();
  const [rowId] = split(routeRowId, '-');
  const groupByDataKey = selectedGroupBy.key;
  const namespace = 'ReportApp-UnbilledReport:columns';

  const getTableData = (row: UnbilledReportRow, index: number) => {
    let rowName = toString(`${row.corp}: ${row.name}`);
    let href: string | undefined = goToUnbilledReport({
      orgType: groupByDataKey,
      miLoc: row.corp,
      rowId: routeRowId,
    });

    switch (groupByDataKey) {
      case 'GRP':
        rowName = toString(`${row.group}: ${row.name}`);
        href = goToUnbilledReport({
          orgType: groupByDataKey,
          miLoc: row.group,
          rowId: routeRowId,
        });
        break;
      case 'DIV':
        rowName = toString(`${row.division}: ${row.name}`);
        href = goToUnbilledReport({
          orgType: groupByDataKey,
          miLoc: row.division,
          rowId: routeRowId,
        });
        break;
      case 'BRCH':
        rowName = toString(`${row.branch}: ${row.name}`);
        href = goToUnbilledReport({
          orgType: groupByDataKey,
          miLoc: row.branch,
          rowId: routeRowId,
        });
        break;
      default:
    }

    return {
      data: {
        ...row,
        title: rowName,
        href,
        hideArrow: orgType === 'BRCH',
        testid: `unbilled-${rowName}-${index}`,
      },
    };
  };

  const tableData = useMemo(
    () => map(drilldownData, getTableData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drilldownData, groupByDataKey, routeRowId, rowId, orgType]
  );

  const { tableColumns, totalsRow } = useGetTableColumns({
    groupByDataKey,
    reportFields,
    selectedGroupBy,
    namespace,
    summaryData,
  });

  return { tableData, tableColumns, totalsRow };
};
