import { map, toString } from 'lodash';
import { SortFieldEnum } from 'models/Sort';
import type { FilterOption } from 'components/Filter/Filter';
import { WebSalesDrillDownFields } from './WebSalesTableDrillDown/WebSalesTableDrillDown';

export const nameSortOptions: FilterOption[] = [
  {
    key: SortFieldEnum.name,
    name: 'common:name',
    type: 'string',
  },
];

const baseSortOptions: FilterOption[] = [
  ...nameSortOptions,
  {
    key: SortFieldEnum.sales,
    name: 'sort:salesDollar',
    type: 'number',
  },
  {
    key: SortFieldEnum.salesChange,
    name: 'sort:salesDollarYOY',
    type: 'number',
  },
  {
    key: SortFieldEnum.gpAmount,
    name: 'sort:gpDollar',
    type: 'number',
  },
  {
    key: SortFieldEnum.gpChange,
    name: 'sort:gpDollarYOY',
    type: 'number',
  },
  {
    key: SortFieldEnum.gpPercentAmount,
    name: 'sort:gpPercent',
    type: 'number',
  },
];

export const locationSortOptions: FilterOption[] = [
  {
    key: SortFieldEnum.miLoc,
    name: 'sort:location',
    type: 'string',
  },
];

const avgDaily: FilterOption[] = [
  {
    key: SortFieldEnum.avgDaily,
    name: 'sort:avgDailyDollar',
    type: 'number',
  },
];

const orders: FilterOption[] = [
  {
    key: SortFieldEnum.transactions,
    name: 'sort:ordersCount',
    type: 'number',
  },
];

const unbilledSortOptions: FilterOption[] = [
  {
    key: SortFieldEnum.unbilled,
    name: 'sort:unbilledDollar',
    type: 'number',
  },
];

const dailyWithLocation = [
  ...locationSortOptions,
  ...baseSortOptions,
  ...orders,
];

const dailyWithoutLocation = [...baseSortOptions, ...orders];

const dailyForTodayWithLocation = [
  ...locationSortOptions,
  ...baseSortOptions,
  ...orders,
  ...unbilledSortOptions,
];

const dailyForTodayWithoutLocation = [
  ...baseSortOptions,
  ...orders,
  ...unbilledSortOptions,
];

const withLocation = [
  ...locationSortOptions,
  ...baseSortOptions,
  ...avgDaily,
  ...orders,
];

const withoutLocation = [...baseSortOptions, ...avgDaily, ...orders];

const getSortOptions = (
  isDailySelected: boolean,
  isTodaySelected: boolean,
  showLocationSortOptions: boolean,
  isTableView: boolean
): FilterOption[] => {
  // TODO: is this needed? columns sort the data in table
  if (isTableView) {
    return [
      ...(showLocationSortOptions ? locationSortOptions : nameSortOptions),
      ...map(WebSalesDrillDownFields, (field) => ({
        key: field.id,
        name: field.key,
        type: 'number',
      })),
    ];
  }

  // isDailySelected_isTodaySelected_showLocationSortOptions
  const sortOptionsMap = new Map([
    ['true_false_true', dailyWithLocation],
    ['true_false_false', dailyWithoutLocation],
    ['true_true_true', dailyForTodayWithLocation],
    ['true_true_false', dailyForTodayWithoutLocation],
    ['false_false_true', withLocation],
    ['false_false_false', withoutLocation],
  ]);

  return (
    sortOptionsMap.get(
      `${toString(isDailySelected)}_${toString(isTodaySelected)}_${toString(
        showLocationSortOptions
      )}`
    ) || baseSortOptions
  );
};

export default getSortOptions;
