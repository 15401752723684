import React, { useEffect } from 'react';
import type { PluginListenerHandle } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { IonRouterOutlet, isPlatform } from '@ionic/react';
import { and } from 'common/utils/logicHelpers';
import {
  loginURL,
  miproServicesURL,
  miproURL,
  NotAvailableURL,
  tabsURL,
  territoriesURL,
  inventoryURL,
} from 'navigation';
import { FeatureToggleType } from 'hooks/useFeatureToggles';
import { AppModeEnum } from 'store/user';
import PrivateRoute from 'navigation/PrivateRoute/PrivateRoute';
import TabNavigator from 'navigation/TabNavigator/TabNavigator';
import {
  getRoutes,
  inventoryRouter,
} from 'navigation/TabNavigator/TabRouterConfig';
import Bulletins from 'pages/Bulletins/Bulletins';
import Login from 'pages/Login/Login';
import Territories from 'pages/Menu/Territories/Territories';
import ModeRedirect from 'pages/ModeRedirect/ModeRedirect';
import HomeMenu from 'components/HomeMenu/HomeMenu';
import NotAvailable from 'components/NotAvailable/NotAvailable';
import Blank from './BlankPage';

const getAppRoutes = (): JSX.Element[] => {
  if (process.env.REACT_APP_DEV_TOOLS === 'true') {
    return [
      <PrivateRoute
        key="mipro-app"
        exact
        path={miproURL()}
        render={() => <ModeRedirect appMode={AppModeEnum.mipro} />}
        routeName="Mi Pro App"
      />,
      <PrivateRoute
        key="services-app"
        exact
        path={miproServicesURL()}
        render={() => <ModeRedirect appMode={AppModeEnum.miproservices} />}
        routeName="Mi Pro Services App"
      />,
    ];
  }
  return [];
};

const MainNavigator = (): JSX.Element => {
  useEffect(() => {
    let showKeyboardListener: PluginListenerHandle | undefined;
    let hideKeyboardListener: PluginListenerHandle | undefined;
    const doKeyboadListener = async () => {
      const appElement = document.querySelector('ion-app') as HTMLElement;
      if (and(Capacitor.isPluginAvailable('Keyboard'), isPlatform('ios'))) {
        showKeyboardListener = await Keyboard.addListener(
          'keyboardWillShow',
          ({ keyboardHeight }) => {
            appElement.style.height = `${
              window.outerHeight - keyboardHeight
            }px`;
          }
        );
        hideKeyboardListener = await Keyboard.addListener(
          'keyboardWillHide',
          () => {
            appElement.style.height = '';
          }
        );
      }
    };
    void doKeyboadListener();

    return () => {
      const removeKeyboardListener = async () => {
        await showKeyboardListener?.remove();
        await hideKeyboardListener?.remove();
      };
      void removeKeyboardListener();
    };
  }, []);

  return (
    <>
      <HomeMenu />
      <IonRouterOutlet id="main">
        <PrivateRoute
          exact
          path="/"
          render={() => <Blank />}
          routeName="Splash Screen"
        />
        <PrivateRoute
          exact
          path={loginURL()}
          render={() => <Login />}
          routeName="Login"
        />
        <PrivateRoute
          path={tabsURL()}
          render={() => <TabNavigator />}
          track={false}
          routeName="Tabs"
        />
        <PrivateRoute
          path={territoriesURL()}
          render={() => <Territories />}
          routeName="Territories"
        />
        {getRoutes(inventoryURL(), inventoryRouter, undefined, [
          FeatureToggleType.viewVmiFeatureToggle,
          FeatureToggleType.viewInventoryFeatureToggle,
        ])}
        {getAppRoutes()}
        <PrivateRoute
          path={NotAvailableURL()}
          render={() => <NotAvailable />}
          routeName="Not Available"
        />
        <PrivateRoute component={NotAvailable} routeName="Not Available" />
      </IonRouterOutlet>
      {process.env.REACT_APP_FEATURE_BULLETINS === 'true' && <Bulletins />}
    </>
  );
};

export default MainNavigator;
