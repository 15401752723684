import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Column, SortingRule } from 'react-table';
import classNames from 'classnames';
import type { Dictionary } from 'lodash';
import {
  filter,
  includes,
  toString,
  kebabCase,
  toNumber,
  map,
  size,
  find,
  head,
} from 'lodash';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import type HelpButton from 'common/components/HelpButton/HelpButton';
import { emptyString } from 'common/utils/valueFormatter';
import { fromUnixTime, isToday } from 'date-fns';
import { groupByWithNameColumn } from 'ReportsApp/util/groupOptions/groupOptions';
import type {
  ReportsContextProps,
  ReportDrillDownItem,
  SummaryItemOutput,
  TableFields,
} from 'models/Reports';
import { findIcon } from 'utils/icons';
import ReportHeader from 'pages/Reports/components/ReportHeader/ReportHeader';
import useDrillDownData from 'pages/Reports/useDrillDownData';
import Button from 'components/Button/Button';
import type { FilterOption } from 'components/Filter/Filter';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import Table from 'components/Table/Table';
import Text from 'components/Text/Text';
import type { GetDrillDownLinkReponse } from './DrillDown';
import classes from './DrillDown.module.scss';

type DrillDownDataItem = GetDrillDownLinkReponse & {
  items: SummaryItemOutput[];
  testid: string;
};

interface TableDrillDownProps {
  miLoc: string;
  sharedBusinessDayLabel?: React.ReactNode;
  scrollContent?: React.ReactNode;
  drilldownData: ReportDrillDownItem[];
  totalsData?: SummaryItemOutput[];
  hasDrilldownError?: boolean;
  groupByOptions: FilterOption[];
  selectedGroupBy: FilterOption;
  reportHeaderClass?: string;
  headerText: string;
  headerImage?: string;
  title: string;
  fields: TableFields[];
  customHeader?: React.ReactNode;
  thClassName?: string;
  tdClassName?: string;
  setGroupBy: (v: string) => void;
  getDrilldownLink: (item: ReportDrillDownItem) => GetDrillDownLinkReponse;
  onSortBy?: (sortBy: SortingRule<Dictionary<unknown>>[]) => void;
  testid: string;
  helpButton?: React.ComponentProps<typeof HelpButton>;
}

const TableDrillDown = ({
  className,
  reportHeaderClass,
  miLoc,
  sharedBusinessDayLabel,
  scrollContent,
  drilldownData,
  totalsData,
  hasDrilldownError,
  groupByOptions,
  selectedGroupBy,
  headerText,
  headerImage,
  helpButton,
  title,
  fields,
  customHeader,
  thClassName,
  tdClassName,
  setGroupBy,
  getDrilldownLink,
  onSortBy,
  sortField,
  sortDir,
  testid,
  busPeriod,
}: TableDrillDownProps &
  IonicReactProps &
  Pick<
    ReportsContextProps,
    'busPeriod' | 'sortField' | 'sortDir'
  >): JSX.Element => {
  const { translate, showAmount } = useDrillDownData();
  const today = isToday(fromUnixTime(busPeriod));
  const filteredFields = useMemo(
    () =>
      filter(
        fields,
        (column) =>
          !includes(column.disabledLevels, selectedGroupBy.key) &&
          (today || (!today && !column.disabledNonToday))
      ),
    [fields, selectedGroupBy.key, today]
  );

  const tableColumns: Column<Dictionary<unknown>>[] = [
    {
      Header: selectedGroupBy.name,
      id: includes(groupByWithNameColumn, selectedGroupBy.key)
        ? 'name'
        : 'miLoc',
      accessor: 'data',
      sortType: 'basic',
      Cell: ({ value }: Dictionary<unknown>) => {
        const {
          title: itemTitle,
          href,
          onClick,
          testid: itemTestId,
          secondaryText,
          hideArrow,
        } = value as DrillDownDataItem;
        return (
          <IonRow className={classes.rowLink}>
            <Button
              iconClassName={classes.icon}
              text={itemTitle}
              href={href}
              rightIcon={
                selectedGroupBy?.key === 'CUST' || hideArrow
                  ? undefined
                  : findIcon('chevron-right', 'far')
              }
              onClick={onClick}
              testid={toString(itemTestId)}
              variant={
                selectedGroupBy?.key === 'CUST' && href ? 'link' : 'clear'
              }
            />
            {secondaryText && (
              <IonRow className={classes.rowLink}>
                <Text
                  className={classes.secondaryText}
                  text={secondaryText}
                  variant="mipro-h6-headline"
                />
              </IonRow>
            )}
          </IonRow>
        );
      },
    },
    ...map(filteredFields, ({ key, id }) => ({
      Header: translate(key),
      id,
      accessor: 'data',
      sortType: 'basic',
      Cell: ({ value }: Dictionary<unknown>) => {
        const { items, testid: itemTestId } = value as DrillDownDataItem;
        const { amount, currencyType, text, hideValue } =
          find(items, { Name: key }) || {};

        return showAmount(
          key,
          toNumber(amount) === 0 && hideValue ? undefined : toNumber(amount),
          itemTestId,
          currencyType,
          text
        );
      },
    })),
  ];

  const tableData = map(drilldownData, (drilldownItem, index) => {
    const { Name, items, secondaryText } = drilldownItem;
    const {
      title: itemTitle,
      href,
      onClick,
      hideArrow,
    } = getDrilldownLink(drilldownItem);

    return {
      data: {
        title: itemTitle,
        href,
        onClick,
        items,
        secondaryText,
        hideArrow,
        testid: `sales-${kebabCase(Name)}-${index}`,
      },
    };
  });

  const { t } = useTranslation();

  const headerRef = useRef<HTMLDivElement>(null);

  const [headerTop, setHeaderTop] = useState(0);
  const onResize = () => setHeaderTop(headerRef.current?.clientHeight || 0);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    onResize();
  }, [title, tableData]);

  const showHeaderTabs =
    // head(groupByOptions)?.key !== 'PRD_GRP_01' &&
    // head(groupByOptions)?.key !== 'PRD_GRP_02' &&
    size(groupByOptions) > 1;

  return (
    <div
      className={classNames(className, classes.pick12Cont)}
      data-testid={`DrillDown-${testid}`}
    >
      <div ref={headerRef} className={classes.stickyHeader}>
        <ReportHeader
          mainHeaderClass={reportHeaderClass}
          miLoc={miLoc}
          headerText={headerText}
          title={title}
          customHeader={customHeader}
          withImage={headerImage}
          helpButton={helpButton}
        />
        {showHeaderTabs && (
          <IonRow className={classes.tabsRow}>
            <SegmentTabs
              className={classes.roleTabs}
              selectedClassName={classes.selectedTab}
              value={selectedGroupBy.key}
              setValue={setGroupBy}
              options={map(groupByOptions, (g) => ({
                key: g.key,
                text: g.name,
              }))}
              testid="role-tabs"
              variant="roundedTabs"
              isScrollable
            />
          </IonRow>
        )}
      </div>
      {sharedBusinessDayLabel}
      {!hasDrilldownError && (
        <Table
          className={classes.drilldownTable}
          theadStyle={{ top: headerTop }}
          thClassName={classNames(classes.tableTH, thClassName)}
          tdClassName={classNames(classes.tableTD, tdClassName)}
          columns={tableColumns}
          data={tableData}
          totals={[
            toString(t('common:totals')),
            ...map(filteredFields, ({ key, id }) => {
              const totalsRow = find(totalsData, { Name: key });
              if (totalsRow?.hideValue) {
                return emptyString;
              }
              return showAmount(
                key,
                toNumber(totalsRow?.amount),
                `totals-${id}`,
                find(head(drilldownData)?.items, { Name: key })?.currencyType
              );
            }),
          ]}
          onSortBy={onSortBy}
          sortField={sortField}
          sortDir={sortDir}
        />
      )}
      {scrollContent}
    </div>
  );
};

export default TableDrillDown;
