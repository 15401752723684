import { includes } from 'lodash';
import type { SalesReportRow } from 'ReportsApp/api/useGetSalesDashboard';
import { goToSalesPerformanceReport } from 'ReportsApp/navigation/navigationHelpers';
import { goToCustomerSnapshot } from 'SearchApp/navigation/navigationHelpers';

const getSalesTableRowMetaData = ({
  groupByDataKey,
  row,
  rowName,
  routeRowId,
  rowId,
  tab,
  orgType,
  isCamUser,
}: {
  row: SalesReportRow;
  rowName: string;
  groupByDataKey: string;
  routeRowId?: string;
  rowId: string;
  tab: string;
  orgType: string;
  isCamUser?: boolean;
}): { title: string; href?: string; customerLink?: boolean } => {
  let href: string | undefined = goToSalesPerformanceReport({
    orgType: groupByDataKey,
    miLoc: row.miLoc,
    rowId: routeRowId,
  });
  let title = rowName;
  const customerHref = goToCustomerSnapshot({
    miLoc: row.miLoc,
    customerId: row.customerNo,
  });
  switch (groupByDataKey) {
    case 'TEAM':
      title = row.teamName;
      href = goToSalesPerformanceReport({
        orgType: groupByDataKey,
        miLoc: row.teamId,
      });
      break;
    case 'REP':
      title = row.repName;
      href = goToSalesPerformanceReport({
        orgType: groupByDataKey,
        miLoc: row.miLoc,
        rowId: row.repNo,
      });
      break;
    case 'NATLACCT':
      title = `${row.miLoc}: ${row.customerName}`;
      href = goToSalesPerformanceReport({
        orgType: groupByDataKey,
        miLoc: row.miLoc,
        rowId: `${row.nationalAcctNo}-${row.miLoc}`,
      });
      break;
    case 'CUST':
      title =
        !includes(
          ['TEAM', 'BRCH', 'REP', 'CUST', 'PRD_GRP_01', 'PRD_GRP_02'],
          orgType
        ) && rowId
          ? `${row.miLoc}: ${row.customerName}`
          : row.customerName;
      href = isCamUser
        ? customerHref
        : goToSalesPerformanceReport({
            orgType: groupByDataKey,
            miLoc: row.miLoc,
            rowId: row.customerNo,
          });
      break;
    case 'PRD_GRP_01':
      title = row.pgcName;
      href = goToSalesPerformanceReport({
        baseUrl: tab === 'search' ? `${customerHref}/reports` : '',
        orgType: groupByDataKey,
        miLoc: row.miLoc,
        rowId,
        pgc1: row.pgc,
      });
      break;
    case 'PRD_GRP_02':
      title = row.pgcName;
      href = undefined;
      break;
    default:
  }

  const customerLink = isCamUser && groupByDataKey === 'CUST';
  return { title, href, customerLink };
};

export default getSalesTableRowMetaData;
