import React from 'react';
import { useTranslation } from 'react-i18next';
import { kebabCase, toString, isNil, toNumber } from 'lodash';
import { emptyString } from 'common/utils/valueFormatter';
import { DrillDownFieldsEnum } from 'models/Reports';
import { formatDate } from 'utils/date';
import ChangeFormat from 'components/ChangeFormat/ChangeFormat';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import NumberFormat from 'components/NumberFormat/NumberFormat';
import Text from 'components/Text/Text';
import classes from './ReportCard/ReportCard.module.scss';

interface UseDrillDownDataReponse {
  translate: (name: string) => string;
  showAmount: (
    name: string,
    amount?: number,
    testid?: string,
    currencyType?: string,
    text?: string
  ) => JSX.Element;
}

const useDrillDownData = (): UseDrillDownDataReponse => {
  const { t } = useTranslation();

  const translate = (name: string): string => {
    switch (name) {
      case DrillDownFieldsEnum.Sales:
        return t('sales');
      case DrillDownFieldsEnum['Avg Daily']:
        return t('averageDaily');
      case DrillDownFieldsEnum.Unbilled:
        return t('unbilled');
      case DrillDownFieldsEnum.GP:
        return t('gp');
      case DrillDownFieldsEnum['GP %']:
        return t('gpPercentage');
      case DrillDownFieldsEnum['Pick12 Count']:
        return t('pick12Count');
      case DrillDownFieldsEnum['Total Pick12 Count']:
        return t('totalPick12Count');
      case DrillDownFieldsEnum['Pick12 %']:
        return t('pick12Percentage');
      case DrillDownFieldsEnum.Orders:
        return t('orders');
      case DrillDownFieldsEnum['Avg Sales Acct']:
        return t('avgSalesPerAcct');
      case DrillDownFieldsEnum['Last Year Sales']:
        return t('previousSales');
      case DrillDownFieldsEnum['Yoy Growth']:
        return t('salesChange');
      case DrillDownFieldsEnum['% of Total Sales']:
        return t('reports:webOverallSales');
      case DrillDownFieldsEnum['% Active WB']:
        return t('reports:activeWebRegistered');
      case DrillDownFieldsEnum.Overage:
        return t('reports:overage');
      case DrillDownFieldsEnum.Commit:
        return t('reports:commitCurrentSales');
      case DrillDownFieldsEnum['Commit %']:
        return t('reports:commitPercentage');
      case DrillDownFieldsEnum.Projected:
        return t('reports:projectedSales');
      case DrillDownFieldsEnum.Approved:
        return t('reports:approvedSales');
      case DrillDownFieldsEnum.Pending:
        return t('reports:pendingSales');
      case DrillDownFieldsEnum['Guaranteed Paid']:
        return t('reports:guaranteed');
      default:
        return name;
    }
  };

  const showAmount = (
    name: string,
    amount?: number,
    testid?: string,
    currencyType?: string,
    text?: string
  ) => {
    switch (name) {
      case DrillDownFieldsEnum.Sales:
      case DrillDownFieldsEnum['Avg Daily']:
      case DrillDownFieldsEnum.Unbilled:
      case DrillDownFieldsEnum.GP:
      case DrillDownFieldsEnum['Avg Sales Acct']:
      case DrillDownFieldsEnum['Last Year Sales']:
      case DrillDownFieldsEnum.Approved:
      case DrillDownFieldsEnum.Projected:
      case DrillDownFieldsEnum.Pending:
      case DrillDownFieldsEnum.Commit:
        return (
          <CurrencyFormat
            value={toNumber(amount)}
            variant="content-small"
            testid={`reports-summary-cards-amount-value-${kebabCase(name)}`}
            currencyType={currencyType}
          />
        );
      case DrillDownFieldsEnum['Effective Date']:
      case DrillDownFieldsEnum['Expiration Date']:
        return (
          <Text
            variant="content-small"
            text={isNil(text) ? emptyString : formatDate(text)}
          />
        );
      case DrillDownFieldsEnum['Guaranteed Paid']:
        return (
          <Text
            variant="content-small"
            text={toString(isNil(text) ? emptyString : text)}
          />
        );
      case DrillDownFieldsEnum['GP %']:
      case DrillDownFieldsEnum['Pick12 %']:
      case DrillDownFieldsEnum['Yoy Growth']:
      case DrillDownFieldsEnum['% of Total Sales']:
      case DrillDownFieldsEnum['% Active WB']:
      case DrillDownFieldsEnum['Commit %']:
        return (
          <NumberFormat
            value={toNumber(amount)}
            scale={1}
            suffix="%"
            className={classes.changeValue}
            variant="content-small"
            testid={`reports-summary-cards-amount-value-${toString(testid)}`}
          />
        );
      case DrillDownFieldsEnum.Overage:
        return (
          <ChangeFormat
            value={toNumber(amount)}
            testid="cost-savings-shortfall"
            typeVariant="currency"
            positiveClassName={classes.positive}
            negativeClassName={classes.negative}
            currencyType={currencyType}
            className={classes.changeFormat}
          />
        );
      case DrillDownFieldsEnum.Orders:
      case DrillDownFieldsEnum['Pick12 Count']:
      case DrillDownFieldsEnum['Total Pick12 Count']:
      default:
        return (
          <NumberFormat
            value={toNumber(amount)}
            className={classes.changeValue}
            variant="content-small"
            testid={`reports-summary-cards-amount-value-${kebabCase(
              name
            )}-${toString(testid)}`}
          />
        );
    }
  };

  return { translate, showAmount };
};

export default useDrillDownData;
