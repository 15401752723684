import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import AppIcons from 'constants/appIcons';
import classNames from 'classnames';
import { toString } from 'lodash';
import { IonContent, IonPage, IonRow, createAnimation } from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import AllActivitiesTab from 'ActivitiesApp/components/ActivitiesTab/ActivitiesTab';
import MyVisitsTab from 'ActivitiesApp/components/MyVisitsTab/MyVisitsTab';
import Header from 'common/components/Header/Header';
import { and, ifRender } from 'common/utils/logicHelpers';
import { namespaces } from 'i18n/i18n.constants';
import { activitiesURL, activityDetailURL } from 'navigation';
import { useTabReset } from 'providers/TabResetProvider';
import { useToasts } from 'providers/ToastProvider';
import { findActivitiesQueryKey } from 'api/activities/useFindActivities';
import { findActivityFiltersQueryKey } from 'api/activities/useFindActivityFilters';
import useMarkAllActivitiesAsRead from 'api/activities/useMarkAllActivitiesAsRead';
import useUpdateActivities from 'api/activities/useUpdateActivities';
import useGetCustomer from 'api/customer/useGetCustomer';
import { onErrorUpdate, onMutateUpdate, onSuccessMutation } from 'api/helpers';
import type { UnreadCount } from 'api/user/useGetUnreadNotificationsCount';
import { unreadQueryKey } from 'api/user/useGetUnreadNotificationsCount';
import useHapticFeedback from 'hooks/useHapticFeedback';
import type { ActionCardActivity } from 'models/ActivityModels';
import type { SnapshotsURLParams } from 'models/Search';
import { clearDeepLink } from 'store/user';
import { ACTIVITY } from 'utils/constants';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import Button from 'components/Button/Button';
import Refresher from 'components/Refresher/Refresher';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import Text from 'components/Text/Text';
import classes from './Activities.module.scss';

export enum ActivitiesTabType {
  'all' = 'all',
  'myVisits' = 'myVisits',
}

const Activities = (): JSX.Element => {
  const dispatch = useDispatch();
  const { miLoc, id } = useParams<SnapshotsURLParams>();
  const { t } = useTranslation(namespaces.activities);
  const { addToast } = useToasts();
  const queryClient = useQueryClient();

  const [multiSelectList, setMultiSelectList] = useState<number[]>([]);
  const [triggerMultiSelectAll, setTriggerMultiSelectAll] = useState<number>();
  const [multiSelectCount, setMultiSelectCount] = useState<number>(0);
  const [multiSelectEnabled, setMultiSelectEnabled] = useState<boolean>();
  const [selectAll, setSelectAll] = useState<boolean>();
  const [tabValue, setTabValue] = useState<ActivitiesTabType>(
    ActivitiesTabType.all
  );

  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id,
  });

  const { status, onMarkAllAsRead } = useMarkAllActivitiesAsRead();
  const isSubmitting = status === 'loading';
  const markAllAsRead = async () => {
    // DOC: optimistic update before api call
    const activitiesContext = await onMutateUpdate<ActionCardActivity>({
      queryClient,
      queryKey: findActivitiesQueryKey,
      updateAll: true,
      updatedItems: [{ eventRead: 'Y' } as ActionCardActivity],
      isInfiniteQuery: true,
    });
    const unreadContext = await onMutateUpdate<UnreadCount>({
      queryClient,
      queryKey: unreadQueryKey,
      updatedItems: [{ unreadCount: 0 }],
      updateAll: true,
      isSingleQuery: true,
    });

    addToast({
      text: t('activitiesReadToast'),
      button: {
        text: t('common:undo'),
        handler: () => {
          onErrorUpdate<ActionCardActivity>({
            queryClient,
            context: activitiesContext,
            isInfiniteQuery: true,
          });
          onErrorUpdate<UnreadCount>({
            queryClient,
            context: unreadContext,
            isSingleQuery: true,
          });
        },
      },
      onClose: () => onMarkAllAsRead({ activitiesContext, unreadContext }),
      testid: 'mark-all-as-read-success-toast',
    });
  };

  useEffect(() => {
    dispatch(clearDeepLink());
  }, [dispatch]);

  const { triggerResetTabMap } = useTabReset();
  const triggerResetActivityTab = triggerResetTabMap?.[ACTIVITY];
  const { hapticsImpactLight } = useHapticFeedback();

  const headerRef = useRef<HTMLIonHeaderElement>(null);
  const ANIMATION_DURATION = 200;

  const playHeaderAnimation = (opacity: number) => {
    if (headerRef.current) {
      const el = headerRef.current;
      void createAnimation()
        .addElement(el)
        .duration(ANIMATION_DURATION)
        .keyframes([{ offset: 1, opacity }])
        .play();
    }
  };

  const toggleSelection = (itemId?: number, haptic = false) => {
    if (!itemId) {
      return;
    }
    if (haptic) {
      void hapticsImpactLight();
    }
    setMultiSelectEnabled(true);
    setMultiSelectList((prev) => {
      const filteredList = prev.filter((value) => value !== itemId);
      if (prev.length === filteredList.length) {
        filteredList.push(itemId);
      }
      return filteredList;
    });
  };

  const history = useHistory();

  const onDetailClick = useCallback(
    (data: { activity: ActionCardActivity }) => {
      if (!multiSelectEnabled) {
        const { userId, historyId, custMiLoc, custNo, eventTagName } =
          data.activity;
        history.push(
          concatRoutes(
            activitiesURL(),
            activityDetailURL(
              custMiLoc,
              custNo,
              userId,
              toString(historyId),
              eventTagName
            )
          )
        );
      }
    },
    [history, multiSelectEnabled]
  );

  useEffect(() => {
    const count = multiSelectList.length;
    setMultiSelectCount(() => count);
    if (count === 1) {
      playHeaderAnimation(0.7);
    }
  }, [multiSelectEnabled, multiSelectList]);

  const { status: updateStatus, onUpdateActivities } = useUpdateActivities();

  useEffect(() => {}, [updateStatus]);

  const onMultiSelectDelete = () => {
    playHeaderAnimation(1);
    const historyIds = [...multiSelectList];
    setMultiSelectEnabled(false);
    setMultiSelectList([]);
    onUpdateActivities({
      done: true,
      historyIds,
    });
    addToast({
      text:
        multiSelectCount > 1
          ? t('multiDeleteToast', { count: multiSelectCount })
          : t('singleDeleteToast'),
      button: {
        role: 'undo',
        text: t('common:undo'),
        handler: () => onUpdateActivities({ done: false, historyIds }),
      },
      testid: 'update-activities-toast',
    });
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      setMultiSelectList([]);
    } else {
      setTriggerMultiSelectAll(Date.now());
    }
    setSelectAll(!selectAll);
  };

  const isSnapshotActivities = and(miLoc, id);

  const options = [
    {
      key: ActivitiesTabType.all,
      text: t('allActivities'),
    },
    {
      key: ActivitiesTabType.myVisits,
      text: t('myVisits'),
    },
  ];

  return (
    <IonPage className={classes.activityPage} data-testid="home-page">
      <Header
        className={classNames(classes.header, classes.contentHeader, {
          [classes.multiSelectHeader]: multiSelectEnabled,
        })}
        subTitle={isSnapshotActivities ? customerData?.name : ''}
        backButton={{
          className: classes.headerEyebrowBack,
          onClick:
            !isSnapshotActivities && tabValue === ActivitiesTabType.myVisits
              ? () => setTabValue(ActivitiesTabType.all)
              : undefined,
        }}
        hideBackButton={
          !isSnapshotActivities && tabValue === ActivitiesTabType.all
        }
        title={
          tabValue === ActivitiesTabType.all
            ? t('common:activities')
            : t('myActivities')
        }
        testid="activities"
        showLocationText={!isSnapshotActivities}
        hideLocationSelector={multiSelectEnabled}
        hideMenuButton={multiSelectEnabled}
        hideCartButton={multiSelectEnabled}
        hideEndSlotComponent={!multiSelectEnabled}
        endSlotComponent={ifRender(
          multiSelectEnabled,
          <Button
            slot="end"
            className={classes.cancelButton}
            variant="filter"
            testid="header-cancel-button"
            onClick={() => {
              setMultiSelectList([]);
              setMultiSelectEnabled(false);
              playHeaderAnimation(1);
            }}
          >
            <Text
              className={classes.text}
              variant="title-info-card-read"
              text={t('common:cancel')}
            />
          </Button>
        )}
      >
        <div
          className={classNames(classes.toolbar, classes.mainToolbar, {
            [classes.multiSelectToolbar]: multiSelectEnabled,
          })}
        >
          {multiSelectEnabled ? (
            <>
              <div slot="start">
                <div className={classes.deleteCont}>
                  <Button
                    className={classes.selectAllButton}
                    variant="filter"
                    icon={findIcon('clone', selectAll ? 'fas' : 'far')}
                    iconClassName="fa-rotate-270 icon"
                    testid="header-select-all-button"
                    onClick={() => {
                      toggleSelectAll();
                    }}
                  >
                    <Text
                      className={classes.text}
                      variant="title-info-card-read"
                      text={selectAll ? t('deSelectAll') : t('selectAll')}
                    />
                  </Button>
                </div>
              </div>
              <div slot="end">
                <div className={classes.deleteCont}>
                  <Button
                    className={classes.actionButton}
                    variant="filter"
                    icon={AppIcons.doneIcon(false)}
                    onClick={onMultiSelectDelete}
                    disabled={
                      multiSelectCount === 0 || updateStatus === 'loading'
                    }
                    testid="delete-button-action"
                  >
                    <Text
                      className={classes.text}
                      variant="title-info-card-read"
                      text={t('common:delete')}
                    />
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <IonRow className={classes.tabsRow}>
              <SegmentTabs
                className={classes.activitiesTabs}
                textVariant="title-action-card"
                options={options}
                selectedClassName={classes.selectedSegmentTab}
                value={tabValue}
                setValue={(v) => setTabValue(v as ActivitiesTabType)}
                testid="activities-tabs"
              />
            </IonRow>
          )}
        </div>
      </Header>
      <IonContent>
        <Refresher
          slot="fixed"
          hidden
          onRefresh={async () => {
            await Promise.all([
              onSuccessMutation(queryClient, findActivityFiltersQueryKey),
              onSuccessMutation(queryClient, unreadQueryKey),
            ]);
          }}
          testid="activities-refresher"
          disabled={isSubmitting}
        />
        <AllActivitiesTab
          className={classNames({
            [classes.hiddenTab]: tabValue !== 'all',
          })}
          multiSelectList={multiSelectList}
          multiSelectEnabled={!!multiSelectEnabled}
          toggleSelection={toggleSelection}
          onDetailClick={onDetailClick}
          setSelectAll={setSelectAll}
          isSubmitting={isSubmitting}
          miLoc={miLoc}
          id={id}
          markAllAsRead={markAllAsRead}
          triggerResetActivityTab={triggerResetActivityTab}
          triggerMultiSelectAll={triggerMultiSelectAll}
          setMultiSelectList={setMultiSelectList}
        />
        <MyVisitsTab
          className={classNames({
            [classes.hiddenTab]: tabValue === 'all',
          })}
          onDetailClick={onDetailClick}
          miLoc={miLoc}
          id={id}
          triggerResetActivityTab={triggerResetActivityTab}
        />
      </IonContent>
    </IonPage>
  );
};

export default Activities;
