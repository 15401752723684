import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { get, toNumber, toString } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import useAPIUrl from 'api';
import useFindReports, {
  findDocumentsQueryKey,
  getDocumentQueryKey,
  reportsToSyncQueryKey,
} from 'DocumentsApp/api/useFindReports';
import useReportDB from 'DocumentsApp/database/useReportDB';
import type { Report } from 'DocumentsApp/models/Report';
import { namespaces } from 'i18n/i18n.constants';
import { useAxios } from 'providers/AxiosProvider';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doPromiseAPI,
  onErrorUpdate,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import type { CoverPage, InspectionReport } from 'models/InspectionReport';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { DateFormatEnum, formatDate } from 'utils/date';
import { findWorkOrdersQueryKey } from './useFindWorkOrders';
import type { UpdateImagesBody } from './useUpdateImages';
import useUpdateImages from './useUpdateImages';
import useDocumentHelpers, {
  transformFromOfflineReport,
  transformFromOnlineReport,
} from './utils/documentHelpers';

interface UseCreateReportProps {
  isUpdate?: boolean;
}

interface CreateReportBody extends UpdateImagesBody {
  coverPage: CoverPage;
  autoSave?: boolean;
  forceOffline?: boolean;
}

interface UseCreateReportResponse {
  status: MutationStatus;
  reportId?: number;
  onCreateReport: (body: CreateReportBody) => void;
}

const useCreateReport = ({
  isUpdate,
}: UseCreateReportProps): UseCreateReportResponse => {
  const { axios } = useAxios();
  const { createReportAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation(namespaces.reports);
  const { isOnline } = useNetworkStatus();
  const { createReports, getReportById } = useReportDB();
  const { getDocumentErrors, handleDocumentErrors } = useDocumentHelpers();
  const { userInfo } = useSelector((state: RootState) => state.user);
  const currentUserId = get(userInfo, 'userid', '');
  const currentUserName = get(userInfo, 'cn', '');

  const { doUpdateImagesOnline, doUpdateImagesOffline } = useUpdateImages();
  const { uploadReport } = useFindReports({ enabled: false });

  const doCreateReport = (vars: CreateReportBody) => {
    return doPromiseAPI<Report>(async () => {
      const report = transformFromOfflineReport(
        (await getReportById(toString(vars.coverPage.reportId))) || {}
      );
      if (
        !vars.forceOffline &&
        isOnline &&
        !report.needSync &&
        !vars.autoSave
      ) {
        const { data } = await axios.post<Report>(
          createReportAPI(),
          vars.coverPage
        );
        getDocumentErrors(data as unknown as AxiosError);
        await doUpdateImagesOnline({
          ...vars,
          report,
          reportId: toString(data.reportId),
        });
        return data;
      }
      if (
        !vars.forceOffline &&
        isOnline &&
        report.reportId &&
        report.needSync &&
        !vars.autoSave
      ) {
        await uploadReport({
          reportId: toString(report.reportId),
          shouldRefetch: false,
          shouldThrowError: true,
        });
        return { ...transformFromOnlineReport(report) };
      }
      const reportId = isUpdate
        ? toNumber(vars.coverPage.reportId)
        : -1 * Date.now();
      const updatedImages = doUpdateImagesOffline({
        ...vars,
        report,
        reportId: toString(reportId),
      });
      const reportDB: Report = {
        ...transformFromOnlineReport(report),
        ...vars.coverPage,
        reportId,
        ...updatedImages,
        creationUserId:
          toString(report.coverPage.creationUserId) || currentUserId,
        creationUserName:
          toString(report.coverPage.creationUserName) || currentUserName,
        creationTmstmp:
          toString(report.coverPage.creationTmstmp) ||
          formatDate(new Date(), DateFormatEnum.ISO),
        lastUpdUserId: currentUserId,
        lastUpdUserName: currentUserName,
        lastUpdTmstmp: formatDate(new Date(), DateFormatEnum.ISO),
        lastSyncDate: formatDate(new Date(), DateFormatEnum.ISO),
        needSync: true,
      };
      await createReports([reportDB]);
      return reportDB;
    });
  };

  const { data, status, mutate } = useMutation(doCreateReport, {
    networkMode: 'always',
    onMutate: (vars) => {
      if (isUpdate) {
        return onMutateUpdate<InspectionReport>({
          queryClient,
          queryKey: findDocumentsQueryKey,
          updatedItems: [
            {
              reportId: toNumber(vars.coverPage.reportId),
              updatedName: vars.coverPage.name,
            } as InspectionReport,
          ],
          findPredicates: [{ reportId: toNumber(vars.coverPage.reportId) }],
          isInfiniteQuery: true,
        });
      }
      return undefined;
    },
    onSuccess: (resp, vars) => {
      if (!vars.autoSave) {
        void onSuccessMutation(queryClient, reportsToSyncQueryKey);
        void onSuccessMutation(queryClient, findDocumentsQueryKey);
        void onSuccessMutation(queryClient, getDocumentQueryKey);
        void onSuccessMutation(queryClient, findWorkOrdersQueryKey);
        if (vars.forceOffline) {
          addToast({
            text: `Changes will be saved in your device.`,
            testid: 'save-offline-toast',
          });
          return;
        }
        addToast({
          type: ToastType.info,
          text: isUpdate
            ? 'Save cover page operation successful.'
            : t('reportCreateSuccess'),
          testid: 'save-report-success-toast',
        });
      }
    },
    onError: (e, vars, context) => {
      handleDocumentErrors({ error: e as AxiosError });
      if (!vars.forceOffline) {
        mutate({ ...vars, forceOffline: true });
      } else {
        onErrorUpdate<InspectionReport>({
          queryClient,
          context,
          isInfiniteQuery: true,
        });
      }
    },
  });

  return {
    status,
    reportId: data?.reportId,
    onCreateReport: (body) => mutate(body),
  };
};

export default useCreateReport;
