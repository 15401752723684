/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { debounce, isNil, split, toNumber, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IonItemSlidingCustomEvent } from '@ionic/core';
import {
  IonCol,
  IonGrid,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonRow,
} from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { and, choose, ifRender, or } from 'common/utils/logicHelpers';
import { useFormik } from 'formik';
import useUpdateItemProfile from 'InventoryApp/api/useUpdateItemProfile';
import type {
  CountGroup,
  CountGroupItem,
} from 'InventoryApp/models/InventoryPlanGroup';
import {
  isStartVMI as getIsStartVMI,
  isPiBelting as getIsPiBelting,
} from 'InventoryApp/util/inventoryUtil';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Modal from 'components/Modal/Modal';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import Text from 'components/Text/Text';
import type { MinMaxFormData, OrderFormData } from './FormHelpers';
import {
  DataRow,
  LENGTH_REGEXP,
  MAX_LENGTH,
  MAX_QUANTITY,
  MAX_WIDTH,
  QUANTITY_REGEXP,
  WIDTH_REGEXP,
  useFormHelpers,
} from './FormHelpers';
import classes from './OrderModal.module.scss';

interface OrderModalProps {
  item?: CountGroupItem;
  calculateOrderQuantity: (
    quantity: number,
    minValue?: number,
    maxValue?: number
  ) => number;
  handleOrderQuantity: (
    itemId: string,
    quantity: number,
    width?: number,
    length?: number,
    minValue?: number,
    maxValue?: number
  ) => void;
  setTriggerScan: (v: number) => void;
  setTriggerNextItem: (v: number) => void;
  setTriggerSwipeRight?: (v: number) => void;
  setTriggerSwipeLeft?: (v: number) => void;
  triggerBleScan?: string;
  setTriggerSaveAfterScan?: (v: string) => void;
  testid: string;
  isOrderModel: boolean;
  itemCount: number;
  countedItemCount: number;
  isFiltered: boolean;
  countGroup?: CountGroup;
  hideScanNext?: boolean;
}

const OrderModal = ({
  item,
  isOpen,
  testid,
  isOrderModel = false,
  calculateOrderQuantity,
  handleOrderQuantity,
  setTriggerScan,
  setTriggerNextItem,
  setIsOpen,
  setTriggerSwipeLeft,
  setTriggerSwipeRight,
  triggerBleScan,
  setTriggerSaveAfterScan,
  itemCount,
  countedItemCount,
  isFiltered,
  countGroup,
  hideScanNext,
}: OrderModalProps &
  React.ComponentProps<typeof Modal> &
  IonicReactProps): JSX.Element => {
  const { t } = useTranslation();
  const isStartVMI = getIsStartVMI(countGroup);
  const isPiBelting = getIsPiBelting(countGroup);
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);
  const [discardMinMaxIsOpen, setDiscardMinMaxIsOpen] = useState(false);
  const [triggerInputSelect, setTriggerInputSelect] = useState(0);
  const [isMinMaxEdit, setIsMinMaxEdit] = useState(false);
  const [minValue, setMinValue] = useState<string>();
  const [maxValue, setMaxValue] = useState<string>();

  const { onUpdateItemProfile, status: updateMinMaxStatus } =
    useUpdateItemProfile();

  const { orderSchema, piBeltingSchema, minMaxSchema } = useFormHelpers();

  const {
    values,
    errors,
    isValid,
    isSubmitting,
    dirty,
    handleSubmit,
    setFieldValue,
    setSubmitting,
    resetForm,
  } = useFormik<OrderFormData>({
    initialValues: {},
    onSubmit: (formValues) => {
      handleOrderQuantity(
        toString(item?.uniqueId),
        toNumber(formValues.quantity),
        toNumber(formValues?.width),
        toNumber(formValues?.length),
        toNumber(minValue),
        toNumber(maxValue)
      );
      setSubmitting(false);
    },
    validationSchema: choose(isPiBelting, piBeltingSchema, orderSchema),
    validateOnChange: false,
    validateOnBlur: true,
  });

  const {
    values: valuesMinMax,
    errors: errorsMinMax,
    isValid: isValidMinMax,
    isSubmitting: isSubmittingMinMax,
    dirty: dirtyMinMax,
    handleSubmit: handleSubmitMinMax,
    setFieldValue: setFieldValueMinMax,
    setSubmitting: setSubmittingMinMax,
    resetForm: resetFormMinMax,
  } = useFormik<MinMaxFormData>({
    initialValues: {},
    onSubmit: (formValues) => {
      onUpdateItemProfile({
        itemId: toString(item?.uniqueId),
        miMaxQty: toNumber(formValues.miMaxQty),
        miMinQty: toNumber(formValues.miMinQty),
        orderQuantity: calculateOrderQuantity(
          or(toNumber(item?.actualCount), 0),
          or(toNumber(formValues.miMinQty), 0),
          or(toNumber(formValues.miMaxQty), 0)
        ),
      });
      setSubmittingMinMax(false);
    },
    validationSchema: minMaxSchema,
    validateOnChange: false,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    // TODO: solution should be to store actualCount as REAL in DB, like width/length
    let quantity = toString(or(toNumber(item?.actualCount), 0));
    if (isPiBelting) {
      quantity = toNumber(quantity).toFixed(1);
    }
    resetForm({
      values: {
        quantity: choose(!item?.hasCount, '', quantity),
        width: choose(
          !item?.hasCount,
          undefined,
          toString(item?.actualItemWidth)
        ),
        length: choose(
          !item?.hasCount,
          undefined,
          toString(item?.actualItemLength)
        ),
      },
    });
    setTimeout(() => setTriggerInputSelect(Date.now()));
  }, [resetForm, isPiBelting, isOpen, item]);

  useEffect(() => {
    if (and(isPiBelting, or(!isNil(values.width), !isNil(values.length)))) {
      // TODO: setField(...validate=true) works the same as timeout,
      // we may want to refactor other places of app to remove timeouts
      void setFieldValue(
        'quantity',
        (
          or(toNumber(values.width), 0) * or(toNumber(values.length), 0)
        ).toFixed(1),
        true
      );
    }
  }, [setFieldValue, values.width, values.length, isPiBelting]);

  useEffect(() => {
    setMinValue(toString(item?.miMinQty));
    setMaxValue(toString(item?.miMaxQty));
  }, [item?.miMaxQty, item?.miMinQty]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    resetFormMinMax({
      values: {
        miMaxQty: maxValue,
        miMinQty: minValue,
      },
    });
  }, [resetFormMinMax, isOpen, maxValue, minValue, isMinMaxEdit]);

  const isUpdating = updateMinMaxStatus === 'loading';

  useEffect(() => {
    if (updateMinMaxStatus === 'success') {
      setIsMinMaxEdit(false);
      setMinValue(valuesMinMax.miMinQty);
      setMaxValue(valuesMinMax.miMaxQty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMinMaxStatus]);

  let title = t(
    `inventory:${toString(
      choose(isOrderModel, 'orderQuantityTitle', 'minMaxQuantityTitle')
    )}`
  );

  if (isMinMaxEdit) {
    title = t('common:edit');
  } else if (isPiBelting) {
    title = t('inventory:piBeltingTitle');
  } else if (!isStartVMI) {
    title = t('inventory:inventoryTitle');
  }

  const validQuantity = and(isValid, values.quantity !== '');
  const disabledSave = or(!validQuantity, isSubmitting);

  const disabledSaveMinMax = or(!isValidMinMax, isSubmittingMinMax, isUpdating);

  let itemOfText = t('inventory:itemOf', {
    curr: `${or(countedItemCount, 0)}`,
    total: `${or(itemCount, 0)}`,
  });
  if (isFiltered) {
    itemOfText = ` (${t('inventory:filtered')})`;
  }

  const onDefaultAction = () => {
    handleSubmit();
    if (isStartVMI) {
      setIsOpen?.(false);
    }
    if (!isStartVMI) {
      setTriggerNextItem?.(Date.now());
    }
  };

  const dataRowProps = {
    item,
    isStartVMI,
    isMinMaxEdit,
    minValue,
    maxValue,
  };

  const orderInfoVmi = (
    <IonGrid className={classes.itemGrid}>
      <IonRow className={classes.itemText}>
        <IonCol className={classes.itemCol}>
          <DataRow field="description" {...dataRowProps} />
          {ifRender(
            toNumber(item?.onOrderQty) !== 0,
            <div>
              <Text
                variant="mipro-report-info"
                className={classes.onOrderQty}
                text={t('inventory:onOrderQty', {
                  onOrderQty: item?.onOrderQty,
                })}
              />
            </div>
          )}
        </IonCol>
      </IonRow>
      <IonRow className={classes.itemText}>
        <IonCol className={classes.itemCol}>
          <DataRow field="bin" {...dataRowProps} />
          <DataRow field="itemNo" {...dataRowProps} />
          <DataRow field="mino" {...dataRowProps} />
          <DataRow field="csn" {...dataRowProps} />
          <DataRow field="upcNo" {...dataRowProps} />
        </IonCol>
        <IonCol className={classes.itemCol}>
          <DataRow field="mfr" {...dataRowProps} />
          <DataRow field="uom" {...dataRowProps} />
          {ifRender(
            !isMinMaxEdit,
            <div className={classes.editButton}>
              <Button
                variant="link"
                text={t('inventory:editMinMaxQuantityTitle')}
                rightIcon={findIcon('pen', 'far')}
                onClick={() => setIsMinMaxEdit(true)}
                testid="edit-button"
              />
            </div>
          )}
          <DataRow field="min" {...dataRowProps} />
          <DataRow field="max" {...dataRowProps} />
          {ifRender(
            and(!isOrderModel, !isMinMaxEdit),
            <div>
              <Text
                className={classes.text}
                variant="mipro-report-info"
                text={t('inventory:orderQuantity', {
                  orderQty: choose(
                    values.quantity,
                    calculateOrderQuantity(
                      or(toNumber(values.quantity), 0),
                      or(toNumber(minValue), 0),
                      or(toNumber(maxValue), 0)
                    ),
                    item?.orderQty
                  ),
                })}
              />
            </div>
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const orderInfoInventory = (
    <IonGrid className={classes.itemGrid}>
      <IonRow className={classNames(classes.itemText, classes.itemTitle)}>
        <DataRow field="description" {...dataRowProps} />
      </IonRow>
      <IonRow className={classNames(classes.itemRow, classes.itemText)}>
        <DataRow field="mino" {...dataRowProps} />
        <Text
          className={classes.textCounter}
          variant="mipro-report-info"
          text={itemOfText}
        />
      </IonRow>
      {ifRender(
        countGroup?.storeroomNo !== '000',
        <IonRow className={classNames(classes.itemRow, classes.itemText)}>
          <DataRow field="csn" {...dataRowProps} />
        </IonRow>
      )}
      <IonRow className={classNames(classes.itemRow, classes.itemText)}>
        <DataRow field="itemNo" {...dataRowProps} />
        <Text
          className={classes.textCounter}
          variant="mipro-report-info"
          text={t('inventory:itemRemaining', {
            remaining: `${or(itemCount - countedItemCount, 0)}`,
          })}
        />
      </IonRow>
      {ifRender(
        isPiBelting,
        <>
          <IonRow className={classNames(classes.itemRow, classes.itemText)}>
            <DataRow field="lotNo" {...dataRowProps} />
          </IonRow>
          <IonRow className={classNames(classes.itemRow, classes.itemText)}>
            <DataRow field="slabRef" {...dataRowProps} />
          </IonRow>
        </>
      )}
      <IonRow className={classNames(classes.itemRow, classes.itemText)}>
        <DataRow field="bin" {...dataRowProps} />
      </IonRow>
      <IonRow className={classNames(classes.itemRow, classes.itemText)}>
        <DataRow field="mfr" {...dataRowProps} />
      </IonRow>
      {ifRender(
        !isPiBelting,
        <IonRow className={classNames(classes.itemRow, classes.itemText)}>
          <DataRow field="upcNo" {...dataRowProps} />
        </IonRow>
      )}
      <IonRow className={classNames(classes.itemRow, classes.itemText)}>
        <DataRow field="uom" {...dataRowProps} />
      </IonRow>
    </IonGrid>
  );

  const prevClick = debounce(() => {
    setTimeout(() => {
      if (and(!disabledSave, dirty)) {
        handleSubmit();
        setTriggerSwipeRight?.(Date.now() * -1);
      } else {
        setTriggerSwipeRight?.(Date.now());
      }
    }, 0);
  }, 300);

  const nextClick = debounce(() => {
    setTimeout(() => {
      if (and(!disabledSave, dirty)) {
        handleSubmit();
        setTriggerSwipeLeft?.(Date.now() * -1);
      } else {
        setTriggerSwipeLeft?.(Date.now());
      }
    }, 0);
  }, 300);

  useEffect(() => {
    const [triggerValue, itemIndex, scanValue] = split(triggerBleScan, '#');
    if (toNumber(triggerValue) > 0) {
      if (and(!disabledSave, dirty)) {
        handleSubmit();
        setTriggerSaveAfterScan?.(`${Date.now() * -1}#${itemIndex}`);
      } else {
        setTriggerSaveAfterScan?.(
          `${Date.now()}#${itemIndex}#${scanValue}#${toString(
            choose(or(isMinMaxEdit, !isValid), 'N', 'Y')
          )}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerBleScan]);

  const onDrag = async (e: IonItemSlidingCustomEvent<unknown>) => {
    const pixelRatio = await e.target.getOpenAmount();
    if (pixelRatio < -125) {
      prevClick();
      await e.target.close();
    } else if (pixelRatio > 125) {
      nextClick();
      await e.target.close();
    }
  };

  return (
    <Modal
      className={classes.modal}
      wrapperClassName={classes.contentWrapper}
      title={title}
      headerClassName={classes.modalHeader}
      withTitleLine={false}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      forceFullHeight
      testid={testid}
      withCloseButton
      closeButtonClick={() => {
        if (!isMinMaxEdit) {
          if (dirty) {
            setDiscardModalIsOpen(true);
          } else {
            setIsOpen?.(false);
          }
        } else if (isMinMaxEdit) {
          if (dirtyMinMax) {
            setDiscardMinMaxIsOpen(true);
          } else {
            setIsMinMaxEdit(false);
          }
        }
      }}
      canDismiss={and(!dirty, !isMinMaxEdit)}
      onDidPresent={() => setTriggerInputSelect(Date.now())}
    >
      <IonItemSliding
        disabled={or(isMinMaxEdit, !isValid)}
        className={classes.itemSlider}
        onIonDrag={onDrag}
        data-testid={`item-slider-${toString(item?.uniqueId)}`}
      >
        <IonItemOptions side="start">
          <IonItemOption className={classes.itemOption} expandable>
            <FontAwesomeIcon
              className={classes.prevOption}
              icon={['far', 'chevron-left']}
              data-testid="prev-slide-option"
            />
          </IonItemOption>
        </IonItemOptions>
        <IonItem className={classes.item} lines="none">
          <div className={classes.itemContent}>
            {choose(isStartVMI, orderInfoVmi, orderInfoInventory)}
            <form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                if (and(!isMinMaxEdit, !disabledSave)) {
                  onDefaultAction();
                }
                if (and(isMinMaxEdit, !disabledSaveMinMax)) {
                  handleSubmitMinMax();
                }
              }}
              data-testid={`${testid}-form`}
            >
              {choose(
                isPiBelting,
                <IonGrid
                  className={classNames(classes.itemGrid, classes.beltingForm, {
                    [classes.hideInput]: isMinMaxEdit,
                  })}
                >
                  <IonRow
                    className={classNames(classes.itemRow, classes.itemText)}
                  >
                    <IonCol>
                      <Input
                        className={classes.orderQuantityInput}
                        inputRowClassName={classes.inputQuantityRow}
                        inputmode="decimal"
                        type="number"
                        autocomplete="off"
                        min={0}
                        max={MAX_WIDTH}
                        label={t('inventory:width')}
                        triggerInputSelect={triggerInputSelect}
                        name="width"
                        value={values.width}
                        error={or(errors.width, errors.quantity)}
                        setValue={(e) => setFieldValue('width', e, true)}
                        disabled={isSubmitting}
                        testid={`${testid}-width-input`}
                        mask={new RegExp(WIDTH_REGEXP)}
                      />
                    </IonCol>
                    <IonCol>
                      <Input
                        className={classes.orderQuantityInput}
                        inputRowClassName={classes.inputQuantityRow}
                        inputmode="decimal"
                        type="number"
                        autocomplete="off"
                        min={0}
                        max={MAX_LENGTH}
                        label={t('inventory:length')}
                        name="length"
                        value={values.length}
                        error={or(errors.length, errors.quantity)}
                        setValue={(e) => setFieldValue('length', e, true)}
                        disabled={isSubmitting}
                        testid={`${testid}-length-input`}
                        mask={new RegExp(LENGTH_REGEXP)}
                      />
                    </IonCol>
                    <IonCol>
                      <Input
                        className={classNames(
                          classes.orderQuantityInput,
                          classes.readOnlyInput
                        )}
                        inputRowClassName={classes.inputQuantityRow}
                        min={0}
                        max={MAX_QUANTITY}
                        label={t('common:quantity')}
                        name="quantity"
                        value={values.quantity}
                        error={errors.quantity}
                        disabled
                        testid={`${testid}-quantity-input`}
                      />
                    </IonCol>
                  </IonRow>
                  {ifRender(
                    errors.width,
                    <IonRow className={classes.errorWrapper}>
                      <Text
                        variant="content-small"
                        className={classes.errMsg}
                        text={toString(errors.width)}
                      />
                    </IonRow>
                  )}
                  {ifRender(
                    errors.length,
                    <IonRow className={classes.errorWrapper}>
                      <Text
                        variant="content-small"
                        className={classes.errMsg}
                        text={toString(errors.length)}
                      />
                    </IonRow>
                  )}
                  {ifRender(
                    errors.quantity,
                    <IonRow className={classes.errorWrapper}>
                      <Text
                        variant="content-small"
                        className={classes.errMsg}
                        text={errors.quantity}
                      />
                    </IonRow>
                  )}
                </IonGrid>,
                <Input
                  className={classNames(classes.orderQuantityInput, {
                    [classes.hideInput]: isMinMaxEdit,
                  })}
                  inputClassName={classes.inputQuantity}
                  inputRowClassName={classes.inputQuantityRow}
                  inputmode="numeric"
                  type="number"
                  autocomplete="off"
                  min={0}
                  max={MAX_QUANTITY}
                  label={t(
                    `inventory:${toString(
                      choose(
                        isOrderModel,
                        'orderQuantityTitle',
                        'handQuantityTitle'
                      )
                    )}`
                  )}
                  triggerInputSelect={triggerInputSelect}
                  name="quantity"
                  value={values.quantity}
                  error={errors.quantity}
                  setValue={(e) => setFieldValue('quantity', e, true)}
                  disabled={isSubmitting}
                  testid={`${testid}-quantity-input`}
                  mask={new RegExp(QUANTITY_REGEXP)}
                />
              )}
              <Input
                className={classNames(classes.orderQuantityInput, {
                  [classes.hideInput]: !isMinMaxEdit,
                })}
                inputClassName={classes.inputQuantity}
                inputRowClassName={classes.inputQuantityRow}
                inputmode="numeric"
                type="number"
                autocomplete="off"
                min={0}
                max={MAX_QUANTITY}
                label={t('inventory:minQuantityTitle')}
                name="miMinQty"
                value={valuesMinMax.miMinQty}
                error={errorsMinMax.miMinQty}
                setValue={(e) => setFieldValueMinMax('miMinQty', e, true)}
                disabled={isSubmittingMinMax}
                testid={`${testid}-min-qty-input`}
                mask={new RegExp(QUANTITY_REGEXP)}
              />
              <Input
                className={classNames(
                  classes.orderQuantityInput,
                  classes.marginTop,
                  {
                    [classes.hideInput]: !isMinMaxEdit,
                  }
                )}
                inputClassName={classes.inputQuantity}
                inputRowClassName={classes.inputQuantityRow}
                inputmode="numeric"
                type="number"
                autocomplete="off"
                min={0}
                max={MAX_QUANTITY}
                label={t('inventory:maxQuantityTitle')}
                name="miMaxQty"
                value={valuesMinMax.miMaxQty}
                error={errorsMinMax.miMaxQty}
                setValue={(e) => setFieldValueMinMax('miMaxQty', e, true)}
                disabled={isSubmittingMinMax}
                testid={`${testid}-max-qty-input`}
              />
              {ifRender(
                !isMinMaxEdit,
                <>
                  <div className={classes.buttonWrapper}>
                    <Button
                      className={classes.modalButton}
                      variant="action"
                      onClick={onDefaultAction}
                      text={choose(
                        isStartVMI,
                        t('common:save'),
                        t('inventory:saveNext')
                      )}
                      disabled={disabledSave}
                      testid={`${testid}-primary-button`}
                    />
                  </div>
                  {ifRender(
                    or(!isStartVMI, !hideScanNext),
                    <div className={classes.buttonWrapper}>
                      <Button
                        className={classes.modalButton}
                        variant="secondary"
                        onClick={() => {
                          setTimeout(() => {
                            handleSubmit();
                            setIsOpen?.(false);
                            if (isStartVMI) {
                              setTimeout(() => setTriggerScan?.(Date.now()), 0);
                            }
                          }, 0);
                        }}
                        text={choose(
                          isStartVMI,
                          t('inventory:saveScanNext'),
                          t('common:save')
                        )}
                        disabled={disabledSave}
                        testid={`${testid}-secondary-button`}
                      />
                    </div>
                  )}
                  <div
                    className={classNames(
                      classes.buttonGap,
                      classes.buttonWrapper
                    )}
                  >
                    <Button
                      className={classes.modalButton}
                      variant="secondary"
                      onClick={prevClick}
                      iconClassName={classes.stepIcon}
                      leftIcon={['far', 'chevron-left']}
                      text={t('inventory:prev')}
                      disabled={!isValid}
                      testid={`${testid}-prev-button`}
                    />
                    <Button
                      className={classes.modalButton}
                      variant="secondary"
                      onClick={nextClick}
                      iconClassName={classes.stepIcon}
                      rightIcon={['far', 'chevron-right']}
                      text={t('inventory:next')}
                      disabled={!isValid}
                      testid={`${testid}-next-button`}
                    />
                  </div>
                </>
              )}
              {ifRender(
                isMinMaxEdit,
                <div className={classes.buttonWrapper}>
                  <Button
                    className={classes.modalButton}
                    variant="action"
                    onClick={() => handleSubmitMinMax()}
                    text={t('inventory:update')}
                    disabled={disabledSaveMinMax}
                    testid={`${testid}-primary-button`}
                  />
                </div>
              )}
              {/* hackaround form onSubmit https://github.com/ionic-team/ionic-framework/issues/19368 */}
              <button
                aria-hidden
                type="submit"
                className={classes.hiddenButton}
              />
            </form>
          </div>
        </IonItem>
        <IonItemOptions side="end">
          <IonItemOption className={classes.itemOption} expandable>
            <FontAwesomeIcon
              className={classes.nextOption}
              icon={['far', 'chevron-right']}
              data-testid="next-slide-option"
            />
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
      <DiscardModal
        title={t('inventory:closeOrderModal')}
        className={classes.discardModal}
        isOpen={discardModalIsOpen}
        setIsOpen={setDiscardModalIsOpen}
        initialBreakpoint={0.4}
        backdropDismiss={false}
        withRightCloseButton
        testid="discard-changes-modal"
        discardMsg={t('common:discardMsg')}
        goBackButtonTitle={t('inventory:goBack')}
        discardButtonTitle={t('inventory:secondaryConfirm')}
        onDiscardClick={() => setIsOpen?.(false)}
      />
      <DiscardModal
        title={t('inventory:closeMinMaxModal')}
        className={classes.discardModal}
        isOpen={discardMinMaxIsOpen}
        setIsOpen={setDiscardMinMaxIsOpen}
        initialBreakpoint={0.4}
        backdropDismiss={false}
        withRightCloseButton
        testid="discard-min-max-modal"
        discardMsg={t('common:discardMsg')}
        goBackButtonTitle={t('inventory:goBack')}
        discardButtonTitle={t('common:discard')}
        onDiscardClick={() => setIsMinMaxEdit(false)}
      />
    </Modal>
  );
};

export default OrderModal;
