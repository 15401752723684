import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import type { Dictionary } from 'lodash';
import { find, isEmpty, map, toString } from 'lodash';
import { and } from 'common/utils/logicHelpers';
import useGetOrder from 'ProductSearchApp/api/useGetOrder';
import type { CheckoutOrderForm } from 'ProductSearchApp/models/Order';
import type { AddToCart } from 'ProductSearchApp/models/Products';
import type { TerritoryLocation } from 'models/Territory';
import type { RootState } from 'store/reducers';
import { CUSTOMERLESS } from 'utils/constants';

export interface ProductSearchByOptionsProp {
  id: string;
  text: string;
}
export const customerLessProductSearchByOptions: ProductSearchByOptionsProp[] =
  [
    { id: 'smart', text: 'Smart Search' },
    { id: 'startsWith', text: 'Starts With' },
  ];

export const productSearchByOptions: ProductSearchByOptionsProp[] = [
  ...customerLessProductSearchByOptions,
  { id: 'csn', text: 'CSN' },
];

export const productSelectCheckboxOptions = ['branch', 'corp', 'cust', 'sales'];

export const supplierConnectCapableTitle = 'SC';

export const sssGreenArray = [
  'M2',
  'M4',
  'M6',
  'R2',
  'R4',
  'R6',
  'P2',
  'P4',
  'P6',
  'O2',
  'O4',
  'O6',
  'RB',
];

export const productImageUrl = '/motion3/fsdb/images/item/';

export const isCustomerLess = (id?: string) => id === CUSTOMERLESS;

export const isSalesBranch = (
  locationTree?: Dictionary<TerritoryLocation>,
  miLoc?: string
) => find(locationTree, { miLoc })?.locationType === 'S';

export const useHandleCustomerUpdate = () => {
  const history = useHistory();
  const { currentCartCustomer } = useSelector((state: RootState) => state.user);
  const { getOrder } = useGetOrder({});

  return {
    doOcnCustomerUpdate: async ({
      orderCtlNo,
      cartMiLoc,
      miLoc,
      shipToCustNo,
      customerName,
      href = '',
      onUpdateHeader,
      onAddToCart,
    }: {
      orderCtlNo: string;
      cartMiLoc: string;
      miLoc: string;
      shipToCustNo: string;
      customerName: string;
      href?: string;
      onUpdateHeader: (body: CheckoutOrderForm) => void;
      onAddToCart: (body: AddToCart) => void;
    }) => {
      if (and(!!href, isEmpty(currentCartCustomer))) {
        history.push(href);
        return;
      }
      const order = await getOrder?.(toString(cartMiLoc), toString(orderCtlNo));
      if (miLoc === cartMiLoc) {
        onUpdateHeader({
          orderCtlNo,
          miLoc,
          shipToCustNo,
          sendUnprocessed: false,
          addCustomer: true,
          customerName,
        });
      } else {
        onAddToCart({
          customerName,
          mfgPartNo: order?.items[0]?.mfgPartNo,
          customerInfo: {
            miLoc,
            customerNo: shipToCustNo,
          },
          switchCustomer: true,
          itemList: map(
            order?.items,
            ({
              itemNo,
              mfrCtlNo: itemMfrCtlNo,
              groupSerial: itemGroupSerial,
              custStockNo,
              mfgPartNo,
              opQtyOrdered,
            }) => ({
              itemNo,
              mino: `${itemMfrCtlNo}${itemGroupSerial}`,
              groupSerial: itemGroupSerial,
              custStockNo,
              mfgPartNo,
              quantity: opQtyOrdered,
            })
          ),
        });
      }
    },
  };
};
