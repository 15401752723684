import React from 'react';
import classnames from 'classnames';
import { toString } from 'lodash';
import type { BaseComponentProps } from 'common/components/utils/renderHelpers';
import type { Address as AddressType } from 'models/Address';
import { getAddress, getMapLocation, openNavigator } from 'utils/address';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import LongPressCopyGesture from 'components/LongPressCopyGesture/LongPressCopyGesture';
import Text from 'components/Text/Text';
import classes from './Address.module.scss';

interface AddressProps extends BaseComponentProps {
  address: AddressType;
  name?: string;
  onClick?: () => void;
}

const Address = ({
  address,
  name,
  className,
  onClick,
}: AddressProps): JSX.Element => {
  const mapLocation = getMapLocation(address);
  const addressFull = getAddress(address);

  return (
    <div className={classnames(className, classes.detailsTextWrapper)}>
      {name && (
        <div className={classes.mainAddressWrapper}>
          <Text text={toString(name)} />
          {!mapLocation && (
            <Text className={classes.address} text={addressFull} />
          )}
        </div>
      )}
      {mapLocation && (
        <LongPressCopyGesture copyableValue={addressFull}>
          <Button
            variant="link"
            className={classes.link}
            leftIcon={findIcon('map-marker')}
            onClick={() => {
              onClick?.();
              openNavigator(mapLocation);
            }}
            testid="map-button"
          >
            <div className={classes.buttonTextWrapper}>
              <Text
                className={classes.buttonText}
                variant="content-heavy"
                text={addressFull}
              />
            </div>
          </Button>
        </LongPressCopyGesture>
      )}
    </div>
  );
};

export default Address;
