import { useSelector } from 'react-redux';
import { choose, or } from 'common/utils/logicHelpers';
import { useGetAvpUser } from 'common/utils/userInfo';
import i18next from 'i18next';
import { AccessControlType } from 'hooks/useAccessControls';
import type { RootState } from 'store/reducers';

interface UseGetCamUserDetailsResponse {
  accessControl: AccessControlType;
  isCamUser?: boolean;
  subTitle: string;
}

const useGetCamUserDetails = (): UseGetCamUserDetailsResponse => {
  const { isCamUser } = useSelector((state: RootState) => state.user);

  const { isAvpUserWithExecView } = useGetAvpUser();

  const accessControl = choose(
    or(isCamUser, isAvpUserWithExecView),
    AccessControlType.camUserReports,
    AccessControlType.viewReports
  ) as AccessControlType;

  return {
    accessControl,
    isCamUser,
    subTitle: i18next.t('reports:myCorporateAccounts'),
  };
};

export default useGetCamUserDetails;
