import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {
  filter,
  includes,
  isEmpty,
  isNil,
  map,
  orderBy,
  size,
  toString,
  toUpper,
  escapeRegExp,
  chain,
  indexOf,
} from 'lodash';
import { IonContent, IonPage } from '@ionic/react';
import { choose } from 'common/utils/logicHelpers';
import {
  documentsURL,
  homeURL,
  salesPlaysURL,
  replenishmentViewURL,
  reportsURL,
  searchURL,
  issueViewURL,
  territoriesURL,
  workOrdersURL,
  reportsDrillDownURL,
  inventoryURL,
  activitiesURL,
} from 'navigation';
import { useDevice } from 'providers/DeviceProvider';
import { useToasts } from 'providers/ToastProvider';
import type { ReportItemType } from 'ReportsApp/models';
import { useDebounce } from 'use-debounce';
import type { TerritoryLocation } from 'models/Territory';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { setUserMiLoc } from 'store/user';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import ActionRow from 'components/ActionRow/ActionRow';
import Header from 'components/Header/Header';
import Searchbar from 'components/Searchbar/Searchbar';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './Territories.module.scss';

const Territories = (): JSX.Element => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const noRedirect = params.get('noRedirect');
  const history = useHistory();
  const dispatch = useDispatch();
  const { miLoc = '', locationTree = {} } = useSelector(
    (state: RootState) => state.user
  );
  const { id = '' } = useParams<{ id: string }>();
  const { addToast } = useToasts();
  const { lastVisitedURL } = useDevice();
  const parent = locationTree[id];
  const grandParent = locationTree[toString(parent?.parent)];
  const territories = orderBy(
    filter(locationTree, { parent: id }),
    ['miLoc'],
    ['asc']
  );
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);
  const withSearchQuery = !isEmpty(searchQueryValue);

  const filteredTerritories = useMemo(
    () =>
      orderBy(
        filter(
          locationTree,
          ({ name, miLoc: iMiLoc }) =>
            withSearchQuery &&
            (!isNil(
              new RegExp(escapeRegExp(searchQueryValue), 'i').exec(name)
            ) ||
              !isNil(
                new RegExp(escapeRegExp(searchQueryValue), 'i').exec(iMiLoc)
              ))
        ),
        ['miLoc'],
        ['asc']
      ),
    [locationTree, searchQueryValue, withSearchQuery]
  );

  const goToLastVisitedURL = () => {
    history.replace(lastVisitedURL || homeURL());
  };

  const goToRootOfLastVisitedTab = () => {
    // DOC: by default, go to Home
    let location = homeURL();
    if (includes(lastVisitedURL, homeURL())) {
      location = homeURL();
    } else if (includes(lastVisitedURL, activitiesURL())) {
      location = activitiesURL();
    } else if (includes(lastVisitedURL, searchURL())) {
      location = searchURL();
    } else if (includes(lastVisitedURL, salesPlaysURL())) {
      location = salesPlaysURL();
    } else if (includes(lastVisitedURL, reportsURL())) {
      const reportType: ReportItemType = chain(lastVisitedURL)
        .split('/')
        .nth(indexOf(lastVisitedURL, `${reportsURL()}/`) + 4)
        .value() as ReportItemType;
      location = reportType
        ? concatRoutes(
            reportsURL(),
            reportsDrillDownURL(reportType, '', '', '', '')
          )
        : reportsURL();
    } else if (includes(lastVisitedURL, documentsURL())) {
      location = documentsURL();
    } else if (includes(lastVisitedURL, replenishmentViewURL())) {
      location = replenishmentViewURL();
    } else if (includes(lastVisitedURL, issueViewURL())) {
      location = issueViewURL();
    } else if (includes(lastVisitedURL, workOrdersURL())) {
      location = workOrdersURL();
    } else if (includes(lastVisitedURL, inventoryURL())) {
      location = lastVisitedURL;
    }
    history.replace(location);
  };

  const changeTerritory = (
    hasChildren: boolean | undefined,
    iMiLoc: string | undefined
  ) => {
    if (!withSearchQuery && hasChildren) {
      history.replace({ pathname: territoriesURL(iMiLoc), search });
    }
  };

  const setTerritory = (location: string) => {
    dispatch(setUserMiLoc({ miLoc: location }));
    addToast({
      text: `New location set as ${location}.`,
      testid: 'location-toast',
    });
    if (noRedirect) {
      goToLastVisitedURL();
      return;
    }
    goToRootOfLastVisitedTab();
  };

  const getHeaderText = (location: TerritoryLocation) =>
    !isNil(location)
      ? `${location?.miLoc} - ${location?.name}`
      : 'Set Sales Territory';

  const onSearch = () => {
    if (withSearchQuery) {
      const foundLocation = locationTree[toUpper(searchQueryValue)];
      if (!isNil(foundLocation)) {
        if (foundLocation.hasAccess) {
          setTerritory(foundLocation.miLoc);
        }
      } else {
        addToast({
          type: ToastType.error,
          text: 'Location not found.',
          testid: 'location-not-found',
        });
      }
    }
  };

  return (
    <IonPage className={classes.Territories} data-testid="page-Territories">
      <Header
        theme="light"
        eyebrow={id ? getHeaderText(grandParent) : ''}
        title={getHeaderText(parent)}
        withBackButton={!isNil(parent)}
        testid="territories"
        className={classes.header}
        href={
          !isNil(parent)
            ? `${territoriesURL(parent?.parent)}${search}`
            : undefined
        }
        onDismiss={() => goToLastVisitedURL()}
        hideHomeMenu
        showCartIcon={false}
      >
        <Searchbar
          className={classes.input}
          value={searchQuery}
          placeholder="Search"
          setValue={setSearchQuery}
          onSearch={onSearch}
          testid="search-input"
        />
      </Header>
      <IonContent className={classes.content}>
        {map(
          withSearchQuery ? filteredTerritories : territories,
          ({
            miLoc: iMiLoc,
            name,
            hasChildren,
            hasAccess,
            id: territoryId,
          }) => (
            <ActionRow
              key={`territory-${iMiLoc}`}
              className={classNames(classes.item, {
                [classes.selectedItem]: miLoc === iMiLoc,
              })}
              text={`${territoryId} - ${name}`}
              onClick={() => changeTerritory(hasChildren, iMiLoc)}
              leftButton={choose(
                hasAccess,
                {
                  className: classes.button,
                  variant: 'link',
                  text: miLoc === iMiLoc ? '' : 'Set',
                  icon: miLoc === iMiLoc ? findIcon('check') : undefined,
                  onClick: () => setTerritory(iMiLoc),
                  testid: `location-action-${iMiLoc}-button`,
                },
                {
                  className: classes.button,
                  testid: `location-action-${iMiLoc}-button`,
                }
              )}
              // TODO remove after converting to modal
              withoutHrefArrow={hasChildren}
              testid={`location-action-${iMiLoc}`}
              track={false}
            />
          )
        )}
        {withSearchQuery && size(filteredTerritories) === 0 && (
          <WarningMessage
            title={t('common:noResults')}
            className={classes.warning}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Territories;
