import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isObject, map, toString } from 'lodash';
import type {
  BaseComponentProps,
  OptionalRenderProp,
} from 'common/components/utils/renderHelpers';
import { DataTypeEnum, formatKeyValue } from 'common/utils/valueFormatter';
import Text from 'components/Text/Text';
import classes from './InsetList.module.scss';

export interface InsetListData extends BaseComponentProps {
  label?: OptionalRenderProp;
  type?: DataTypeEnum;
  value?: OptionalRenderProp;
  customValue?: React.ReactNode;
}

interface InsetListProps extends BaseComponentProps {
  data?: InsetListData[];
}

const InsetList = ({
  data,
  testid = 'inset-list',
  className,
}: InsetListProps): JSX.Element => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  return (
    <table
      className={classNames(className, classes.table)}
      data-testid={testid}
    >
      <tbody>
        {map(data, (item, index) => {
          let labelProps = item.label;
          const label = isObject(labelProps) ? labelProps.text : labelProps;
          labelProps = isObject(labelProps) ? labelProps : undefined;

          let valueProps = item.value;
          const value = isObject(valueProps) ? valueProps.text : valueProps;
          const type = item.type || DataTypeEnum.string;

          valueProps = isObject(valueProps) ? valueProps : undefined;

          return (
            <tr
              className={classNames(classes.insetList, item.className)}
              key={index}
            >
              <td>
                <Text
                  text={toString(label)}
                  variant="list-item-subtitle"
                  testid={`label-${index}`}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...labelProps}
                />
              </td>
              <td>
                {!item.customValue && (
                  <Text
                    variant="list-item-title"
                    testid={`value-${index}`}
                    text={formatKeyValue({
                      value,
                      type,
                      language,
                      currency: 'USD',
                    })}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...valueProps}
                  />
                )}
                {item.customValue}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default InsetList;
