import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import classNames from 'classnames';
import { isNil, map, toString } from 'lodash';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow, IonSkeletonText } from '@ionic/react';
import { fromUnixTime } from 'date-fns';
import { namespaces } from 'i18n/i18n.constants';
import { useReportsConfig } from 'providers/ReportsProvider';
import JobWarning from 'ReportsApp/components/JobWarning/JobWarning';
import type { DateSegmentType } from 'models/Reports';
import type { SortOption } from 'models/Sort';
import { formatCharDate } from 'utils/date';
import { getErrorMessage, getInvalidBusDay } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { isSalesJobRunning, noCoprporateAccountAssigned } from 'utils/reports';
import Button from 'components/Button/Button';
import ContentRow from 'components/ContentRow/ContentRow';
import DropDown from 'components/DropDown/DropDown';
import OverlayCard from 'components/Overlays/OverlayCard';
import OverlayInfobar from 'components/Overlays/OverlayInfoBar';
import ReportDateDropdown from 'components/ReportDateDropdown/ReportDateDropdown';
import Text from 'components/Text/Text';
import classes from './DashboardCard.module.scss';

export interface DashboardLineProps {
  text: string;
  value: unknown;
  valueType: 'currency' | 'percentage' | 'string';
  changePercentage?: number;
  changeBp?: number;
  changeLabel?: string;
  currencyType?: string;
  showInfoIcon?: boolean;
  hidden?: boolean;
  onClick?: () => void;
}

interface ButtonProps {
  href: string;
  text?: string;
  icon?: IconProp;
  imageSrc?: string;
}

interface DashboardCardProps {
  title?: string;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  customTitle?: React.ReactNode;
  subtitle?: string;
  lines: DashboardLineProps[];
  requestType: DateSegmentType;
  isLoading?: boolean;
  id: 'salesPerf' | 'pick12' | 'webPerf';
  reportKey: string;
  tabReportKey: string;
  locations?: SortOption[];
  overlayBarMsg?: string;
  overlayInfoHeader?: string;
  overlayInfoContent?: string;
  busPeriod: number;
  error?: AxiosError | null;
  setLocation?: (key: string) => void;
  setRequestType: (v: DateSegmentType) => void;
  setBusPeriod: (v: Date) => void;
}

const DashboardCard = ({
  title,
  customTitle,
  subtitle,
  lines,
  reportKey,
  tabReportKey,
  requestType,
  setRequestType,
  primaryButton,
  secondaryButton,
  locations = [],
  setLocation,
  // TO DO once we do loading items
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  overlayBarMsg = '',
  overlayInfoHeader = '',
  overlayInfoContent = '',
  id,
  busPeriod,
  setBusPeriod,
  isLoading = true,
  error,
}: DashboardCardProps): JSX.Element => {
  const [requestDate, setRequestDate] = useState('');
  const [isOpenOverlayCard, setIsOpenOverlayCard] = useState<boolean>(false);
  const [isShowInfobar, setIsShowInfobar] = useState<boolean>(true);

  const { t } = useTranslation(namespaces.reports);

  const {
    updateRequestType: reportTabUpdateRequestType,
    updateBusPeriod: reportTabUpdateBusPeriod,
  } = useReportsConfig({ key: tabReportKey });

  const setReportTabFilters = () => {
    reportTabUpdateRequestType(requestType);
    reportTabUpdateBusPeriod(fromUnixTime(busPeriod));
  };

  const isJobRunning = isSalesJobRunning(error);

  const isInvalidBusDay = getInvalidBusDay(error);

  let errorMessage = getErrorMessage(error);

  if (isInvalidBusDay) {
    errorMessage = t('reports:invalidBusDayMsg', {
      requestType: requestType === 'MTD' ? t('common:month') : t('common:year'),
    });
  }

  if (noCoprporateAccountAssigned(error)) {
    errorMessage = t('reports:noAcctsAssigned');
  }

  const errorContent = isJobRunning ? (
    <JobWarning
      className={classes.jobWarning}
      reportName={t('salesPerformance')}
    />
  ) : (
    <div className={classes.errorCard}>
      <IonRow key="error-row-1">
        <FontAwesomeIcon
          className={classes.icon}
          icon={findIcon('exclamation-triangle', 'fas')}
        />
      </IonRow>
      <IonRow key="error-row-2" className={classes.errorRow}>
        <Text
          className={classes.errorText}
          variant="content-default"
          text={
            isInvalidBusDay
              ? t('reports:emptyReportHeader')
              : t('reports:errorReports')
          }
        />
      </IonRow>
      <IonRow key="error-row-3">
        <Text
          className={classes.errorText}
          variant="content-default"
          text={errorMessage}
        />
      </IonRow>
    </div>
  );

  return (
    <div className={classes.card}>
      <div className={classes.header}>
        <IonRow className={classes.titleRow}>
          {customTitle}
          {isNil(customTitle) && (
            <Text variant="title-action-card" text={toString(title)} />
          )}
          <ReportDateDropdown
            reportKey={reportKey}
            id={id}
            busPeriod={busPeriod}
            requestType={requestType}
            setBusPeriod={setBusPeriod}
            setRequestType={setRequestType}
            setRequestDate={setRequestDate}
            dropdownClassName={classes.typeDropdown}
          />
        </IonRow>
        <IonRow className={classes.subtitleRow}>
          <div className={classes.left}>
            {locations.length > 1 ? (
              <>
                <Button
                  className={classes.dropdownButton}
                  variant="link"
                  text={toString(subtitle)}
                  rightIcon={findIcon('caret-down', 'fas')}
                  testid="location-button"
                  id="location"
                />
                <DropDown
                  trigger="location"
                  dismissOnSelect
                  alignment="start"
                  testid="location-dropdown"
                  filterOptions={locations}
                  selectedItem={
                    locations.find((item) => item.name === subtitle)?.key
                  }
                  onOptionClick={(option) =>
                    setLocation?.call(null, option.key)
                  }
                  id="location"
                />
              </>
            ) : (
              <Text
                className={classes.labelHeader}
                variant="label-header-micro"
                text={toString(subtitle)}
              />
            )}
          </div>
          <div className={classes.right}>
            <Text
              variant="label-header-micro"
              text={requestDate}
              className={classes.labelHeader}
            />
          </div>
        </IonRow>
      </div>
      <div className={classes.content}>
        {overlayBarMsg && isShowInfobar && (
          <OverlayInfobar
            message={overlayBarMsg}
            setIsOpenOverlayCard={setIsOpenOverlayCard}
            setIsShowInfobar={setIsShowInfobar}
          />
        )}
        {error && errorContent}
        {!error &&
          lines &&
          map(
            lines,
            (
              {
                text,
                value,
                valueType,
                changePercentage,
                changeLabel,
                currencyType,
                changeBp,
              },
              index
            ) => (
              <ContentRow
                key={index}
                loading={isLoading}
                text={text}
                valueType={valueType}
                value={toString(value)}
                currencyType={currencyType}
                changePercentage={changePercentage}
                changeLabel={changeLabel}
                changeBp={changeBp}
                testid="dashboard-card-content-row"
              />
            )
          )}
      </div>
      <div className={classes.footer}>
        <IonRow className={classes.footerButtonsRow}>
          {primaryButton && (
            <Button
              className={classNames(
                classes.footerButton,
                classes.primaryButton
              )}
              variant="action"
              text={primaryButton.text || t('viewFullReport')}
              testid="view-report-button"
              href={primaryButton.href}
              onClick={setReportTabFilters}
            />
          )}
          {secondaryButton && (
            <Button
              className={classNames(
                classes.footerButton,
                classes.secondaryButton
              )}
              iconClassName={classes.secondaryBtnIcon}
              icon={secondaryButton.icon}
              variant="secondary"
              text={secondaryButton.text || t('viewFullReport')}
              testid="view-secondary-button"
              href={secondaryButton.href}
              onClick={setReportTabFilters}
            >
              {secondaryButton.imageSrc && (
                <img
                  src={secondaryButton.imageSrc}
                  className={classes.secondaryBtnIcon}
                  alt={secondaryButton.text}
                />
              )}
            </Button>
          )}
          {isLoading ? (
            <IonSkeletonText animated className={classes.timestampLoader} />
          ) : (
            <Text
              className={classes.timestamp}
              variant="label-header-micro"
              text={t('updateDateText', {
                updateDate: formatCharDate(new Date(Date.now())),
              })}
            />
          )}
        </IonRow>
      </div>
      {isOpenOverlayCard && (
        <OverlayCard
          infoHeader={overlayInfoHeader}
          detailText={overlayInfoContent}
          onDismiss={setIsOpenOverlayCard}
        />
      )}
    </div>
  );
};

export default DashboardCard;
