import React from 'react';
import classNames from 'classnames';
import { isNil, toNumber, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow, IonSkeletonText } from '@ionic/react';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import ChangeFormat from 'components/ChangeFormat/ChangeFormat';
import CurrencyFormat from 'components/CurrencyFormat/CurrencyFormat';
import Text from 'components/Text/Text';
import classes from './ContentRow.module.scss';

export interface ContentRowProps {
  className?: string;
  loading?: boolean;
  text: string;
  valueType: 'currency' | 'percentage' | 'string';
  value: string;
  currencyType?: string;
  changeBp?: number;
  changePercentage?: number;
  changeLabel?: string;
  testid: string;
  showInfoIcon?: boolean;
  openHelpModal?: (b: boolean) => void;
}
const ContentRow = ({
  className,
  loading,
  text,
  valueType,
  value,
  currencyType,
  changeBp,
  changePercentage,
  changeLabel,
  testid = 'content-row',
  showInfoIcon = false,
  openHelpModal,
}: ContentRowProps): JSX.Element => (
  <IonRow className={classNames(className, classes.contentRow)}>
    <div className={classes.valueCol}>
      {loading ? (
        <IonSkeletonText
          animated
          className={classes.label}
          data-testid="loader"
        />
      ) : (
        <span className={classes.valueWrapper}>
          <Text
            className={classes.label}
            variant="label-header-micro"
            text={text}
            testid="label"
          />
          {showInfoIcon && (
            <Button
              testid={testid}
              onClick={() => openHelpModal?.(true)}
              className={classes.button}
            >
              <FontAwesomeIcon
                className={classes.icon}
                icon={findIcon('info-circle', 'fas')}
              />
            </Button>
          )}
        </span>
      )}
      {valueType === 'currency' &&
        (loading ? (
          <IonSkeletonText animated className={classes.currencyValue} />
        ) : (
          <CurrencyFormat
            className={classes.value}
            variant="content-heavy"
            value={toNumber(value)}
            testid={`${testid}-value-text`}
            currencyType={currencyType}
          />
        ))}
      {valueType === 'percentage' &&
        (loading ? (
          <IonSkeletonText animated className={classes.percentageValue} />
        ) : (
          <Text
            className={classes.value}
            variant="content-heavy"
            text={`${toNumber(value)}%`}
            testid={`${testid}-value-text`}
          />
        ))}
      {valueType === 'string' &&
        (loading ? (
          <IonSkeletonText animated className={classes.stringValue} />
        ) : (
          <Text
            className={classes.value}
            variant="content-heavy"
            text={toString(value)}
            testid={`${testid}-value-text`}
          />
        ))}
    </div>
    {(!isNil(changePercentage) || !isNil(changeBp)) && (
      <div className={classes.changeCol}>
        {loading ? (
          <>
            <IonSkeletonText animated className={classes.changeSign} />
            <IonSkeletonText animated className={classes.changePercent} />
            <IonSkeletonText animated className={classes.changeLabel} />
          </>
        ) : (
          <>
            <ChangeFormat
              positiveClassName={classes.positive}
              negativeClassName={classes.negative}
              variant="content-small-heavy"
              value={changePercentage ?? changeBp ?? 0}
              typeVariant={!isNil(changeBp) ? 'bp' : 'number'}
              hideSign
              testid={`${testid}-change-text`}
            />
            <Text
              className={classNames(classes.label, classes.newLineBreak)}
              variant="label-header-micro"
              text={toString(changeLabel)}
              testid={`${testid}-change`}
            />
          </>
        )}
      </div>
    )}
  </IonRow>
);

export default ContentRow;
