import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { get, has, some } from 'lodash';
import { or } from 'common/utils/logicHelpers';
import { isAccountRep, useGetAvpUser } from 'common/utils/userInfo';
import { territoriesURL } from 'navigation';
import type { RootState } from 'store/reducers';
import useChangeAppMode from './useChangeAppMode';

interface UseChangeLocatorResponse {
  isLocatorAvailable: boolean;
  locatorURL: string;
}

const useChangeLocation = (): UseChangeLocatorResponse => {
  const {
    miLoc = '',
    userInfo,
    locationTree = {},
    isCamUser,
  } = useSelector((state: RootState) => state.user);
  const jobCode = get(userInfo, 'jobCode');
  const { isMiProApp } = useChangeAppMode();
  const { isAvpUserWithExecView } = useGetAvpUser();
  const hideLocationSwitcher =
    or(
      isAccountRep(jobCode) && has(locationTree, 'VT'),
      isCamUser,
      isAvpUserWithExecView
    ) && isMiProApp;

  const currentLocation = locationTree[miLoc]?.parent;
  const isMultiLocator = useMemo(
    () =>
      some(
        Object.values(locationTree),
        (item) => item.hasAccess && item.miLoc !== miLoc
      ),
    [locationTree, miLoc]
  );

  return {
    isLocatorAvailable: !hideLocationSwitcher && isMultiLocator,
    locatorURL: territoriesURL(currentLocation),
  };
};

export default useChangeLocation;
