import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import { toNumber, toString } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationStatus } from '@tanstack/react-query';
import useAPIUrl from 'api';
import useCountPlanDB from 'InventoryApp/database/useCountPlanDB';
import type {
  CountGroup,
  CountGroupItem,
} from 'InventoryApp/models/InventoryPlanGroup';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import {
  doPromiseAPI,
  getPlaceholderData,
  onMutateUpdate,
  onSuccessMutation,
} from 'api/helpers';
import { ToastType } from 'models/Toast';
import { findIcon } from 'utils/icons';
import {
  findCountGroupItemsQueryKey,
  findCountGroupTotalItemsQueryKey,
} from './useFindCountGroupItems';
import { findCountGroupsQueryKey } from './useFindCountGroups';

interface VerifyManualItemAPIResponse {
  message?: string;
  error?: string;
  Success: boolean;
}

interface UseVerifyAndSaveManualItemResponse {
  status: MutationStatus;
  data?: VerifyManualItemAPIResponse;
  error?: AxiosError | null;
  onSubmitVerifyManualItem: (body: CountGroupItem) => void;
}

const useVerifyAndSaveManualItem = (): UseVerifyAndSaveManualItemResponse => {
  const { axios } = useAxios();
  const { t } = useTranslation();
  const { addItemsToCountPlan, updateCountGroups } = useCountPlanDB();
  const queryClient = useQueryClient();
  const { verifyManualItemAPI } = useAPIUrl();
  const { addToast } = useToasts();

  const doSubmitVerifyManualItem = (body: CountGroupItem) => {
    return doPromiseAPI(async (cancelToken) => {
      const { actualCount, groupId, ...rest } = body;
      const { data } = await axios.post<VerifyManualItemAPIResponse>(
        verifyManualItemAPI(),
        { ...rest, count: actualCount, groupId },
        { cancelToken }
      );
      if (!data.Success) {
        throw new Error(data?.error);
      }
      const cachedGroup = getPlaceholderData<CountGroup>({
        queryClient,
        queryKey: findCountGroupsQueryKey,
        objectKey: 'items',
        findPredicate: { uniqueId: groupId },
      });
      await addItemsToCountPlan([
        {
          ...body,
          type: 'M',
          lineNo: 0,
          pageNo: 0,
          hasCount: true,
          hasLocalCount: true,
          allowEdit: true,
          mino: `${body.mfrCtlNo}${body.groupSerialNo}`,
        },
      ]);
      const lines = toString(toNumber(cachedGroup?.lines) + 1);
      const linesUpdated = toString(toNumber(cachedGroup?.linesUpdated) + 1);
      await updateCountGroups([
        { lines, linesUpdated, groupUniqueId: toString(groupId) },
      ]);

      return { item: data, lines, linesUpdated };
    });
  };

  const { status, error, mutate, data } = useMutation(
    doSubmitVerifyManualItem,
    {
      // TODO: optimisitc update for tabs totals?
      onSuccess: ({ lines, linesUpdated }, { groupId }) => {
        void onMutateUpdate<CountGroup>({
          queryClient,
          queryKey: findCountGroupsQueryKey,
          updatedItems: [
            {
              uniqueId: groupId,
              lines,
              linesUpdated,
            } as CountGroup,
          ],
          dataPath: 'items',
          findPredicates: [{ uniqueId: groupId }],
          markAsUpdated: false,
          isInfiniteQuery: true,
        });
        void onSuccessMutation(queryClient, findCountGroupItemsQueryKey);
        void onSuccessMutation(queryClient, findCountGroupTotalItemsQueryKey);
        addToast({
          type: ToastType.success,
          variant: 'mipro-toast',
          header: t('inventory:createItemSuccess'),
          leftIcon: findIcon('check-circle', 'fas'),
          testid: 'add-item-creation-toast',
        });
      },
      onError: (err) => {
        addToast({
          type: ToastType.error,
          text: err instanceof Error ? err?.message : toString(err),
          testid: 'verify-manual-item-error-toast',
        });
      },
    }
  );

  return {
    status,
    data: data?.item,
    error: error as AxiosError,
    onSubmitVerifyManualItem: (body: CountGroupItem) => mutate(body),
  };
};

export default useVerifyAndSaveManualItem;
