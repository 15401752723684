import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { find, isEmpty, map, size, toString } from 'lodash';
import { IonContent, IonFooter, IonLoading, IonRow } from '@ionic/react';
import type { ScanListenerEvent } from 'capacitor-datawedge';
import { DataWedge } from 'capacitor-datawedge';
import List from 'common/components/List/List';
import { useIonContentRef } from 'common/components/utils/renderHelpers';
import { Formik } from 'formik';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import useAddReplenishment from 'StoreroomsApp/api/useAddItemsReplenishment';
import useFindReplenishmentItems from 'StoreroomsApp/api/useFindReplenishmentItems';
import useUploadReplenishment from 'StoreroomsApp/api/useUploadReplenishment';
import type { ItemReplenishmentDTO } from 'StoreroomsApp/models/Replenishment';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import { ToastType } from 'models/Toast';
import selectIsBranchLocation from 'store/user/selectors';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import ReplenishmentItem from './ReplenishmentItem';
import classes from './ReplenishmentsList.module.scss';

export interface ReplenishmentForm {
  items?: ItemReplenishmentDTO[];
}

interface ReplenishmentsListProps {
  searchQuery: string;
}

const ReplenishmentsList = ({
  searchQuery,
}: ReplenishmentsListProps): JSX.Element => {
  const { isOnline } = useNetworkStatus();
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const { addToast } = useToasts();
  const isBranch = useSelector(selectIsBranchLocation);
  const ac = useAccessControls(AccessControlType.downloadItemReplenishment);
  const [scannedLabel, setScannedLabel] = useState('');
  const [scanInProgress, setScanInProgress] = useState(false);
  const [uploadPressed, setUploadPressed] = useState(false);
  const [addingFailed, setAddingFailed] = useState(false);
  const [cachedItems, setCachedItems] = useState<ItemReplenishmentDTO[]>();
  const { nodeRef, node } = useIonContentRef();

  const {
    miLoc,
    replenishmentItems,
    error,
    isLoading: itemsIsLoading,
  } = useFindReplenishmentItems({
    query: searchQuery,
  });

  const {
    data: uploadResponse,
    status: uploadStatus,
    onUploadReplenishment,
    isLoading: uploadLoading,
  } = useUploadReplenishment();

  const {
    onAddItemsReplenishment,
    status: addStatus,
    isLoading: addItemIsLoading,
  } = useAddReplenishment();

  const uploadIsLoading =
    uploadLoading || addItemIsLoading || addingFailed || uploadPressed;

  const onScanItem = (barcode: string) => {
    if (!scanInProgress) {
      setScanInProgress(true);
      try {
        onAddItemsReplenishment({ barcode });
        setScannedLabel('');
      } catch (e) {
        addToast({
          type: ToastType.error,
          text: 'Cant find an item with the scanned barcode',
          testid: 'item-scan-error-toast',
        });
        setScanInProgress(false);
      }
    }
  };

  useEffect(() => {
    if (addStatus !== 'loading') {
      setScanInProgress(false);
      setAddingFailed(false);
      setCachedItems([]);
    }
  }, [addStatus]);

  useEffect(() => {
    DataWedge.addListener('scan', (e: ScanListenerEvent) => {
      const barcode = e.data;
      setScannedLabel(barcode);
    }).catch(() => null);
  }, []);

  useDebounce(
    () => {
      if (isBranch && scannedLabel && url === pathname) {
        void onScanItem(scannedLabel);
      }
    },
    500,
    [scannedLabel]
  );

  useEffect(() => {
    setCachedItems([]);
  }, [miLoc]);

  useEffect(() => {
    if (uploadStatus !== 'loading') {
      setUploadPressed(false);
    }
    if (uploadStatus === 'success') {
      if (size(uploadResponse?.failedItems) > 0) {
        setAddingFailed(true);
        onAddItemsReplenishment({
          hideSuccessToast: true,
          items: map(uploadResponse?.failedItems, (i) => {
            const item = find(uploadResponse?.sendItems, {
              itemId: i.combinedStoreroomItemId,
            }) as ItemReplenishmentDTO;
            return {
              ...item,
              itemName: `${item.barcodeValue} ${item.itemDescription}`,
              errorCode: i.errorCode,
              error: i.errorMessage,
            };
          }),
        });
      } else {
        setCachedItems([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadStatus, uploadResponse?.failedItems, uploadResponse?.sendItems]);

  return (
    <Formik<ReplenishmentForm>
      initialValues={{
        items: size(replenishmentItems) > 0 ? replenishmentItems : cachedItems,
      }}
      enableReinitialize
      onSubmit={(values) => {
        setUploadPressed(true);
        setCachedItems(values.items);
        // DOC: timeout needed for scanner devices lag to disable button
        setTimeout(() => {
          onUploadReplenishment({
            items: values.items || [],
          });
        }, 0);
      }}
      key={`replenishment-form-${miLoc}`}
    >
      {({ values, handleSubmit }) => {
        const itemsSize = size(values.items);
        return (
          <>
            <IonContent ref={nodeRef} className={classes.content}>
              <IonLoading
                isOpen={uploadIsLoading}
                message="Uploading replenishment in progress. This could take a couple minutes."
              />
              <IonLoading
                isOpen={scanInProgress}
                message="Scan in progress..."
              />
              {!isBranch ? (
                <WarningMessage
                  className={classes.warningMessage}
                  icon={['far', 'info-circle']}
                  title="You must be at a branch level to use this feature."
                />
              ) : (
                <List
                  title={{
                    customContent: (
                      <IonRow className={classes.listHeader}>
                        <Text text="POU" variant="content-heavy" />
                        <Text text="CSN" variant="content-heavy" />
                        <Text
                          className={classes.quantityLabel}
                          text="QTY"
                          variant="content-heavy"
                        />
                      </IonRow>
                    ),
                  }}
                  data={values.items}
                  itemContent={(itemIndex, item) => (
                    <ReplenishmentItem
                      key={`${toString(item.id)}-${itemIndex}`}
                      item={item}
                      index={itemIndex}
                      disabled={uploadIsLoading}
                    />
                  )}
                  scrollParent={node}
                  hideKeyboardOnScroll={false}
                  isLoading={{
                    isLoading: itemsIsLoading && isEmpty(values.items),
                    text: 'Loading replenishment items',
                  }}
                  isEmptyList={{
                    isEmptyList: isEmpty(values.items),
                    title: 'No items ready for upload',
                  }}
                  isError={{
                    isError: !!error,
                    title: 'Error loading replenishment items',
                    body: getErrorMessage(error),
                  }}
                  testid="replenishment-list"
                />
              )}
            </IonContent>
            <IonFooter className={classes.footer}>
              <IonRow>
                <Button
                  variant="action"
                  onClick={() => handleSubmit()}
                  icon={findIcon('upload')}
                  text={`Upload Items${itemsSize > 0 ? ` (${itemsSize})` : ''}`}
                  disabled={
                    !ac || uploadIsLoading || itemsSize === 0 || !isOnline
                  }
                  testid="upload-replenishment-button"
                />
              </IonRow>
            </IonFooter>
          </>
        );
      }}
    </Formik>
  );
};

export default ReplenishmentsList;
