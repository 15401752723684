import { toString } from 'lodash';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator';
import IMask from 'imask';
import type { Address } from 'models/Address';

export const getAddress = (address?: Address, separator = ''): string => {
  const lineSeparator = separator ? `${separator} ` : '\n';

  const line1 = toString(address?.addrLine1);
  const line2 = toString(address?.addrLine2);
  const line3 = toString(address?.addrLine3);
  const city = toString(address?.city);
  const state = toString(address?.state);
  const zip = toString(address?.zip);
  const country = toString(address?.country);

  const cityLine = `${city}, `;
  const location = `${city ? cityLine : ''}${state ? `${state} ` : ''}${
    zip ? `${zip} ` : ''
  }${country ? `${country} ` : ''}`;

  return `${line1 ? `${line1}${lineSeparator}` : ''}${
    line2 ? `${line2}${lineSeparator}` : ''
  }${line3 ? `${line3}${lineSeparator}` : ''}${location}`;
};

export const getMapLocation = (address?: Address): string => {
  const line1 = toString(address?.addrLine1);
  const line2 = toString(address?.addrLine2);
  const line3 = toString(address?.addrLine3);
  const city = toString(address?.city);
  const state = toString(address?.state);
  const zip = toString(address?.zip);
  const country = toString(address?.country);
  let respMapAddr = '';
  const line = line2 || line1;
  switch (country) {
    case 'US':
    case 'CA':
      respMapAddr = `${line} ${city} ${state} ${zip}`;
      break;
    default:
      respMapAddr = `${line} ${line3} ${zip} ${city} ${state}`;
      break;
  }
  return respMapAddr;
};

export const openNavigator = (mapLocation: string): void => {
  void LaunchNavigator.navigate(mapLocation, {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    app: LaunchNavigator.APP.USER_SELECT,
  });
};

export const formatPhone = (phone?: string): string => {
  if (phone && phone.length < 10) {
    return phone;
  }
  const masked = IMask.createMask({
    mask: '(000) 000-0000',
  });
  masked.resolve(toString(phone));
  return masked.value;
};
