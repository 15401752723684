import { useSelector } from 'react-redux';
import type { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { findProductsQueryKey } from 'CostSavingsApp/api/useFindProducts';
import { findProductOpportunitiesQueryKey } from 'ProductSearchApp/api/useFindProductOpportunties';
import { getOrderQueryKey } from 'ProductSearchApp/api/useGetOrder';
import type { CartOrderItem } from 'ProductSearchApp/models/Order';
import type { Product } from 'ProductSearchApp/models/Products';
import { getProductFromOcnLine } from 'ProductSearchApp/util/ocnHelpers';
import { isCustomerLess } from 'ProductSearchApp/util/productSearchUtil';
import { useAxios } from 'providers/AxiosProvider';
import {
  doGetIsLoading,
  doPromiseAPI,
  getPlaceholderData,
  useKeyUserId,
} from 'api/helpers';
import type { Customer } from 'models/Customer';
import type { QueryFlags } from 'models/Search';
import type { RootState } from 'store/reducers';
import useGetCustomer from './useGetCustomer';

const getProductQueryKey = 'product-details-v2';

interface UseGetProductProps {
  miLoc: string;
  id: string;
  productId: string;
}

interface UseGetProductResponse {
  productData?: Product;
  customerData?: Customer;
}

const useGetProductDetail = ({
  miLoc,
  id,
  productId,
}: UseGetProductProps): UseGetProductResponse & QueryFlags => {
  const queryClient = useQueryClient();
  const { axios } = useAxios();
  const { getProductAPIv2 } = useAPIUrl();
  const { createQueryKey } = useKeyUserId();
  const enabled = !isEmpty(miLoc) && !isEmpty(id) && !isEmpty(productId);
  const { currentCartCustomer } = useSelector((state: RootState) => state.user);

  const doGetRecentProductDetail = () => {
    return doPromiseAPI<Product>(async (cancelToken) => {
      const { data } = await axios.get<Product>(
        getProductAPIv2(miLoc, id, productId),
        { cancelToken }
      );
      return data;
    });
  };

  const response = useQuery<Product, AxiosError>(
    createQueryKey(getProductQueryKey, { miLoc, id, productId }),
    doGetRecentProductDetail,
    {
      enabled,
      placeholderData: () => {
        const searchProduct = getPlaceholderData<Product>({
          queryClient,
          queryKey: findProductsQueryKey,
          findPredicate: { itemNumber: productId },
          objectKey: 'products',
        });
        if (searchProduct) {
          return searchProduct;
        }
        const opportunityProduct = getPlaceholderData<Product>({
          queryClient,
          queryKey: findProductOpportunitiesQueryKey,
          findPredicate: { itemNumber: productId },
          objectKey: 'items',
        });
        if (opportunityProduct) {
          return opportunityProduct;
        }
        const currentCartProduct = getPlaceholderData<CartOrderItem>({
          queryClient,
          queryKey: getOrderQueryKey,
          queryKeyParams: {
            miLoc: currentCartCustomer?.miLoc,
            orderCtlNo: currentCartCustomer?.orderCtlNo,
          },
          findPredicate: { itemNo: productId },
          objectKey: 'items',
        });
        if (currentCartProduct) {
          // DOC should remove pricing data so it doesn't display overrides from cache
          return getProductFromOcnLine(currentCartProduct, true);
        }
        const ocnProduct = getPlaceholderData<CartOrderItem>({
          queryClient,
          queryKey: getOrderQueryKey,
          queryKeyParams: { miLoc },
          findPredicate: { itemNo: productId },
          objectKey: 'items',
        });
        if (ocnProduct) {
          return getProductFromOcnLine(ocnProduct, true);
        }
        return undefined;
      },
    }
  );

  const { data, error } = response;

  const { data: customerData } = useGetCustomer({
    searchType: 'customer',
    miLoc,
    id,
    enabled: !isCustomerLess(id),
  });

  return {
    error,
    productData: data,
    customerData,
    isLoading: doGetIsLoading(response),
  };
};

export default useGetProductDetail;
