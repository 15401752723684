import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import type { SortingRule } from 'react-table';
import {
  map,
  toString,
  type Dictionary,
  find,
  debounce,
  head,
  isEmpty,
  includes,
  isNil,
  size,
  split,
  filter,
} from 'lodash';
import {
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  useIonViewDidEnter,
} from '@ionic/react';
import Header from 'common/components/Header/Header';
import HelpButton from 'common/components/HelpButton/HelpButton';
import { or } from 'common/utils/logicHelpers';
import { fromUnixTime, isToday } from 'date-fns';
import { reportsDrillDownURL, reportsURL } from 'navigation';
import {
  TabPick12ReportKey,
  useReportsConfig,
} from 'providers/ReportsProvider';
import useGetPick12Dashboard from 'ReportsApp/api/useGetPick12Dashboard';
import ReportHelper from 'ReportsApp/components/ReportHelper/ReportHelper';
import { useGetReportTableData } from 'ReportsApp/helpers/reportHelpers';
import useGetGroupBy from 'ReportsApp/hooks/useGetGroupBy';
import useReportHeader from 'ReportsApp/hooks/useReportHeader';
import { type BaseReportURLParams } from 'ReportsApp/models';
import { useDebounce } from 'use-debounce';
import useGetCalendarDays from 'api/salesReports/useGetCalendarDays';
import { RoleGroupEnum } from 'models/Reports';
import type { SortOption } from 'models/Sort';
import { SortDirEnum } from 'models/Sort';
import {
  getBusinessDays,
  getHolidays,
  useGetSharedBusinessDayLabel,
} from 'utils/date';
import { concatRoutes } from 'utils/navigations';
import {
  locationSortOptions,
  nameSortOptions,
} from 'pages/Reports/DrillDown/sortOptions';
import Button from 'components/Button/Button';
import DateToolbar from 'components/DateToolbar/DateToolbar';
import DropDown from 'components/DropDown/DropDown';
import type { FilterOption } from 'components/Filter/Filter';
import Refresher from 'components/Refresher/Refresher';
import Text from 'components/Text/Text';
import classes from './Pick12Report.module.scss';

const GROUP_BY_FOR_LOCATION_SORT = ['EXEC', 'CORP', 'GRP', 'DIV', 'BRCH'];

const Pick12Report = ({
  reportType,
}: {
  reportType: 'pick12' | 'locationPick12';
}): JSX.Element => {
  const { t } = useTranslation('ReportApp-Pick12Report');
  const history = useHistory();

  const { orgType = '', rowId: routeRowId = '' } =
    useParams<BaseReportURLParams>();

  const [rowId] = split(routeRowId, '-');

  const { data: calendar } = useGetCalendarDays({});
  const businessDays = useMemo(() => getBusinessDays(calendar), [calendar]);
  const holidays = useMemo(() => getHolidays(calendar), [calendar]);
  const pickTwelve = reportType === 'pick12';
  const locationPick12 = reportType === 'locationPick12';

  const {
    requestType,
    busPeriod: configBusPeriod,
    sortField,
    sortDir,
    updateRequestType,
    updateBusPeriod,
    updateSortField,
    updateSortDir,
  } = useReportsConfig({ key: TabPick12ReportKey });

  const [debouncedUpdateBusPeriod, setDebouncedUpdateBusPeriod] =
    useState(false);
  const [debouncedBusPeriod] = useDebounce(configBusPeriod, 1000);
  const busPeriod = debouncedUpdateBusPeriod
    ? debouncedBusPeriod
    : configBusPeriod;

  const [canChangeTab, setCanChangeTab] = useState(false);
  const [isOpenHeaderMenu, setIsOpenHeaderMenu] = useState(false);

  useIonViewDidEnter(() => {
    setCanChangeTab(true);
  });

  const Pick12ColumnAccessByGroup = [
    RoleGroupEnum[RoleGroupEnum.CUST],
    RoleGroupEnum[RoleGroupEnum.PRD_GRP_01],
    RoleGroupEnum[RoleGroupEnum.PRD_GRP_02],
  ];

  // #region group and sort
  const { miLoc, userRole, groupByOptions, selectedGroupBy, setGroupByData } =
    useGetGroupBy(pickTwelve ? 'pick12' : 'locationPick12');
  const groupByDataKey = selectedGroupBy.key;
  const showGroupBy = size(groupByOptions) > 1;
  const today = isToday(fromUnixTime(busPeriod));

  const reportFields = useMemo(
    () => [
      {
        key: 'sales',
        type: 'currency',
        id: 'currentSales',
      },
      {
        key: 'gp%',
        type: 'percentage',
        id: 'currentGpPercent',
      },
      {
        key: 'gp',
        type: 'currency',
        id: 'currentGp',
      },
      {
        key: 'pick12Count',
        type: 'number',
        scale: 0,
        id: 'customerPick12ActiveCount',
        hidden: includes(Pick12ColumnAccessByGroup, selectedGroupBy.key),
      },
      {
        key: 'totalPick12Count',
        type: 'number',
        scale: 0,
        id: 'customerPick12TotalCount',
        hidden: includes(Pick12ColumnAccessByGroup, selectedGroupBy.key),
      },
      {
        key: 'pick12%',
        type: 'percentage',
        id: 'customerPick12ActivePercent',
        hidden: includes(Pick12ColumnAccessByGroup, selectedGroupBy.key),
      },
      {
        key: 'avgSalesPerAcct',
        type: 'currency',
        id: 'customerPick12AvgSalesPerAcct',
        hidden: includes(Pick12ColumnAccessByGroup, selectedGroupBy.key),
      },
      {
        key: 'prevYearSales',
        type: 'currency',
        id: 'previousSalesToCurrentBusDay',
        hidden: !today,
      },
      {
        key: 'yoyGrowth',
        type: 'percentage',
        id: 'currentSalesChangeToCurrentBusDay',
        hidden: !today,
      },
      {
        key: 'effectiveDate',
        type: 'date',
        id: 'customerPick12EffectiveDate',
      },
      {
        key: 'expirationDate',
        type: 'date',
        id: 'customerPick12ExpirationDate',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedGroupBy.key, today]
  );

  const onGroupBy = (value: string) => {
    if (canChangeTab) {
      setGroupByData((prev) => ({
        ...prev,
        [userRole]: find(groupByOptions, (group) => group.key === value),
      }));
    }
  };

  const sortDirOptions = useCallback(
    (fieldSelected?: FilterOption) =>
      [
        {
          key: SortDirEnum.ASCENDING,
          name: t(
            fieldSelected?.type === 'string'
              ? 'sort:alphaAscending'
              : 'sort:numberDescending'
          ),
          icon: 'arrow-up',
        },
        {
          key: SortDirEnum.DESCENDING,
          name: t(
            fieldSelected?.type === 'string'
              ? 'sort:alphaDescending'
              : 'sort:numberAscending'
          ),
          icon: 'arrow-down',
        },
      ] as FilterOption[],
    [t]
  );

  const sortFieldSelected = useMemo(() => {
    const showLocationSortOptions = includes(
      GROUP_BY_FOR_LOCATION_SORT,
      groupByDataKey
    );
    const sortFieldOptions = [
      ...(showLocationSortOptions ? locationSortOptions : nameSortOptions),
      ...map(reportFields, (field) => ({
        key: field.id,
        type: field.type,
      })),
    ] as FilterOption[];
    let selectedSort = find(sortFieldOptions, {
      key: sortField,
    });
    selectedSort = or(selectedSort, head(sortFieldOptions));
    return selectedSort;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupByDataKey, busPeriod, requestType, sortField]);

  const sortDirSelected = useMemo(
    () =>
      or(
        find(sortDirOptions(sortFieldSelected), { key: sortDir }),
        head(sortDirOptions(sortFieldSelected))
      ),
    [sortDirOptions, sortFieldSelected, sortDir]
  );

  const onSortBy = debounce(
    (sortOption: SortingRule<Dictionary<unknown>>[]) => {
      updateSortField?.(toString(head(sortOption)?.id));
      if (!isEmpty(sortOption)) {
        updateSortDir?.(
          head(sortOption)?.desc
            ? SortDirEnum.DESCENDING
            : SortDirEnum.ASCENDING
        );
      }
    },
    300
  );
  // #endregion group and sort

  const {
    rows,
    datesWithSameBusDay,
    error,
    refetch,
    fetchNextPage,
    isLoading: drilldownIsLoading,
    isEmptyResponse,
    summary,
    dataUpdatedAt,
    noMoreData,
    // eslint-disable-next-line no-nested-ternary
  } = useGetPick12Dashboard({
    miLoc,
    customerId: includes(['CUST', 'PRD_GRP_01', 'PRD_GRP_02'], orgType)
      ? rowId
      : undefined,
    territory: orgType === 'REP' ? rowId : undefined,
    busPeriod,
    requestType,
    groupBy: groupByDataKey,
    sortField: sortFieldSelected.key,
    sortDir: sortDirSelected.key as SortDirEnum,
    isPick12Report: pickTwelve,
    isLocationPick12Report: locationPick12,
  });

  const sharedBusinessDayLabel = useGetSharedBusinessDayLabel(
    busPeriod,
    datesWithSameBusDay
  );

  const { tableData, tableColumns, totalsRow } = useGetReportTableData({
    drilldownData: rows,
    summaryData: summary,
    reportFields,
    selectedGroupBy,
    setCanChangeTab,
    setGroupByData,
    pick12: pickTwelve,
    reportType,
    namespace: 'ReportApp-Pick12Report:columns',
  });

  const reportHelperProps = {
    sharedBusinessDayLabel,
    showGroupBy,
    groupByOptions,
    selectedGroupBy,
    onGroupBy,
    onSortBy,
    fetchNextPage,
    error,
    reportName: t('pick12'),
    isEmptyResponse,
    requestType,
    tableData,
    tableColumns,
    totalsRow,
    sortFieldSelected,
    sortDir,
    isLoading: drilldownIsLoading,
    noMoreData,
  };
  // #region fixed header
  const { title, reportHeader } = useReportHeader();
  // #endregion fixed header

  const options: SortOption[] = useMemo(() => {
    const result: SortOption[] = [
      {
        key: 'pick-12-leader-board',
        name: t('pick12LeaderBoard'),
        icon: ['far', 'trophy'],
      },
      {
        key: 'view-as-pdf',
        name: t('viewAsPDF'),
        icon: ['far', 'file-spreadsheet'],
        hidden: true,
      },
      {
        key: 'share-in-email',
        name: t('shareInEmail'),
        icon: ['far', 'link'],
        hidden: true,
      },
    ];
    return result;
  }, [t]);

  const toggleMenuHeader = () => {
    setIsOpenHeaderMenu(!isOpenHeaderMenu);
  };

  const helpModalBody = (
    <div>
      <Text text={t('pick12ModalTextLine1')} />
      <ul>
        <li>
          <Text text={t('pick12ModalTextLine2')} />
        </li>
        <li>
          <Text text={t('pick12ModalTextLine3')} />
        </li>
        <li>
          <Text text={t('pick12ModalTextLine4')} />
        </li>
      </ul>
    </div>
  );

  const customPageTitle = (
    <IonRow>
      <Text
        variant="mipro-h1-headline"
        text={t('pick12ReportLabel')}
        testid="pageTitle"
      />
      <HelpButton
        modal={{
          initialBreakpoint: 0.75,
          children: helpModalBody,
          testid: 'pick12-report',
          title: t('pick12Reporting'),
        }}
        button={{
          iconClassName: classes.infoIcon,
          testid: 'pick12-report',
        }}
      />
    </IonRow>
  );

  return (
    <IonPage data-testid="pick-12-report-page">
      <Header
        testid="pick-12--page-header"
        className={classes.pageHeader}
        title={t('pick12ReportLabel')}
        subTitle={title}
      >
        <DateToolbar
          className={classes.datetoolbar}
          testid="pick-12-report"
          busPeriod={configBusPeriod}
          requestType={requestType}
          updateBusPeriod={(date, waitFor) => {
            if (!isNil(waitFor)) {
              setDebouncedUpdateBusPeriod(waitFor);
            }
            updateBusPeriod(date);
          }}
          updateRequestType={updateRequestType}
          businessDays={businessDays}
          holidays={holidays}
          reportKey={TabPick12ReportKey}
        />
      </Header>
      <IonContent>
        <Refresher
          slot="fixed"
          lastUpdatedAt={dataUpdatedAt}
          onRefresh={refetch}
          hidden={requestType !== 'DAILY'}
          disabled={drilldownIsLoading}
          testid="pick-12-report"
        />
        <Header
          collapse="condense"
          testid="pick-12-header"
          className={classes.header}
          customTitle={
            <>
              {customPageTitle}
              <div className={classes.wrapper}>{reportHeader}</div>
            </>
          }
          endSlotComponent={
            <IonCol slot="end" className={classes.pick12Dots}>
              <Button
                variant="icon-action"
                icon={['far', 'ellipsis-h']}
                testid="pick12-dots-button"
                id={`${orgType}-${rowId}-pick12-menu-button`}
              />
              <DropDown
                trigger={`${orgType}-${rowId}-pick12-menu-button`}
                dismissOnSelect
                className={classes.pick12Menu}
                alignment="start"
                side="start"
                onClick={toggleMenuHeader}
                iconsLeft
                testid="requesttype-dropdown"
                filterOptions={filter(options, (option) => !option.hidden)}
                selectedItem={requestType}
                onOptionClick={() => {
                  history.push(
                    concatRoutes(
                      reportsURL(),
                      reportsDrillDownURL('pick12LeaderBoard', '', '', '', '')
                    )
                  );
                }}
                id={`${orgType}-${rowId}-pick12-menu-button`}
              />
            </IonCol>
          }
        />
        {
          // eslint-disable-next-line react/jsx-props-no-spreading
          <ReportHelper {...reportHelperProps} />
        }
      </IonContent>
    </IonPage>
  );
};

export default Pick12Report;
