import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import CustomerSelect from 'CostSavingsApp/components/FastFind/CustomerSelect';
import { useToasts } from 'providers/ToastProvider';
import type { SelectModalItem } from 'models/Search';
import { ToastType } from 'models/Toast';
import NavigationRow, {
  NavigationRowVariants,
} from 'components/NavigationRow/NavigationRow';
import classes from './SelectCustomerBar.module.scss';

interface SelectCustomerBarProps {
  testid: string;
  isCustomerLessSearch: boolean;
  selCustomer?: SelectModalItem;
  setCustomerInfo: (o: SelectModalItem) => void;
  triggerCustomerSelect?: number;
}

const SelectCustomerBar = ({
  testid,
  isCustomerLessSearch,
  selCustomer,
  setCustomerInfo,
  triggerCustomerSelect,
}: SelectCustomerBarProps) => {
  const { t } = useTranslation('ProductSearchApp-Search');
  const { addToast } = useToasts();
  const [triggerCustomerInput, setTriggerCustomerInput] = useState(0);

  useEffect(() => {
    if (toNumber(triggerCustomerSelect) > 0) {
      setTriggerCustomerInput(Date.now());
    }
  }, [triggerCustomerSelect]);

  return isCustomerLessSearch ? (
    <>
      <NavigationRow
        className={classes.selCustBar}
        variant={NavigationRowVariants.banner}
        onClick={() => setTriggerCustomerInput(Date.now())}
        text={{
          text: t('selectCust'),
          variant: 'title-action-card',
          className: classes.banner,
        }}
        testid={`${testid}-bar`}
      />
      <CustomerSelect
        className={classes.lineHeading}
        label={t('selectCustomerLabel')}
        placeholder={t('selectCustomer')}
        iconClassName={classes.magnifier}
        setCustomerInfo={(o) => {
          setCustomerInfo(o);
          setTriggerCustomerInput(0);
          addToast({
            variant: 'mipro-toast',
            className: classes.selectedToast,
            type: ToastType.success,
            leftIcon: ['fas', 'check-circle'],
            header: t('changeCustomerTitle', { name: o.title }),
            text: t('changeCustomerText'),
            testid: 'change-customer-toast',
          });
        }}
        triggerInput={triggerCustomerInput}
        showTabOptions={false}
        selItem={selCustomer}
        testid={`cust-${testid}`}
        showInput={false}
      />
    </>
  ) : null;
};

export default SelectCustomerBar;
