import React from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import { IonItem } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import Button from 'components/Button/Button';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import classes from './HeaderActions.module.scss';

interface HeaderActionsProps {
  title?: string;
  disabled?: boolean;
  options?: Array<
    React.ComponentProps<typeof Button> & { autoClose?: boolean }
  >;
}

const HeaderActions = ({
  title,
  isOpen,
  setIsOpen,
  options,
  initialBreakpoint,
  disabled,
  className,
  testid,
}: HeaderActionsProps &
  IonicReactProps &
  Omit<React.ComponentProps<typeof SheetModal>, 'title'>): JSX.Element => {
  return (
    <div className={className} data-testid={testid}>
      <Button
        className={classes.button}
        variant="icon-action"
        icon={['far', 'ellipsis-h']}
        onClick={() => setIsOpen?.(true)}
        disabled={disabled}
        testid="actions-button"
      />
      <SheetModal
        title={title}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        initialBreakpoint={initialBreakpoint}
        testid="actions-modal"
        withRightCloseButton
      >
        <div className={classes.content}>
          {map(
            options,
            ({
              text,
              icon,
              disabled: buttonDisabled,
              href,
              onClick,
              testid: buttonTestId,
              children,
              className: btnClassName,
              autoClose = true,
            }) => (
              <IonItem
                key={`${buttonTestId}-item`}
                className={classes.buttonWrapper}
              >
                <Button
                  icon={icon}
                  className={classNames(classes.modalButton, btnClassName)}
                  disabled={buttonDisabled}
                  href={href}
                  onClick={(e) => {
                    if (autoClose) {
                      setIsOpen?.(false);
                    }
                    onClick?.(e);
                  }}
                  testid={buttonTestId}
                  // TODO: should check hardcoded slots in app
                  slot="start"
                  text={text}
                  variant="mipro-text-button"
                >
                  {children}
                </Button>
              </IonItem>
            )
          )}
        </div>
      </SheetModal>
    </div>
  );
};
export default HeaderActions;
