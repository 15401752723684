import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { find, includes, isEmpty, toNumber, toString } from 'lodash';
import { IonCol, IonContent, IonPage } from '@ionic/react';
import Header from 'common/components/Header/Header';
import {
  and,
  choose,
  ifFunction,
  ifRender,
  or,
} from 'common/utils/logicHelpers';
import { isAccountRep } from 'common/utils/userInfo';
import useGetAvailableCSHLocations from 'CostSavingsApp/hooks/useGetAvailableCSHLocations';
import { costSavingsURL, searchURL } from 'navigation';
import { TabCostSavingsReportKey } from 'providers/ReportsProvider';
import type { CostSavingsReportRow } from 'ReportsApp/api/useGetCostSavingsReport';
import useGetCostSavingsReport from 'ReportsApp/api/useGetCostSavingsReport';
import GroupBy from 'ReportsApp/components/GroupBy/GroupBy';
import ReportError from 'ReportsApp/components/ReportError/ReportError';
import useGetFixedHeader from 'ReportsApp/hooks/useGetFixedHeader';
import useGetGroupBy from 'ReportsApp/hooks/useGetGroupBy';
import useGetSortBy from 'ReportsApp/hooks/useGetSortBy';
import useReportHeader from 'ReportsApp/hooks/useReportHeader';
import type { BaseReportURLParams } from 'ReportsApp/models';
import { goToCostSavingsReport } from 'ReportsApp/navigation/navigationHelpers';
import { useGetSelectedMiLoc } from 'api/helpers';
import useAccessControls, { AccessControlType } from 'hooks/useAccessControls';
import type { ViewAsRoleType } from 'models/Reports';
import { RoleGroupEnum } from 'models/Reports';
import type { SortOption, SortDirEnum } from 'models/Sort';
import type { RootState } from 'store/reducers';
import { concatRoutes } from 'utils/navigations';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import DropDown from 'components/DropDown/DropDown';
import type { FilterOption } from 'components/Filter/Filter';
import Filter from 'components/Filter/Filter';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import Refresher from 'components/Refresher/Refresher';
import Table from 'components/Table/Table';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './CostSavings.module.scss';
import { useGetCostSavingsTableData } from './costSavingsHelpers';

const CostSavings = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { miLoc = '', orgType } = useParams<BaseReportURLParams>();
  const { title, reportHeader } = useReportHeader();
  const {
    userInfo,
    isCamUser,
    locationTree = {},
  } = useSelector((state: RootState) => state.user);
  const canCostSavingsEntry = useAccessControls(
    AccessControlType.AddCostSavingsAccessControls
  );
  const { sortDir, onSortBy, sortField, updateSortField } = useGetSortBy(
    TabCostSavingsReportKey
  );

  const { isSingleCSHExecLocation, isExecSelected, filterOptions } =
    useGetAvailableCSHLocations();
  const selectExecMiloc = filterOptions.find(
    (item) => item.key === location.state
  ) as SortOption;

  const [selectedCSHExecLocation, setSelectedCSHExecLocation] =
    useState<SortOption>(or(selectExecMiloc, filterOptions[0]));
  const showExecLocationDropdown = and(
    isExecSelected,
    !isSingleCSHExecLocation,
    isEmpty(orgType)
  );

  useEffect(() => {
    if (!isEmpty(selectExecMiloc)) {
      setSelectedCSHExecLocation(selectExecMiloc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const { fromVirtualTeam } = useGetSelectedMiLoc(miLoc);

  // region group by
  const {
    miLoc: stateMiLoc,
    groupByOptions,
    selectedGroupBy,
    setGroupByData,
    userRole,
    showGroupBy,
  } = useGetGroupBy('costSavings');

  const onGroupBy = (value: string) => {
    setGroupByData((prev) => ({
      ...prev,
      [userRole]: find(groupByOptions, (group) => group.key === value),
    }));
    ifFunction(
      and(
        or(value === 'GRP', value === 'BRCH', value === 'DIV'),
        sortField === 'name'
      ),
      () => {
        updateSortField?.('miLoc');
      }
    );

    ifFunction(and(value === 'CUST', sortField === 'miLoc'), () => {
      updateSortField?.('name');
    });
  };

  const isGroupByCustomer = selectedGroupBy.key === 'CUST';

  // region report fields
  const reportFields = useMemo(() => {
    const CostSavingsColumnAccessByGroup = [RoleGroupEnum[RoleGroupEnum.CUST]];

    return [
      {
        key: 'shortfall',
        type: 'currency',
        id: 'overage',
      },
      {
        key: 'sales',
        type: 'currency',
        id: 'sales',
      },
      {
        key: 'commit',
        type: 'currency',
        id: 'commitCurrentSales',
      },
      {
        key: 'commit%',
        type: 'percentage',
        id: 'commitPercentage',
        hidden: !includes(CostSavingsColumnAccessByGroup, selectedGroupBy.key),
      },
      {
        key: 'projected',
        type: 'currency',
        id: 'projectedSales',
      },
      {
        key: 'approved',
        type: 'currency',
        id: 'approvedSales',
      },
      {
        key: 'pending',
        type: 'currency',
        id: 'pendingSales',
      },
      {
        key: 'guranteedPaid',
        id: 'guaranteed',
        type: 'text',
        hidden: !includes(CostSavingsColumnAccessByGroup, selectedGroupBy.key),
      },
    ];
  }, [selectedGroupBy.key]);

  // region filters
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [costSavingsAgreementOption, setCostSavingsAgreementOption] =
    useState<FilterOption>();
  const [costSavingsShowByOption, setCostSavingsShowByOption] =
    useState<FilterOption>();
  const showByOptions = {
    title: t('showBy'),
    options: [
      { key: 'CORP', name: t('reports:corporateAccount') },
      { key: 'BILLTO', name: t('reports:billTo') },
      { key: 'CUST', name: t('reports:shipTo') },
    ],
    hidden: !isGroupByCustomer,
  };
  const aggreementOptions = {
    title: t('reports:agreementType'),
    options: [
      { key: 'ALL', name: t('reports:all') },
      { key: 'Y', name: t('reports:guaranteedYes') },
      { key: 'N', name: t('reports:guaranteedNo') },
    ],
  };
  let defaultSelectedShowByData = choose(
    isAccountRep(userInfo?.jobCode),
    showByOptions.options[2],
    showByOptions.options[1]
  );

  if (isCamUser) {
    [defaultSelectedShowByData] = showByOptions.options;
  }

  const selectedShowByData = or(
    costSavingsShowByOption,
    defaultSelectedShowByData
  ) as FilterOption;
  const selectedAgreementData: FilterOption = or(
    costSavingsAgreementOption,
    aggreementOptions.options[0]
  ) as FilterOption;

  let defaultFilter = and(
    or(
      choose(
        isAccountRep(userInfo?.jobCode),
        selectedShowByData.key === showByOptions.options[2].key,
        selectedShowByData.key === showByOptions.options[1].key
      ),
      !isGroupByCustomer
    ),
    selectedAgreementData.key === aggreementOptions.options[0].key
  );

  if (and(isCamUser, isGroupByCustomer)) {
    defaultFilter = selectedShowByData.key === showByOptions.options[0].key;
  }

  let showByParam = costSavingsShowByOption?.key;

  ifFunction(and(isCamUser, isEmpty(showByParam)), () => {
    showByParam = defaultSelectedShowByData?.key;
  });

  const showByCount = choose(
    and(
      defaultSelectedShowByData?.key !== selectedShowByData.key,
      isGroupByCustomer
    ),
    1,
    0
  );
  const agreementTypeCount = choose(selectedAgreementData.key === 'ALL', 0, 1);
  const filterCount = toNumber(showByCount) + toNumber(agreementTypeCount);
  const filterButton = (
    <Filter
      className={classes.filter}
      selectedItems={[selectedShowByData, selectedAgreementData]}
      setFilterData={[
        (option) => {
          setCostSavingsShowByOption(option);
        },
        (option) => {
          setCostSavingsAgreementOption(option);
        },
      ]}
      filterOptions={[showByOptions, aggreementOptions]}
      isOpen={isFilterOpen}
      setIsOpen={setIsFilterOpen}
      testid="cost-savings-filter"
      variant="sort"
      modalTitle={t('showByFilter')}
      customButton={{
        className: classNames(classes.filterButton, {
          [classes.filterNone]: defaultFilter,
          [classes.hasFilters]: !defaultFilter,
        }),
        text: choose(defaultFilter, t('common:filter'), toString(filterCount)),
        testid: 'cost-saving-filter-button',
        children: undefined,
        rightIcon: undefined,
        icon: ['far', 'sliders-up'],
      }}
    />
  );

  // region Cost Savings View role

  const role = or(locationTree[stateMiLoc]?.userRole, 'EXEC');

  let costSavingsViewRole: ViewAsRoleType;
  if (isAccountRep(userInfo?.jobCode)) {
    costSavingsViewRole = or(
      costSavingsShowByOption?.key,
      'CUST'
    ) as ViewAsRoleType;
  } else if (or(role === 'TEAM', isGroupByCustomer)) {
    costSavingsViewRole = or(
      costSavingsShowByOption?.key,
      'BILLTO'
    ) as ViewAsRoleType;
    if (isCamUser) {
      costSavingsViewRole = or(
        costSavingsShowByOption?.key,
        'CORP'
      ) as ViewAsRoleType;
    }
  } else {
    costSavingsViewRole = 'LOC';
  }

  let groupByParam = selectedGroupBy.key;

  ifFunction(isGroupByCustomer, () => {
    groupByParam = 'BRCH';
  });

  // region API Call

  const {
    rows,
    totals,
    dataUpdatedAt,
    isLoading,
    error,
    noMoreData,
    refetch,
    fetchNextPage,
    isEmptyResponse,
    currencyType,
  } = useGetCostSavingsReport({
    miLoc: toString(
      choose(
        and(isCamUser, isEmpty(orgType)),
        selectedCSHExecLocation?.key,
        toString(or(stateMiLoc, miLoc))
      )
    ),
    groupBy: groupByParam,
    enabled: true,
    guaranteed: choose(
      costSavingsAgreementOption?.key === 'ALL',
      undefined,
      costSavingsAgreementOption?.key
    ),
    showBy: showByParam,
    sortField,
    viewAsRole: costSavingsViewRole,
    sortDir: sortDir as SortDirEnum,
  });

  let errorContent;

  if (and(isEmptyResponse, !isLoading)) {
    errorContent = (
      <WarningMessage
        className={classes.warningMessage}
        icon={['far', 'info-circle']}
        title={t('reports:noReports')}
        testid="empty-response"
      />
    );
  }

  if (error) {
    errorContent = (
      <ReportError
        className={classes.warningMessage}
        error={error}
        reportName={t('costSavings')}
        testid="report-error"
      />
    );
  }

  // region get table data

  const { tableData, tableColumns, totalsRow } = useGetCostSavingsTableData({
    drilldownData: rows,
    summaryData: totals as CostSavingsReportRow,
    reportFields,
    selectedGroupBy,
    currencyType,
    showBy: or(showByParam, defaultSelectedShowByData?.key),
  });

  // #region fixed header
  const { headerRef, headerTop } = useGetFixedHeader({ tableData });

  return (
    <IonPage data-testid="cost-savings-page">
      <Header
        testid="cost-savings-page-header"
        title={t('reports:costSavingsReport')}
        className={classes.pageHeader}
        subTitle={choose(
          showExecLocationDropdown,
          `${selectedCSHExecLocation?.key}: ${toString(title)}`,
          title
        )}
        endSlotComponent={filterButton}
      >
        {ifRender(
          canCostSavingsEntry,
          <ActionRow
            withArrow={false}
            testid="create-cost-savings"
            className={classes.actionRowLink}
            href={concatRoutes(searchURL(), costSavingsURL('', '', true))}
          >
            <Button
              variant="secondary"
              text={t('costSavings:costSavings')}
              icon={['far', 'piggy-bank']}
              testid="cost-savings-button"
              className={classes.linkButton}
            />
          </ActionRow>
        )}
      </Header>
      <IonContent>
        <Refresher
          slot="fixed"
          lastUpdatedAt={dataUpdatedAt}
          onRefresh={refetch}
          disabled={isLoading}
          testid="cost-savings"
          hidden
        />
        <Header
          testid="cost-savings-header"
          collapse="condense"
          pageTitle={t('reports:costSavingsReport')}
          className={classes.header}
          customTitle={
            <>
              {choose(
                or(and(stateMiLoc === 'EXEC', !isCamUser), fromVirtualTeam),
                <Text
                  text={choose(
                    fromVirtualTeam,
                    t('reports:myTeams'),
                    `AL00: ${t('common:motion')}`
                  )}
                  className={classes.wrapper}
                  variant="mipro-h3-headline"
                />,
                <div className={classes.wrapper}>{reportHeader}</div>
              )}
              {ifRender(
                showExecLocationDropdown,
                <div className={classes.dropdown}>
                  <Button
                    variant="link"
                    text={selectedCSHExecLocation?.name}
                    className={classes.dropdownButton}
                    rightIcon={['fas', 'caret-down']}
                    testid="request-type-button"
                    id="cost-savings-report-dropdown"
                  />
                  <DropDown
                    trigger="cost-savings-report-dropdown"
                    dismissOnSelect
                    side="bottom"
                    alignment="start"
                    testid="cost-savings-dropdown"
                    filterOptions={filterOptions}
                    selectedItem={selectedCSHExecLocation?.key}
                    onOptionClick={(option) => {
                      setSelectedCSHExecLocation(option);
                      history.push({
                        pathname: goToCostSavingsReport({}),
                        state: option.key,
                      });
                    }}
                    id="cost-savings-report-dropdown"
                  />
                </div>
              )}
            </>
          }
          endSlotComponent={
            <IonCol className={classes.filterCol}>{filterButton}</IonCol>
          }
        />
        <div ref={headerRef} className={classes.stickyHeader}>
          {ifRender(
            showGroupBy,
            <GroupBy
              groupByOptions={groupByOptions}
              selectedGroupBy={selectedGroupBy}
              onGroupBy={onGroupBy}
            />
          )}
        </div>
        {ifRender(
          !error,
          <Table
            className={classes.drilldownTable}
            thClassName={classes.tableTH}
            theadStyle={{ top: headerTop }}
            tdClassName={classNames(classes.tableTD, {
              [classes.link]: and(
                and(showByParam !== 'BILLTO', !isEmpty(showByParam)),
                isGroupByCustomer
              ),
            })}
            columns={tableColumns}
            data={tableData}
            totals={totalsRow}
            onSortBy={onSortBy}
            sortField={sortField}
            sortDir={sortDir}
          />
        )}

        <Loader
          className={classes.loader}
          text={t('reports:loadingReports')}
          isOpen={isLoading}
          testid="loader"
        />
        {errorContent}
        {ifRender(
          !noMoreData,
          <InfiniteScroll onLoadMore={fetchNextPage} testid="infinite-scroll" />
        )}
      </IonContent>
    </IonPage>
  );
};

export default CostSavings;
