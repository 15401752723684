import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow } from '@ionic/react';
import HelpButton from 'common/components/HelpButton/HelpButton';
import { and, choose, or } from 'common/utils/logicHelpers';
import { useGetAvpUser } from 'common/utils/userInfo';
import { namespaces } from 'i18n/i18n.constants';
import { reportsDrillDownURL, reportsURL } from 'navigation';
import {
  TabWebSalesReportKey,
  useReportsConfig,
  WebSalesHomeKey,
} from 'providers/ReportsProvider';
import useGetDigitalSalesDashboard from 'ReportsApp/api/useGetDigitalSalesReport';
import useGetCamUserDetails from 'ReportsApp/hooks/useGetCamUserDetails';
import { useGetSelectedMiLoc } from 'api/helpers';
import { useHasAccessControls } from 'hooks/useAccessControls';
import type { RootState } from 'store/reducers';
import { concatRoutes } from 'utils/navigations';
import { GetIncludeDailyTotalsMsg } from 'utils/reports';
import Text from 'components/Text/Text';
import type { DashboardLineProps } from './DashboardCard';
import DashboardCard from './DashboardCard';
import classes from './DashboardCard.module.scss';

const WebPerformanceCard = (): JSX.Element => {
  const { updateRequestType, updateBusPeriod, requestType, busPeriod } =
    useReportsConfig({ key: WebSalesHomeKey });
  const {
    showDigitalSalesReport,
    miLoc: stateMiLoc = '',
    locationTree,
  } = useSelector((state: RootState) => state.user);
  const stateLocation = locationTree?.[stateMiLoc];
  const { team, fromVirtualTeam, selectedMiLoc } = useGetSelectedMiLoc();
  const { hasAccessControl } = useHasAccessControls();
  const { includeDailyTotalMsg = '', includeDailyTotalInfo = '' } =
    GetIncludeDailyTotalsMsg({
      requestType,
      busPeriod,
    });
  const { accessControl, subTitle, isCamUser } = useGetCamUserDetails();
  const { isAvpUserWithExecView } = useGetAvpUser();

  const miLoc = choose(
    or(fromVirtualTeam, team),
    undefined,
    selectedMiLoc.miLoc
  );

  const {
    summaryData: homeWebData,
    error,
    showLoader,
  } = useGetDigitalSalesDashboard({
    miLoc,
    enabled: and(hasAccessControl(accessControl), !!showDigitalSalesReport),
    requestType,
    summaryOnly: true,
    busPeriod,
    digitalType: 'ALL',
  });

  const gpBasisPoints: number =
    homeWebData?.webPerformance?.vsOtherGPBasisPointsDiff || 0;

  const { t } = useTranslation(namespaces.reports);

  const webSaleUrl = concatRoutes(
    reportsURL(),
    reportsDrillDownURL('webSales', '', '', '', '')
  );

  const lines = useMemo(() => {
    const result: DashboardLineProps[] = [
      {
        text: t('totalDigitalSales'),
        value:
          find(homeWebData?.webPerformance?.sales, {
            Name: 'Digital Sales',
          })?.amount || 0,
        valueType: 'currency',
        currencyType: homeWebData?.currencyType,
        changePercentage:
          find(homeWebData?.webPerformance?.sales, {
            Name: 'Digital Sales',
          })?.change || 0,
        changeLabel: 'YOY',
      },
      {
        text: t('digitalPct'),
        value: homeWebData?.webPerformance?.digitalPercentOfTotalSales || 0,
        valueType: 'percentage',
      },
      {
        text: t('totalWebSales'),
        value:
          find(homeWebData?.webPerformance?.sales, {
            Name: 'Sales',
          })?.amount || 0,
        valueType: 'currency',
        currencyType: homeWebData?.currencyType,
        changePercentage:
          find(homeWebData?.webPerformance?.sales, {
            Name: 'Sales',
          })?.change || 0,
        changeLabel: 'YOY',
      },
      {
        text: t('webPct'),
        value: homeWebData?.webPerformance?.salesPercentOfTotalSales || 0,
        valueType: 'percentage',
      },
      {
        text: t('webGPPct'),
        value:
          find(homeWebData?.webPerformance?.profit, {
            Name: 'GP%',
          })?.amount || 0,
        valueType: 'percentage',
        changePercentage:
          find(homeWebData?.webPerformance?.profit, {
            Name: 'GP%',
          })?.change || 0,
        changeLabel: 'YOY',
      },
      {
        text: t('gpPctCompare'),
        value: t('gpBasisPoints', {
          positive: gpBasisPoints > 0 ? '+' : '',
          gpBasisPoints,
        }),
        valueType: 'string',
      },
    ];
    if (requestType === 'DAILY') {
      return result;
    }
    result.splice(5, 0, {
      text: t('averageWebSales'),
      value:
        find(homeWebData?.webPerformance?.sales, {
          Name: 'Avg Daily',
        })?.amount || 0,
      valueType: 'currency',
      currencyType: homeWebData?.currencyType,
      changePercentage:
        find(homeWebData?.webPerformance?.sales, {
          Name: 'Avg Daily',
        })?.change || 0,
      changeLabel: 'YOY',
    });
    return result;
  }, [
    gpBasisPoints,
    homeWebData?.currencyType,
    homeWebData?.webPerformance?.digitalPercentOfTotalSales,
    homeWebData?.webPerformance?.profit,
    homeWebData?.webPerformance?.sales,
    homeWebData?.webPerformance?.salesPercentOfTotalSales,
    requestType,
    t,
  ]);

  return (
    <DashboardCard
      id="webPerf"
      customTitle={
        <div className={classes.iconWrapper}>
          <FontAwesomeIcon
            className={classes.salesPerfIcon}
            icon={['far', 'chart-pie-simple']}
          />
          <Text
            variant="title-action-card"
            text={t('digitalSales')}
            testid="title"
          />
          <HelpButton
            modal={{
              title: t('digitalSales'),
              testid: 'digital-sales-info',
              initialBreakpoint: 0.45,
              children: (
                <IonRow>
                  <Text text={t('digitalSalesHelpMessage')} />
                </IonRow>
              ),
            }}
            button={{
              testid: 'digital-sales-info-btn',
              icon: ['far', 'info-circle'],
              className: classes.infoButton,
            }}
          />
        </div>
      }
      subtitle={choose(
        or(isCamUser, isAvpUserWithExecView),
        subTitle,
        stateLocation?.name
      )}
      lines={lines}
      overlayBarMsg={includeDailyTotalMsg}
      overlayInfoHeader={includeDailyTotalInfo}
      reportKey={WebSalesHomeKey}
      tabReportKey={TabWebSalesReportKey}
      requestType={requestType}
      isLoading={showLoader}
      busPeriod={busPeriod}
      error={error}
      setBusPeriod={updateBusPeriod}
      setRequestType={updateRequestType}
      primaryButton={{
        href: webSaleUrl,
        text: t(`${namespaces.reports}:viewWebSalesReport`),
      }}
    />
  );
};

export default WebPerformanceCard;
