import React from 'react';
import classNames from 'classnames';
import { IonRow } from '@ionic/react';
import type { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import Button from 'components/Button/Button';
import classes from './PriceOverrideLabel.module.scss';

enum PriceBagdeEnum {
  'priceOverride',
  'priceUpdate',
}

export type PriceBagdeType = keyof typeof PriceBagdeEnum;

interface PriceOverrideLabelProps {
  text?: string;
  variant?: PriceBagdeType;
  testid?: string;
}

const PriceOverrideLabel = ({
  testid,
  text,
  className,
  variant = 'priceOverride',
}: PriceOverrideLabelProps & IonicReactProps): JSX.Element => {
  return (
    <IonRow
      data-testid={testid}
      className={classNames(classes.priceLabel, classes[variant], className)}
    >
      <Button
        variant="link"
        text={text}
        icon={['fas', 'calculator']}
        testid="price-override-label"
      />
    </IonRow>
  );
};

export default PriceOverrideLabel;
