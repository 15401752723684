import { useMemo } from 'react';
import { head, map, orderBy } from 'lodash';
import type { InfiniteData } from '@tanstack/react-query';
import type { BaseAPIHook } from 'common/api/utils/apiHelpers';
import type {
  QueryFlags,
  QueryParamsType,
} from 'common/api/utils/useGetQueryFlags';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { useAxios } from 'providers/AxiosProvider';
import { useMiLocOrTeamId } from 'api/helpers';
import { SortDirEnum } from 'models/Sort';
import { pageSize } from 'utils/constants';
import { getUnbilledAPI } from './apiRoutes';
import { getParamsGroupBy } from './useGetSalesReport';

export interface UnbilledReportRow {
  totalPossibleAmt: number;
  unbilledDirectsCnt: number;
  unbilledDirectsLineCnt: number;
  unbilledDirectsAmt: number;
  unreleasedSummaryBillCnt: number;
  unreleasedSummaryBillAmt: number;
  unapprovedVendorDirectsCnt: number;
  unapprovedVendorDirectsLineCnt: number;
  unapprovedVendorDirectsAmt: number;
  otherOrdersReadyToShipCnt: number;
  otherOrdersReadyToShipAmt: number;
  currency: string;
  branch: string;
  corp: string;
  division: string;
  name: string;
  group: string;
}

interface UseUnbilledProps extends BaseAPIHook {
  miLoc: string;
  groupBy: string;
  sortField?: string;
  sortDir?: SortDirEnum;
}

export interface GetUnbilledResponse {
  rows: UnbilledReportRow[];
  summary?: UnbilledReportRow;
  totalRows?: number;
}

const getUnbilledReportQueryKey = 'unbilled-report';

const useGetUnbilledReport = ({
  miLoc,
  groupBy,
  sortField,
  sortDir,
  limit = pageSize(),
  enabled,
}: UseUnbilledProps): GetUnbilledResponse & QueryFlags => {
  const { axios } = useAxios();
  const { getURLParams } = useMiLocOrTeamId({});
  const params: QueryParamsType = {
    miLoc,
    groupBy: getParamsGroupBy(groupBy),
    limit,
  };

  const response = useQuerySetup<UnbilledReportRow, GetUnbilledResponse>({
    queryKey: getUnbilledReportQueryKey,
    queryParams: params,
    onAPIRequest: async ({ pageParam = 1, signal }) => {
      const { data } = await axios.get<GetUnbilledResponse>(
        getUnbilledAPI(getURLParams({ ...params, page: pageParam })),
        { signal }
      );
      return {
        rows: data.rows,
        summary: data.summary,
        totalRows: data.totalRows,
      };
    },
    dataPath: 'rows',
    totalDataPath: 'summary',
    limit,
    enabled,
    isInfiniteQuery: true,
  });

  const { items, totalItems, data: responseData, ...queryFlags } = response;
  const unbilledResponseDatas =
    responseData as InfiniteData<GetUnbilledResponse>;
  const summaryPage = head(unbilledResponseDatas?.pages);

  const rows = useMemo(
    () =>
      map(
        orderBy(
          items,
          sortField,
          sortDir === SortDirEnum.ASCENDING ? 'asc' : 'desc'
        )
      ),
    [items, sortField, sortDir]
  );

  return {
    ...queryFlags,
    rows,
    summary: summaryPage?.summary,
  };
};

export default useGetUnbilledReport;
