import { head, toString } from 'lodash';
import type { InfiniteData } from '@tanstack/react-query';
import type {
  QueryParamsType,
  QueryFlags,
} from 'common/api/utils/useGetQueryFlags';
import useQuerySetup from 'common/api/utils/useQuerySetup';
import { useAxios } from 'providers/AxiosProvider';
import useEnabledByUser from 'ReportsApp/hooks/useEnabledByUser';
import useGetApiUrl from 'ReportsApp/hooks/useGetAPIUrl';
import { useMiLocOrTeamId } from 'api/helpers';
import useGetUserConfig from 'api/user/useGetUserConfig';
import type { CalendarDay } from 'models/Reports';
import { SortDirEnum } from 'models/Sort';
import { pageSize } from 'utils/constants';
import useGetLocationCurrency from 'utils/currency';
import { DateFormatEnum, formatDate } from 'utils/date';
import { mapGroupBy, mapSortField } from './useGetSalesDashboard';
import type {
  SalesReportRow,
  SalesReportTrendRow,
  UseGetSalesProps,
} from './useGetSalesDashboard';

export const getPick12DashboardQueryKey = 'pick12-dashboard';

export interface Pick12ReportData {
  rows?: SalesReportRow[];
  summary?: SalesReportRow;
  datesWithSameBusDay: CalendarDay[];
  trend?: {
    current?: SalesReportTrendRow[];
    previous?: SalesReportTrendRow[];
  };
}

export interface UseGetPick12DashboardResponse {
  rows: SalesReportRow[];
  summary?: SalesReportRow;
  datesWithSameBusDay?: CalendarDay[];
}

const useGetPick12Dashboard = ({
  miLoc: propsMiLoc,
  customerId,
  nationalAcctNo,
  territory,
  busPeriod,
  requestType = 'MTD',
  groupBy,
  sortField = 'name',
  sortDir = SortDirEnum.ASCENDING,
  summaryOnly,
  limit = pageSize(),
  sendVirtualTeamId = true,
  isPick12Report = false,
  isLocationPick12Report = false,
  enabled: isEnabled = true,
}: UseGetSalesProps): UseGetPick12DashboardResponse & QueryFlags => {
  const { axios } = useAxios();
  const { createParams, getURLParams } = useMiLocOrTeamId({
    miLoc: propsMiLoc,
    sendVirtualTeamId,
  });

  const enabled = useEnabledByUser({ enabled: isEnabled });
  const apiUrl = useGetApiUrl({ isPick12Report, isLocationPick12Report });

  const { data: currencyData } = useGetUserConfig({
    configType: 'currency',
    enabled,
  });
  const { secondaryCurrencyType } = useGetLocationCurrency();
  let currencyType = currencyData?.currency;
  currencyType ||= secondaryCurrencyType;

  const propsGroupBy = toString(groupBy);
  let paramsGroupBy = mapGroupBy[propsGroupBy];
  paramsGroupBy ||= propsGroupBy;

  const params: QueryParamsType = {
    ...createParams(),
    customerNo: customerId,
    nationalAcctNo,
    territory,
    requestDate: formatDate(busPeriod, DateFormatEnum.reportsDateAPI),
    requestType,
    usdRequested: currencyType === 'USD',
    limit,
    ...(summaryOnly
      ? {}
      : {
          groupBy: paramsGroupBy,
          sortField: mapSortField(sortField, paramsGroupBy),
          sortDir,
        }),
  };

  const response = useQuerySetup<SalesReportRow, UseGetPick12DashboardResponse>(
    {
      queryKey: getPick12DashboardQueryKey,
      queryParams: params,
      onAPIRequest: async ({ pageParam = 1, signal }) => {
        const { data } = await axios.get<UseGetPick12DashboardResponse>(
          apiUrl(
            getURLParams({
              ...params,
              // api doesn't support page param
              start: limit * (pageParam - 1),
              page: pageParam,
            })
          ),
          { signal }
        );
        return data;
      },
      dataPath: 'rows',
      totalDataPath: 'summary',
      limit,
      enabled,
      isInfiniteQuery: true,
    }
  );

  const {
    items: rows,
    totalItems,
    data: responseData,
    ...queryFlags
  } = response;
  const pick12Data =
    responseData as InfiniteData<UseGetPick12DashboardResponse>;
  const summaryPage = head(pick12Data?.pages);

  return {
    ...queryFlags,
    rows,
    summary: summaryPage?.summary,
    datesWithSameBusDay: summaryPage?.datesWithSameBusDay,
  };
};

export default useGetPick12Dashboard;
