import React, { useState } from 'react';
import classNames from 'classnames';
import { toString } from 'lodash';
import Button from 'components/Button/Button';
import HelpModal from 'components/HelpModal/HelpModal';
import type Modal from 'components/Modal/Modal';
import classes from './HelpButton.module.scss';

interface HelpButtonProps {
  modal: Omit<React.ComponentProps<typeof Modal>, 'isOpen'>;
  button: React.ComponentProps<typeof Button>;
}

const HelpButton = ({ modal, button }: HelpButtonProps): JSX.Element => {
  const [isOpenHelpModal, setIsOpenHelpModal] = useState<boolean>(false);
  const {
    iconClassName,
    icon = ['fas', 'info-circle'],
    className,
    testid,
    text,
    onClick,
  } = button;
  const { title, children, testid: modalTestId, initialBreakpoint } = modal;

  return (
    <>
      <Button
        testid={`${testid}-button`}
        onClick={(e) => {
          setIsOpenHelpModal?.(true);
          onClick?.(e);
        }}
        className={classNames(className, classes.button)}
        iconClassName={classNames(classes.icon, iconClassName)}
        text={text}
        rightIcon={icon}
        textVariant="list-item-secondaryText"
      />
      <HelpModal
        title={toString(title)}
        closeHelpModal={setIsOpenHelpModal}
        testid={`${modalTestId}-help-modal`}
        isOpen={isOpenHelpModal}
        initialBreakpoint={initialBreakpoint}
      >
        {children}
      </HelpModal>
    </>
  );
};

export default HelpButton;
