import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isEmpty, toString } from 'lodash';
import type { ShopQuoteReady } from 'ActivitiesApp/models/ShopQuote';
import InsetList from 'common/components/InsetList/InsetList';
import { and, ifRender } from 'common/utils/logicHelpers';
import { DataTypeEnum } from 'common/utils/valueFormatter';
import { parseISO, isBefore } from 'date-fns';
import { searchLocationDetailURL, searchURL } from 'navigation';
import useTransmitDetails from 'ProductSearchApp/hooks/useTransmitLogDetails';
import type { ActionCardActivity } from 'models/ActivityModels';
import { formatCardDate } from 'utils/date';
import { concatRoutes } from 'utils/navigations';
import { removeLeadingZeros } from 'utils/number';
import {
  goToReviewCart,
  goToTransmissionLog,
} from 'navigation/navigationHelpers';
import Button from 'components/Button/Button';
import Address from 'components/Contacts/Address/Address';
import { PhoneCallRow } from 'components/Contacts/PhoneNumber/PhoneNumber';
import { EmailRow } from 'components/Email/Email';
import SendEmailModal from 'components/Modals/SendEmailModal/SendEmailModal';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import NavigationRow, {
  NavigationRowVariants,
} from 'components/NavigationRow/NavigationRow';
import Text from 'components/Text/Text';
import classes from './ShopQuoteForm.module.scss';

interface ShopQuoteFormProps {
  activity: ActionCardActivity;
}

const ShopQuoteForm = ({ activity }: ShopQuoteFormProps): JSX.Element => {
  const {
    shopMiLoc,
    itemDescription,
    workOrderCtlNo,
    orderCtlNo,
    shopName,
    cost,
    shopAddr1,
    shopAddr2,
    shopAddr3,
    shopCity,
    shopState,
    shopZip,
    country,
    shopPhone,
    shopEmail,
    workOrderQuoteComplete,
    lastDocumentTransmission,
  } = activity.extendedInfo as unknown as ShopQuoteReady;

  const { transmitMessage } = useTransmitDetails({
    transmissionMethod: lastDocumentTransmission?.transmissionMethod,
    transmissionStatus: lastDocumentTransmission?.transmissionStatus,
    creationUserId: lastDocumentTransmission?.creationUserId,
    creationUsername: lastDocumentTransmission?.creationUsername,
  });

  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [repairShopModalOpen, setRepairShopModalOpen] = useState(false);
  const { t } = useTranslation('ActivitiesApp-ShopQuote');
  const shopLocationHref = concatRoutes(
    searchURL(),
    searchLocationDetailURL(shopMiLoc)
  );
  const ocnReviewHref = goToReviewCart({
    miLoc: activity?.custMiLoc,
    shipToCustNo: activity?.custNo,
    orderCtlNo,
    ocnType: 'order',
    ocnMode: 'review',
  });

  const tranmissionLogHref = goToTransmissionLog({
    miLoc: activity?.custMiLoc,
    shipToCustNo: activity?.custNo,
    orderCtlNo,
    ocnType: 'order',
    ocnMode: 'review',
  });

  const insetData = [
    {
      label: t('itemDesc'),
      type: DataTypeEnum.string,
      value: itemDescription,
    },
    {
      label: t('workOrder'),
      type: DataTypeEnum.string,
      value: workOrderCtlNo,
    },
    {
      label: t('ocn'),
      type: DataTypeEnum.string,
      customValue: (
        <Button
          variant="link"
          testid="inset-order-ctl-no"
          text={removeLeadingZeros(orderCtlNo)}
          textVariant="list-item-title"
          href={ocnReviewHref}
          className={classes.insetCustomValue}
        />
      ),
    },
    {
      label: t('motionShop'),
      type: DataTypeEnum.string,
      customValue: (
        <Button
          variant="link"
          testid="inset-shop-name"
          text={`${toString(shopMiLoc)} - ${toString(shopName)}`}
          textVariant="list-item-title"
          className={classes.insetCustomValue}
          onClick={() => setRepairShopModalOpen(true)}
        />
      ),
    },
    {
      label: t('dateQuoted'),
      type: DataTypeEnum.string,
      value: formatCardDate(activity?.creationTimestamp, false, false),
    },
    {
      label: t('cost'),
      type: DataTypeEnum.currency,
      value: cost,
    },
  ];

  const dismissShopModal = () => setRepairShopModalOpen(false);

  const showOcnIndicator = () => {
    if (
      and(
        !isEmpty(lastDocumentTransmission),
        isBefore(
          parseISO(workOrderQuoteComplete),
          parseISO(lastDocumentTransmission?.transmissionTmstmp)
        )
      )
    ) {
      return (
        <NavigationRow
          text={transmitMessage}
          endCustomContent={
            <Button
              testid="view-transmission-log"
              variant="link"
              text={t('ActivitiesApp-ShopQuote:view')}
            />
          }
          variant={NavigationRowVariants.iconLink}
          startCustomContent={
            <Button testid="mail-icon" icon={['fas', 'envelope']} />
          }
          className={classes.ocnIndicator}
          href={tranmissionLogHref}
        />
      );
    }
    return (
      <Text
        variant="mipro-body-copy"
        text={t('shopQuoteDescription', {
          item: itemDescription,
          location: shopMiLoc,
          shop: shopName,
        })}
        className={classNames(classes.textBlock, classes.lineBreak)}
      />
    );
  };

  return (
    <div className={classes.detailCard}>
      {showOcnIndicator()}
      <InsetList
        testid="inset-list"
        data={insetData}
        className={classes.insetList}
      />
      <Button
        className={classes.linkButton}
        variant="secondary"
        text={t('viewOcn')}
        testid="view-ocn-button"
        href={ocnReviewHref}
      />
      <SheetModal
        withRightCloseButton
        isOpen={repairShopModalOpen}
        setIsOpen={setRepairShopModalOpen}
        testid="shop-quote-form-shop-modal"
        title={`${toString(shopMiLoc)} - ${toString(shopName)}`}
        contentClass={classes.shopModal}
      >
        {ifRender(
          shopAddr2 && shopCity && shopState,
          <Address
            className={classes.address}
            onClick={() => dismissShopModal()}
            address={{
              addrLine1: shopAddr1,
              addrLine2: shopAddr2,
              addrLine3: shopAddr3,
              city: shopCity,
              state: shopState,
              zip: shopZip,
              country,
            }}
          />
        )}
        {ifRender(
          shopPhone,
          <PhoneCallRow
            className={classes.modalText}
            phoneNumber={shopPhone as string}
            testid="shop-phone"
            onClick={() => dismissShopModal()}
          />
        )}
        {ifRender(
          shopEmail,
          <EmailRow
            className={classes.modalText}
            emailAddress={shopEmail as string}
            testid="shop-email"
            onClick={() => {
              setSendEmailModal(true);
              dismissShopModal();
            }}
          />
        )}
        <Button
          className={classNames(classes.linkButton, classes.spacer)}
          variant="secondary"
          text={t('viewShopDetails')}
          testid="view-shop-button"
          href={shopLocationHref}
          onClick={() => dismissShopModal()}
        />
      </SheetModal>
      <SendEmailModal
        searchType="motionLocation"
        miLoc={shopMiLoc}
        id={shopMiLoc}
        defaultRecipients={[
          { name: shopName, miLoc: shopMiLoc, email: shopEmail },
        ]}
        canRemoveRecipients={false}
        isOpen={sendEmailModal}
        setIsOpen={setSendEmailModal}
        title={t('sendEmail')}
        testid="send-email-modal"
      />
    </div>
  );
};

export default ShopQuoteForm;
