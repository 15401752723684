import { includes } from 'lodash';
import type { SalesReportRow } from 'ReportsApp/api/useGetSalesDashboard';
import type { ReportRowMetaData } from 'ReportsApp/helpers/reportHelpers';
import { goToPick12Report } from 'ReportsApp/navigation/navigationHelpers';
import { goToCustomerSnapshot } from 'SearchApp/navigation/navigationHelpers';

interface Pick12MetaDataProp {
  row: SalesReportRow;
  rowName: string;
  groupByDataKey: string;
  routeRowId?: string;
  rowId: string;
  pick12: boolean;
  orgType: string;
}

const getPick12TableRowMetaData = ({
  groupByDataKey,
  row,
  rowName,
  routeRowId,
  rowId,
  pick12,
  orgType,
}: Pick12MetaDataProp): ReportRowMetaData => {
  let href: string | undefined = goToPick12Report({
    orgType: groupByDataKey,
    miLoc: row.miLoc,
    rowId: routeRowId,
  });
  let title = rowName;
  const customerHref = goToCustomerSnapshot({
    miLoc: row.miLoc,
    customerId: row.customerNo,
  });
  switch (groupByDataKey) {
    case 'REP':
      title = row.repName;
      href = goToPick12Report({
        orgType: groupByDataKey,
        miLoc: row.miLoc,
        rowId: row.repNo,
        pick12,
      });
      break;
    case 'CUST':
      title =
        !includes(
          ['TEAM', 'BRCH', 'REP', 'CUST', 'PRD_GRP_01', 'PRD_GRP_02'],
          orgType
        ) && rowId
          ? `${row.miLoc}: ${row.customerName}`
          : row.customerName;
      href = customerHref;
      break;

    default:
  }
  const customerLink = groupByDataKey === 'CUST';
  return { title, href, customerLink };
};

export default getPick12TableRowMetaData;
