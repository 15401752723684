import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type { SortingRule } from 'react-table';
import {
  map,
  toString,
  type Dictionary,
  find,
  debounce,
  head,
  isEmpty,
  includes,
  isNil,
  size,
  toNumber,
  split,
} from 'lodash';
import { IonContent, IonPage, useIonViewDidEnter } from '@ionic/react';
import Header from 'common/components/Header/Header';
import { fromUnixTime, isToday } from 'date-fns';
import {
  ProductGroupKey,
  TabSalesPerformanceKey,
  useReportsConfig,
} from 'providers/ReportsProvider';
import type { SalesReportRow } from 'ReportsApp/api/useGetSalesDashboard';
import useGetSalesDashboard from 'ReportsApp/api/useGetSalesDashboard';
import ReportHelper from 'ReportsApp/components/ReportHelper/ReportHelper';
import { useGetReportTableData } from 'ReportsApp/helpers/reportHelpers';
import useGetGroupBy from 'ReportsApp/hooks/useGetGroupBy';
import useReportHeader from 'ReportsApp/hooks/useReportHeader';
import { type BaseReportURLParams } from 'ReportsApp/models';
import { goToEndOfMonthReport } from 'ReportsApp/navigation/navigationHelpers';
import { useDebounce } from 'use-debounce';
import useGetCalendarDays from 'api/salesReports/useGetCalendarDays';
import { SortDirEnum } from 'models/Sort';
import {
  getBusinessDays,
  getHolidays,
  useGetSharedBusinessDayLabel,
} from 'utils/date';
import {
  locationSortOptions,
  nameSortOptions,
} from 'pages/Reports/DrillDown/sortOptions';
import EndOfMonthDashboard from 'assets/EndOfMonthDashboard.svg';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import DateToolbar from 'components/DateToolbar/DateToolbar';
import type { FilterOption } from 'components/Filter/Filter';
import Refresher from 'components/Refresher/Refresher';
import classes from './SalesPerformance.module.scss';

export interface SalesPerformanceURLParams extends BaseReportURLParams {
  tab?: string;
  pgc1?: string;
}

const SalesPerformance = (): JSX.Element => {
  const { t } = useTranslation('ReportApp-SalesPerformanceReport');

  const {
    tab = '',
    orgType = '',
    rowId: routeRowId = '',
    pgc1 = '',
  } = useParams<SalesPerformanceURLParams>();

  const [rowId] = split(routeRowId, '-');

  const { data: calendar } = useGetCalendarDays({});
  const businessDays = useMemo(() => getBusinessDays(calendar), [calendar]);
  const holidays = useMemo(() => getHolidays(calendar), [calendar]);

  const reportKey = tab === 'search' ? ProductGroupKey : TabSalesPerformanceKey;

  const {
    requestType,
    busPeriod: configBusPeriod,
    sortField,
    sortDir,
    updateRequestType,
    updateBusPeriod,
    updateSortField,
    updateSortDir,
  } = useReportsConfig({ key: reportKey });

  const [debouncedUpdateBusPeriod, setDebouncedUpdateBusPeriod] =
    useState(false);
  const [debouncedBusPeriod] = useDebounce(configBusPeriod, 1000);
  const busPeriod = debouncedUpdateBusPeriod
    ? debouncedBusPeriod
    : configBusPeriod;

  const [canChangeTab, setCanChangeTab] = useState(false);

  useIonViewDidEnter(() => {
    setCanChangeTab(true);
  });

  const reportFields = useMemo(
    () => [
      {
        key: 'sales',
        type: 'currency',
        id: 'currentSales',
      },
      {
        key: 'salesYoY',
        type: 'yoy',
        id: 'currentSalesChangeToCurrentBusDay',
      },
      {
        key: 'gp',
        type: 'currency',
        id: 'currentGp',
      },
      {
        key: 'gpYoY',
        type: 'yoy',
        id: 'currentGpChangeToCurrentBusDay',
      },
      {
        key: 'gp%',
        type: 'percentage',
        id: 'currentGpPercent',
      },
      {
        key: 'gpbpsYoY',
        type: 'bp-yoy',
        id: 'currentBpChangeToCurrentBusDay',
      },
      {
        key: 'avgDaily',
        type: 'currency',
        id: 'currentAvgDaily',
        hidden: requestType === 'DAILY',
      },
      {
        key: 'avgDailyYoY',
        type: 'yoy',
        id: 'currentAvgDailyChangeToCurrentBusDay',
        hidden: requestType === 'DAILY',
      },
      {
        key: 'unbilled',
        type: 'currency',
        id: 'summaryBillingSales',
        getValue: (v: unknown) => {
          const item = v as SalesReportRow;
          let value = item?.summaryBillingSales;
          value ||= toNumber(item?.summaryBilling?.sales);
          return value;
        },
        hidden: requestType !== 'DAILY' || !isToday(fromUnixTime(busPeriod)),
      },
    ],
    [busPeriod, requestType]
  );

  // #region group and sort
  const {
    miLoc,
    userRole,
    groupByOptions,
    selectedGroupBy,
    setGroupByData,
    showGroupBy,
  } = useGetGroupBy('sales');
  const groupByDataKey = selectedGroupBy.key;

  const onGroupBy = (value: string) => {
    if (canChangeTab) {
      setGroupByData((prev) => ({
        ...prev,
        [userRole]: find(groupByOptions, (group) => group.key === value),
      }));
    }
  };

  const sortFieldOptions = useCallback(
    (groupByKey?: string) => {
      const showLocationSortOptions = includes(
        ['EXEC', 'CORP', 'GRP', 'DIV', 'BRCH'],
        groupByKey
      );

      return [
        ...(showLocationSortOptions ? locationSortOptions : nameSortOptions),
        ...map(reportFields, (field) => ({
          key: field.id,
          name: field.id,
          type: 'number',
        })),
      ].map((sortItem) => ({
        ...sortItem,
        name: t(sortItem.name),
      })) as FilterOption[];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [busPeriod, requestType]
  );

  const sortFieldSelected = useMemo(
    () => {
      let selectedSort = find(sortFieldOptions(groupByDataKey), {
        key: sortField,
      });
      selectedSort ||= head(sortFieldOptions(groupByDataKey));
      return selectedSort;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupByDataKey, sortField]
  ) as FilterOption;

  const onSortBy = debounce(
    (sortOption: SortingRule<Dictionary<unknown>>[]) => {
      updateSortField?.(toString(head(sortOption)?.id));
      if (!isEmpty(sortOption)) {
        updateSortDir?.(
          head(sortOption)?.desc
            ? SortDirEnum.DESCENDING
            : SortDirEnum.ASCENDING
        );
      }
    },
    300
  );
  // #endregion group and sort

  const {
    drilldownData,
    summaryData,
    datesWithSameBusDay,
    dataUpdatedAt,
    isLoading,
    error,
    noMoreData,
    refetch,
    fetchNextPage,
  } = useGetSalesDashboard({
    miLoc,
    customerId: includes(['CUST', 'PRD_GRP_01', 'PRD_GRP_02'], orgType)
      ? rowId
      : undefined,
    territory: orgType === 'REP' ? rowId : undefined,
    nationalAcctNo:
      !includes(['REP', 'CUST', 'PRD_GRP_01', 'PRD_GRP_02'], orgType) && rowId
        ? rowId
        : undefined,
    pgc1,
    busPeriod,
    requestType,
    groupBy: groupByDataKey,
    sortField: sortFieldSelected.key,
    sortDir: sortDir as SortDirEnum,
  });

  const isEmptyResponse = !isLoading && size(drilldownData) === 0;

  const sharedBusinessDayLabel = useGetSharedBusinessDayLabel(
    busPeriod,
    datesWithSameBusDay
  );

  const { tableData, tableColumns, totalsRow } = useGetReportTableData({
    drilldownData,
    summaryData,
    reportFields,
    selectedGroupBy,
    setCanChangeTab,
    setGroupByData,
    reportType: 'sales',
    namespace: 'ReportApp-SalesPerformanceReport',
  });

  const reportHelperProps = {
    sharedBusinessDayLabel,
    showGroupBy,
    groupByOptions,
    selectedGroupBy,
    onGroupBy,
    onSortBy,
    fetchNextPage,
    error,
    reportName: t('salesPerformance'),
    isEmptyResponse,
    requestType,
    tableData,
    tableColumns,
    totalsRow,
    sortFieldSelected,
    sortDir,
    isLoading,
    noMoreData,
  };

  // #region fixed header
  const { title, reportHeader } = useReportHeader(true);
  // #endregion fixed header

  return (
    <IonPage data-testid="sales-performance-page">
      <Header
        testid="sales-performance-page-header"
        title={t('salesPerformance')}
        className={classes.pageHeader}
        subTitle={title}
      >
        <DateToolbar
          className={classes.datetoolbar}
          testid="Reports"
          busPeriod={configBusPeriod}
          requestType={requestType}
          updateBusPeriod={(date, waitFor) => {
            if (!isNil(waitFor)) {
              setDebouncedUpdateBusPeriod(waitFor);
            }
            updateBusPeriod(date);
          }}
          updateRequestType={updateRequestType}
          businessDays={businessDays}
          holidays={holidays}
          reportKey={reportKey}
        />
        <ActionRow
          className={classes.actionRowLink}
          href={goToEndOfMonthReport()}
          testid="oem-report-link"
        >
          <Button
            className={classes.linkButton}
            variant="secondary"
            text={t('viewEOMReportText')}
            testid="oem-report-button"
          >
            <img src={EndOfMonthDashboard} alt="" />
          </Button>
        </ActionRow>
      </Header>
      <IonContent>
        <Refresher
          slot="fixed"
          lastUpdatedAt={dataUpdatedAt}
          onRefresh={refetch}
          hidden={requestType !== 'DAILY'}
          disabled={isLoading}
          testid="sales-performance"
        />
        <Header
          collapse="condense"
          testid="sales-performance-header"
          pageTitle={t('salesPerformance')}
          className={classes.header}
          customTitle={<div className={classes.wrapper}>{reportHeader}</div>}
        />
        {
          // eslint-disable-next-line react/jsx-props-no-spreading
          <ReportHelper {...reportHelperProps} />
        }
      </IonContent>
    </IonPage>
  );
};

export default SalesPerformance;
