import CostSavings from 'ReportsApp/pages/CostSavings/CostSavings';
import EndOfMonth from 'ReportsApp/pages/EndOfMonth/EndOfMonth';
import OpenQuotes from 'ReportsApp/pages/OpenQuotes/OpenQuotes';
import Pick12Report from 'ReportsApp/pages/Pick12Report/Pick12Report';
import SalesPerformance from 'ReportsApp/pages/SalesPerformance/SalesPerformance';
import Unbilled from 'ReportsApp/pages/Unbilled/Unbilled';
import type { TabRoutes } from 'models/Navigation';

// #region ui-routes
interface BaseDrilldownReportURLProps {
  orgType?: string;
  miLoc?: string;
  rowId?: string;
  pgc1?: string;
}

const baseDrilldownReportURL = ({
  url = '',
  orgType = ':orgType?',
  miLoc = ':miLoc?',
  rowId = ':rowId?',
  pgc1 = ':pgc1?',
}) => {
  const orgTypeParam = orgType ? `/${orgType}` : '';
  const miLocParam = miLoc ? `/${miLoc}` : '';
  const rowIdParam = rowId ? `/${rowId}` : '';
  const pgc1Param = pgc1 ? `/${pgc1}` : '';
  return `/${url}${orgTypeParam}${miLocParam}${rowIdParam}${pgc1Param}`;
};

export const locationPick12ReportURL = (props: BaseDrilldownReportURLProps) => {
  return baseDrilldownReportURL({
    url: 'location-pick-12',
    ...props,
    pgc1: '',
  });
};

export const pick12ReportURL = (props: BaseDrilldownReportURLProps) => {
  return baseDrilldownReportURL({ url: 'pick-12', ...props, pgc1: '' });
};

export const salesPerformanceReportURL = (
  props: BaseDrilldownReportURLProps
) => {
  return baseDrilldownReportURL({ url: 'sales-performance', ...props });
};

export const costSavingsReportURL = (props: BaseDrilldownReportURLProps) => {
  return baseDrilldownReportURL({ url: 'cost-savings', ...props });
};

export const unbilledReportURL = (props: BaseDrilldownReportURLProps) => {
  return baseDrilldownReportURL({ url: 'unbilled', ...props });
};

export const openQuotesReportsURL = () => '/open-quotes';

export const endOfMonthReportsURL = () => '/end-of-month';
// #endregion ui-routes

export const ReportsAppRouter: TabRoutes[] = [
  {
    path: locationPick12ReportURL({}),
    component: Pick12Report as React.FC,
    props: { reportType: 'locationPick12' },
    routeName: 'Location Pick 12 Report',
  },
  {
    path: pick12ReportURL({}),
    component: Pick12Report as React.FC,
    props: { reportType: 'pick12' },
    routeName: 'Pick 12 Report',
  },
  {
    path: salesPerformanceReportURL({}),
    component: SalesPerformance,
    routeName: 'Sales Performance Report',
  },
  {
    path: openQuotesReportsURL(),
    component: OpenQuotes,
    routeName: 'Open Quotes Report',
  },
  {
    path: endOfMonthReportsURL(),
    component: EndOfMonth,
    routeName: 'End Of Month Live',
  },
  {
    path: costSavingsReportURL({}),
    component: CostSavings,
    routeName: 'Cost Savings Report',
  },
  {
    path: unbilledReportURL({}),
    component: Unbilled,
    routeName: 'Unbilled Report',
  },
];
