import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { filter, first, includes, isEmpty, last, map } from 'lodash';
import type { MutationStatus } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAPIUrl from 'api';
import { and, choose, or } from 'common/utils/logicHelpers';
import { getOrderQueryKey } from 'ProductSearchApp/api/useGetOrder';
import type { Order } from 'ProductSearchApp/models/Order';
import type { AddToCart } from 'ProductSearchApp/models/Products';
import { isCustomerLess } from 'ProductSearchApp/util/productSearchUtil';
import { useAxios } from 'providers/AxiosProvider';
import { useToasts } from 'providers/ToastProvider';
import { onSuccessMutation, useKeyUserId } from 'api/helpers';
import { ToastType } from 'models/Toast';
import type { RootState } from 'store/reducers';
import { setCurrentCartCustomer } from 'store/user';
import { findOrdersQueryKey } from './orders/useFindOrders';

interface UseAddToCartResponse {
  status: MutationStatus;
  onAddToCart: (body: AddToCart) => void;
  itemIsAlreadyInCart: (order?: Order, itemNo?: string) => boolean;
  refetchCart?: () => Promise<void | Order>;
}

const useAddToCart = (): UseAddToCartResponse => {
  const { axios } = useAxios();
  const { addToCartAPI, addItemSourcingAPI } = useAPIUrl();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { createQueryKey } = useKeyUserId();
  const { addToast } = useToasts();
  const { t } = useTranslation('ProductSearchApp-Search');
  const { currentCartCustomer } = useSelector((state: RootState) => state.user);

  const doAddToCart = async (body: AddToCart) => {
    const { customerName, mfgPartNo, sourceList, switchCustomer, ...restBody } =
      body;
    const addPayload = {
      customerInfo: {
        customerNo: currentCartCustomer?.shipToCustNo,
        miLoc: currentCartCustomer?.miLoc,
        billToNo: currentCartCustomer?.billToCustNo,
      },
      orderInfo: {
        miLoc: currentCartCustomer?.miLoc,
        orderCtlNo: currentCartCustomer?.orderCtlNo,
        shipToCustNo: currentCartCustomer?.shipToCustNo,
      },
    };
    const addBody = { ...restBody, ...addPayload };
    let data: Order;

    if (isEmpty(currentCartCustomer) || switchCustomer) {
      ({ data } = await axios.post<Order>(addToCartAPI(), restBody));
    } else {
      ({ data } = await axios.post<Order>(addToCartAPI(), addBody));
    }

    if (!isEmpty(sourceList)) {
      const sentItem = first(restBody.itemList);
      ({ data } = await axios.post<Order>(addItemSourcingAPI(), {
        customerInfo: {
          miLoc: data?.miLoc,
          customerNo: data?.shipToCustNo,
          billToNo: data?.billToCustNo,
        },
        orderInfo: {
          miLoc: data?.miLoc,
          orderCtlNo: data?.orderCtlNo,
          shipToCustNo: data?.shipToCustNo,
        },
        item: {
          orderLineNo: last(
            filter(data.items, {
              itemNo: sentItem?.itemNo,
              mfrCtlNo: sentItem?.mfrCtlNo,
              groupSerial: sentItem?.groupSerial,
            })
          )?.orderLineNo,
        },
        sourceList,
      }));
    }
    return data;
  };

  const { status, mutate } = useMutation(doAddToCart, {
    onSuccess: (data, vars) => {
      if (vars.mfgPartNo) {
        addToast({
          type: ToastType.success,
          text: choose(
            isCustomerLess(vars.customerInfo?.customerNo),
            t('addToOcnCustomerlessSuccessToast', {
              mfgPartNo: vars.mfgPartNo,
            }),
            t('addToOcnSuccessToast', {
              customerName: or(
                currentCartCustomer?.customerName,
                vars.customerName
              ),
              mfgPartNo: vars.mfgPartNo,
            })
          ),
          testid: 'add-to-cart-success-toast',
        });
      }
      if (
        or(
          isEmpty(currentCartCustomer),
          and(
            currentCartCustomer?.shipToCustNo !== data?.shipToCustNo,
            isCustomerLess(currentCartCustomer?.shipToCustNo)
          )
        )
      ) {
        void onSuccessMutation(queryClient, findOrdersQueryKey);
        dispatch(
          setCurrentCartCustomer({
            currentCartCustomer: {
              customerName: vars.customerName,
              miLoc: data?.miLoc,
              shipToCustNo: data?.shipToCustNo,
              billToCustNo: data?.billToCustNo,
              orderCtlNo: data?.orderCtlNo,
            },
          })
        );
      } else {
        // TODO create utils to update query data
        queryClient.setQueryData<Order>(
          createQueryKey(getOrderQueryKey, {
            miLoc: currentCartCustomer?.miLoc,
            orderCtlNo: currentCartCustomer?.orderCtlNo,
          }),
          data
        );
      }
    },
    onError: () => {
      addToast({
        type: ToastType.error,
        text: t('addToOcnFailedToast'),
        testid: 'add-to-cart-error-toast',
      });
    },
  });

  return {
    status,
    onAddToCart: (body: AddToCart) => mutate(body),
    itemIsAlreadyInCart: (order?: Order, itemNo?: string) => {
      return includes(
        map(order?.items, (item) => item.itemNo),
        itemNo
      );
    },
  };
};

export default useAddToCart;
