import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  map,
  toString,
  type Dictionary,
  includes,
  findIndex,
  find,
  isEmpty,
  filter,
  get,
  size,
} from 'lodash';
import { and, choose, ifFunction, or } from 'common/utils/logicHelpers';
import i18next from 'i18n/i18n';
import type { BaseReportURLParams, ReportItemType } from 'ReportsApp/models';
import {
  groupByBranch,
  groupByCam,
  groupByCamCorp,
  groupByCamExec,
  groupByCostSavings,
  groupByCustomer,
  groupByPersonalPick12,
  groupByProductGroup01,
  groupByRoleOptions,
  groupByTeamAndRep,
  groupByUnbilled,
  groupByVirtualTeam,
} from 'ReportsApp/util/groupOptions/groupOptions';
import type { GroupByProps } from 'ReportsApp/util/groupOptions/groupOptions';
import type { StateMiLocArray } from 'api/helpers';
import { useGetSelectedMiLoc } from 'api/helpers';
import type { RootState } from 'store/reducers';
import { getUserRole } from 'utils/reports';
import type { FilterOption } from 'components/Filter/Filter';

interface GetGroupByOptionsProps {
  miLoc?: string;
  userRole?: string;
  fromVirtualTeam?: boolean;
  isCamUser?: boolean;
  orgType?: string;
  rowId?: string;
  singleTeam?: StateMiLocArray;
  reportType: ReportItemType;
}

const defaultGroupBy = {
  sales: 'BRCH',
  webSales: 'BRCH',
  costSavings: 'CUST',
};

const getGroupByOptions = ({
  miLoc = '',
  userRole = '',
  fromVirtualTeam = false,
  isCamUser = false,
  orgType = '',
  rowId = '',
  singleTeam,
  reportType,
}: GetGroupByOptionsProps) => {
  let groupBy;
  let groupByRoleItems;

  switch (reportType) {
    case 'sales':
    case 'webSales':
      if (userRole === 'BRCH') {
        groupBy = groupByBranch();
      } else if (includes(['TEAM', 'REP'], userRole)) {
        groupBy = groupByTeamAndRep;
      } else if (userRole === 'CUST') {
        groupBy = groupByCustomer;
      } else if (userRole === 'PRD_GRP_01') {
        groupBy = groupByProductGroup01;
      } else {
        groupByRoleItems = groupByRoleOptions();
      }
      ifFunction(and(fromVirtualTeam, !singleTeam), () => {
        groupBy = groupByVirtualTeam;
      });
      ifFunction(isCamUser, () => {
        groupByRoleItems = choose(
          miLoc === 'EXEC',
          groupByCamExec,
          groupByCamCorp
        );
        ifFunction(and(orgType !== 'NATLACCT', !isEmpty(rowId)), () => {
          groupByRoleItems = groupByCam;
        });
      });

      break;
    case 'pick12':
    case 'locationPick12':
      if (and(reportType === 'pick12', !orgType)) {
        groupBy = groupByPersonalPick12;
      } else if (userRole === 'BRCH') {
        groupBy = groupByBranch(true);
      } else if (includes(['TEAM', 'REP'], userRole)) {
        groupBy = groupByTeamAndRep;
      } else if (userRole === 'CUST') {
        groupBy = groupByCustomer;
      } else if (userRole === 'PRD_GRP_01') {
        groupBy = groupByProductGroup01;
      } else {
        groupByRoleItems = groupByRoleOptions(true);
      }
      break;
    case 'costSavings':
      ifFunction(
        or(userRole === 'GRP', userRole === 'BRCH', userRole === 'DIV'),
        () => {
          groupByRoleItems = filter(
            groupByCostSavings,
            (groupByItem: GroupByProps) => groupByItem.key !== 'REP'
          );
        }
      );
      groupBy = choose(fromVirtualTeam, groupByTeamAndRep, groupByCamCorp);
      break;
    case 'unbilled':
      groupBy = groupByUnbilled(userRole);
      break;
    default:
  }

  if (groupByRoleItems) {
    const currentRoleIndex = findIndex(groupByRoleItems, { key: userRole });
    groupBy = groupByRoleItems.slice(currentRoleIndex + 1);
  }

  return map(groupBy, (element) => {
    return {
      key: element.key,
      name: i18next.t(element.name, { suffix: element.suffix }),
    };
  });
};

const useGetGroupBy = (reportType: ReportItemType) => {
  const {
    orgType = '',
    miLoc: routeMiLoc = '',
    rowId,
  } = useParams<BaseReportURLParams>();
  const {
    miLoc: stateMiLoc = '',
    locationTree,
    isCamUser,
  } = useSelector((state: RootState) => state.user);
  const miLoc = or(routeMiLoc, stateMiLoc);
  let locationRole = or(toString(locationTree?.[miLoc]?.userRole), 'EXEC');
  locationRole = getUserRole(locationRole);
  const userRole = or(orgType, locationRole);

  const { fromVirtualTeam, singleTeam } = useGetSelectedMiLoc(miLoc);

  const groupByOptions = useMemo(() => {
    return getGroupByOptions({
      miLoc,
      userRole,
      fromVirtualTeam,
      isCamUser,
      orgType,
      rowId,
      singleTeam,
      reportType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [miLoc, userRole, fromVirtualTeam, isCamUser, orgType, rowId, singleTeam]);

  const [groupByData, setGroupByData] =
    useState<Dictionary<FilterOption | undefined>>();
  let selectedGroupBy = groupByData?.[userRole];

  ifFunction(isCamUser, () => {
    selectedGroupBy = or(
      selectedGroupBy,
      find(groupByOptions, {
        key: toString(get(defaultGroupBy, reportType)),
      })
    );
  });

  selectedGroupBy = or(selectedGroupBy, groupByOptions[0]);

  const showGroupBy = size(groupByOptions) > 1;

  return {
    miLoc,
    userRole,
    showGroupBy,
    groupByOptions,
    selectedGroupBy,
    setGroupByData,
  };
};

export default useGetGroupBy;
