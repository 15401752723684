import React from 'react';
import classNames from 'classnames';
import { isNil, isObject, toString } from 'lodash';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonItem, IonRow } from '@ionic/react';
import {
  getOptionalRenderProp,
  type BaseComponentProps,
  type OptionalRenderProp,
} from 'common/components/utils/renderHelpers';
import { and, choose, ifRender, or } from 'common/utils/logicHelpers';
import { logFirebaseEvent } from 'utils/firebaseAnalytics';
import Text from 'components/Text/Text';
import classes from './NavigationRow.module.scss';

export enum NavigationRowVariants {
  default = 'default',
  iconLink = 'iconLink',
  banner = 'banner',
}

interface NavigationRowProps extends BaseComponentProps {
  variant?: NavigationRowVariants;
  startIcon?: OptionalRenderProp<FontAwesomeIconProps, IconProp>;
  endIcon?: OptionalRenderProp<FontAwesomeIconProps, IconProp>;
  text?: OptionalRenderProp;
  tracking?: boolean;
  startCustomContent?: React.ReactNode;
  endCustomContent?: React.ReactNode;
}

const NavigationRow = ({
  testid = 'navigation-row',
  className,
  variant = NavigationRowVariants.default,
  startIcon: pStartIcon,
  endIcon: pEndIcon,
  text: pText,
  onClick,
  href,
  tracking = true,
  disabled,
  startCustomContent,
  endCustomContent,
}: NavigationRowProps & React.ComponentProps<typeof IonItem>): JSX.Element => {
  const withStartSlot = or(!isNil(pStartIcon), !isNil(startCustomContent));
  const withClick = !isNil(onClick);
  const isBanner = variant === NavigationRowVariants.banner;

  const { text, props: textProps } = getOptionalRenderProp(pText);

  const startIcon = (pStartIcon as FontAwesomeIconProps)?.icon || pStartIcon;
  const startIconProps = isObject(pStartIcon)
    ? (pStartIcon as FontAwesomeIconProps)
    : undefined;

  const endIcon = (pEndIcon as FontAwesomeIconProps)?.icon || pEndIcon;
  const endIconProps = isObject(pEndIcon)
    ? (pEndIcon as FontAwesomeIconProps)
    : undefined;

  const clickTracking = () => {
    logFirebaseEvent('navigation_row', {
      type: 'navigation_row',
      testid,
    });
  };

  const handleOnClick = (
    e: React.MouseEvent<HTMLIonItemElement, MouseEvent>
  ) => {
    onClick?.(e);
    if (tracking) {
      clickTracking();
    }
  };

  return (
    <IonItem
      lines="none"
      href={choose(!withClick, href)}
      routerLink={href}
      onClick={choose(withClick, handleOnClick)}
      className={classNames(classes.navigationRow, classes[variant], className)}
      data-testid={testid}
      detail={and(
        variant === NavigationRowVariants.default,
        isNil(startCustomContent),
        isNil(startIcon),
        isNil(endCustomContent),
        isNil(endIcon)
      )}
      disabled={disabled}
    >
      {ifRender(
        withStartSlot,
        <IonRow
          slot="start"
          data-testid={`${testid}-start`}
          className={classes.start}
        >
          {ifRender(startCustomContent, startCustomContent)}
          {startIcon && (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <FontAwesomeIcon icon={startIcon} {...startIconProps} />
          )}
        </IonRow>
      )}
      <IonRow slot="end" data-testid={`${testid}-end`} className={classes.end}>
        {or(isBanner, !isNil(endIcon)) && (
          <FontAwesomeIcon
            icon={choose(isBanner, 'arrow-right', endIcon) as IconProp}
            className={classNames(classes.endIcon, endIconProps?.className)}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...endIconProps}
          />
        )}
        {ifRender(endCustomContent, endCustomContent)}
      </IonRow>
      {ifRender(
        text,
        <Text
          variant="mipro-body-copy"
          text={toString(text)}
          testid={`${testid}-text`}
          className={classNames(classes.textEllipse, textProps?.className)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...textProps}
        />
      )}
    </IonItem>
  );
};

export default NavigationRow;
