import { chain, isNaN, split, toNumber, toString } from 'lodash';
import { and, choose } from 'common/utils/logicHelpers';

export type FormatNumberScale = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

/**
 * Formats any give number with thousands and decimals using Intl.NumberFormat
 * It uses a long maximum fraction digits to avoid any rounding up.
 *
 * Additionally, it uses a scale parameter to force decimal numbers at will,
 * it validates that it doesn't exceeds the maximum fraction digits and sets a limit.
 *
 * When the scale parameter is present it intentionally truncates the decimals instead of rounding them.
 * This is a business decision and shouldn't be changed unless it's requested.
 *
 * @param number: The number to format.
 * @param scale: A number from 0 to 10 that sets the limit to the number of decimals that should be shown.
 */
export const customizeNumberFormat = (
  number: number,
  scale: FormatNumberScale = 0
): string => {
  const maximumFractionDigits = 10;
  const minimumFractionDigits =
    scale > maximumFractionDigits ? maximumFractionDigits : scale;
  const formattedNumber = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(number);

  const output = split(formattedNumber, '.')[0];

  if (scale !== 0) {
    /**
     * If the scale value is present and is more than 0,
     * it truncates decimals accordingly to the scale number.
     * This is achieved by splitting the number after the decimal separator
     * transforming the decimals to an array
     * then it takes the number of elements that match the scale parameter
     * once we have the requested number of decimals it joins the array
     * and concatenates number and decimals and returns it as a string
     */
    const decimals = chain(formattedNumber)
      .split('.')
      .drop(1)
      .split('')
      .take(minimumFractionDigits)
      .join('')
      .value();

    return `${output}.${decimals}`;
  }

  return output;
};

/**
 * Formats any word with the appropriate form of a string based on whether a number is singular or plural using Intl.PluralRules.
 * @param singular: the singular form of the word.
 * @param plural: the plural form of the word.
 * @param number: the number to format.
 * @param locale: the locale to use.
 */
export const formatWords = (
  singular: string,
  plural: string,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  number = 0,
  locale: string
): string => {
  const format = new Intl.PluralRules(locale, {
    type: 'cardinal',
  });
  return `${number} ${format.select(number) === 'one' ? singular : plural}`;
};

/**
 * @description A generic function that attempts to remove leading zeros from a string.
 */
export const removeLeadingZeros = (value = '') => {
  return toString(choose(and(!isNaN(value), !!value), toNumber(value)));
};

export const formatPhoneCountryCode = (
  medium: string,
  phoneNumber: string,
  phoneCountryCode?: string
) => {
  const phoneNumberVal = phoneCountryCode
    ? `+${phoneCountryCode}${phoneNumber}`
    : phoneNumber;

  return medium.concat(phoneNumberVal);
};

const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

export const ordinalNumber = (value: number): string => {
  const rule = pr.select(value);
  const suffix = suffixes.get(rule);
  return `${value}${toString(suffix)}`;
};
