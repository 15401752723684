import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { head, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonContent, IonPage, IonRow } from '@ionic/react';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import ProductSearchResult from 'common/components/ProductSearchResult/ProductSearchResult';
import { useIonContentRef } from 'common/components/utils/renderHelpers';
import type { ProductSearchByOptionsProp } from 'ProductSearchApp/util/productSearchUtil';
import { productSearchByOptions } from 'ProductSearchApp/util/productSearchUtil';
import { useDebounce } from 'use-debounce';
import useGetCustomer from 'api/customer/useGetCustomer';
import useShowCostDetailsDispatcher from 'hooks/useToggleCostDetailsDispatcher';
import type { SnapshotsURLParams } from 'models/Search';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Searchbar from 'components/Searchbar/Searchbar';
import classes from './ProductSearch.module.scss';

interface ProductSearchBarProps {
  isCustomerLess?: boolean;
  searchMode?: ProductSearchByOptionsProp;
  searchQuery: string;
  triggerInputSelect: number;
  productSearchByOptionsProps: ProductSearchByOptionsProp[];
  setSearchQuery: (searchQuery: string) => void;
  setSearchMode: (searchMode: ProductSearchByOptionsProp | undefined) => void;
  setTriggerInputSelect: (triggerInputSelect: number) => void;
}

interface ProductSearchHeaderActionsProps {
  setTriggerAddZcodedItem?: (triggerAddZcodedItem: number) => void;
}

export const ProductSearchBar = ({
  isCustomerLess = false,
  searchMode,
  searchQuery,
  triggerInputSelect,
  productSearchByOptionsProps,
  setSearchQuery,
  setSearchMode,
  setTriggerInputSelect,
}: ProductSearchBarProps) => {
  const [isOpenSearchBy, setIsOpenSearchBy] = useState(false);
  const { t } = useTranslation();
  return (
    <IonRow
      className={classNames(classes.searchBarWrapper, {
        [classes.customerLessSearchBar]: isCustomerLess,
      })}
    >
      <Button
        variant="action"
        text={head(searchMode?.text.split(' '))}
        testid="product-search-selection"
        className={classes.selectBtn}
        rightIcon={['far', 'chevron-down']}
        onClick={() => setIsOpenSearchBy(true)}
      />
      <Searchbar
        value={searchQuery}
        setValue={setSearchQuery}
        testid="product-search-bar"
        placeholder={t('productSearch:placeholder')}
        hideSearchIcon
        className={classes.searchBar}
        inputRowClassName={classes.inputRow}
        triggerInputSelect={triggerInputSelect}
      />
      <SheetModal
        title={t('productSearch:searchBy')}
        isOpen={isOpenSearchBy}
        setIsOpen={setIsOpenSearchBy}
        initialBreakpoint={0.3}
        testid="product-search-by-modal"
        withRightCloseButton
      >
        <div className={classes.searchByContent}>
          {map(productSearchByOptionsProps, (searchByItem, index) => {
            const selectedSearchBy = searchByItem.id === searchMode?.id;
            return (
              <ActionRow
                testid={`${searchByItem.id}-${index}`}
                key={`${searchByItem.id}-${index}`}
                disabled={false}
                onClick={() => {
                  setSearchMode(searchByItem);
                  setIsOpenSearchBy(false);
                  // TUDU: Refractor to not use timeouts
                  setTimeout(() => {
                    setTriggerInputSelect(Date.now());
                  }, 1000);
                }}
              >
                {selectedSearchBy && (
                  <FontAwesomeIcon
                    icon={['far', 'check']}
                    className={classes.checkIcon}
                  />
                )}
                <div
                  className={classNames({ [classes.bold]: selectedSearchBy })}
                >
                  {searchByItem.text}
                </div>
              </ActionRow>
            );
          })}
        </div>
      </SheetModal>
    </IonRow>
  );
};

export const ProductSearchHeaderActions = ({
  setTriggerAddZcodedItem,
}: ProductSearchHeaderActionsProps) => {
  const { t } = useTranslation();
  const { toggleCostDetailsOption } = useShowCostDetailsDispatcher();
  const zCodedNameSpace = 'productSearch:zCodedItem';
  const namespace = 'productSearch:ocn';
  return {
    title: t(`${namespace}:manageOcn`),
    initialBreakpoint: 0.3,
    options: [
      ...(setTriggerAddZcodedItem
        ? [
            {
              text: t(`${zCodedNameSpace}:addZCodedItem`),
              testid: 'add-zcoded-button',
              onClick: () => setTriggerAddZcodedItem?.(Date.now()),
            },
          ]
        : []),
      toggleCostDetailsOption(false),
    ],
  };
};

const ProductSearch = (): JSX.Element => {
  const { miLoc, id } = useParams<SnapshotsURLParams>();
  const { data: customerData } = useGetCustomer({
    miLoc,
    id,
    searchType: 'customer',
  });
  const { nodeRef, node } = useIonContentRef();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 1000);

  const [triggerAddZcodedItem, setTriggerAddZcodedItem] = useState(0);
  const [searchMode, setSearchMode] = useState(head(productSearchByOptions));
  const [triggerInputSelect, setTriggerInputSelect] = useState(0);

  const headerActions = ProductSearchHeaderActions({ setTriggerAddZcodedItem });

  return (
    <IonPage className={classes.container}>
      <Header
        customTitle={<CustomerName customerData={customerData} />}
        hideMenuButton
        hideLocationSelector={false}
        testid="product-search-header"
        headerActions={headerActions}
        className={classes.header}
      >
        <ProductSearchBar
          searchMode={searchMode}
          searchQuery={searchQuery}
          triggerInputSelect={triggerInputSelect}
          setSearchQuery={setSearchQuery}
          setTriggerInputSelect={setTriggerInputSelect}
          setSearchMode={setSearchMode}
          productSearchByOptionsProps={productSearchByOptions}
        />
      </Header>
      <IonContent ref={nodeRef} className={classes.content}>
        <ProductSearchResult
          miLoc={miLoc}
          id={id}
          triggerAddZcodedItem={triggerAddZcodedItem}
          contentNode={node}
          searchMode={searchMode}
          searchQuery={searchQuery}
          searchQueryValue={searchQueryValue}
          setSearchQuery={setSearchQuery}
          setSearchMode={setSearchMode}
          productSearchByOptions={productSearchByOptions}
        />
      </IonContent>
    </IonPage>
  );
};

export default ProductSearch;
