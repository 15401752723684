import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Column, SortingRule } from 'react-table';
import type { AxiosError } from 'axios';
import { type Dictionary } from 'lodash';
import GroupBy from 'ReportsApp/components/GroupBy/GroupBy';
import ReportError from 'ReportsApp/components/ReportError/ReportError';
import useGetFixedHeader from 'ReportsApp/hooks/useGetFixedHeader';
import type { DateSegmentType } from 'models/Reports';
import type { FilterOption } from 'components/Filter/Filter';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import Loader from 'components/Loader/Loader';
import Table from 'components/Table/Table';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './ReportHelper.module.scss';

interface ReportComponentProps {
  sharedBusinessDayLabel?: string;
  showGroupBy: boolean;
  groupByOptions: {
    key: string;
    name: string;
  }[];
  selectedGroupBy: FilterOption;
  error?: AxiosError;
  isEmptyResponse?: boolean;
  tableData: Dictionary<unknown>[];
  tableColumns: Column<Dictionary<unknown>>[];
  totalsRow: React.ReactNode[];
  sortFieldSelected: FilterOption;
  sortDir?: string;
  isLoading?: boolean;
  noMoreData?: boolean;
  requestType: DateSegmentType;
  reportName: string;
  onSortBy: (sortBy: SortingRule<Dictionary<unknown>>[]) => void;
  onGroupBy: (value: string) => void;
  fetchNextPage?: () => Promise<void>;
}

const ReportHelper = ({
  sharedBusinessDayLabel,
  showGroupBy,
  groupByOptions,
  selectedGroupBy,
  onGroupBy,
  onSortBy,
  fetchNextPage,
  error,
  isEmptyResponse,
  tableData,
  tableColumns,
  totalsRow,
  sortFieldSelected,
  sortDir,
  isLoading,
  noMoreData,
  requestType,
  reportName,
}: ReportComponentProps) => {
  const { t } = useTranslation();
  const { headerRef, headerTop } = useGetFixedHeader({ tableData });

  // #region messages
  let errorContent;
  if (!isLoading && isEmptyResponse) {
    errorContent = (
      <WarningMessage
        className={classes.warningMessage}
        icon={['far', 'info-circle']}
        title={t('reports:noReports')}
        testid="empty-response"
      />
    );
  }

  if (error) {
    errorContent = (
      <ReportError
        className={classes.warningMessage}
        error={error}
        requestType={requestType}
        reportName={reportName}
        testid="report-error"
      />
    );
  }
  // #endregion messages

  return (
    <>
      {sharedBusinessDayLabel && (
        <Text
          className={classes.sharedBusinessDayLabel}
          variant="label-header"
          text={sharedBusinessDayLabel}
        />
      )}
      <div ref={headerRef} className={classes.stickyHeader}>
        {showGroupBy && (
          <GroupBy
            groupByOptions={groupByOptions}
            selectedGroupBy={selectedGroupBy}
            onGroupBy={onGroupBy}
          />
        )}
      </div>
      {!error && (
        <Table
          className={classes.drilldownTable}
          theadStyle={{ top: headerTop }}
          thClassName={classes.tableTH}
          tdClassName={classes.tableTD}
          columns={tableColumns}
          data={tableData}
          totals={totalsRow}
          onSortBy={onSortBy}
          sortField={sortFieldSelected.key}
          sortDir={sortDir}
        />
      )}
      <Loader
        className={classes.loader}
        text={t('reports:loadingReports')}
        isOpen={isLoading}
        testid="loader"
      />
      {errorContent}
      {!noMoreData && (
        <InfiniteScroll onLoadMore={fetchNextPage} testid="infinite-scroll" />
      )}
    </>
  );
};

export default ReportHelper;
